import React from 'react'
import { Form } from 'react-bootstrap'
import { FieldMetaState } from 'react-final-form'

export type TError = string | string[]

export interface IFieldErrorProps {
  readonly error: TError
}

export class FieldError extends React.Component<IFieldErrorProps> {
  static isInvalid<T>(meta: FieldMetaState<T>) {
    return meta.error && meta.touched && !meta.active
  }

  render() {
    const { error } = this.props

    return <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
  }
}
