import React from 'react'

import { Card, Form } from 'react-bootstrap'
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'

import { SpinnerWrapper } from 'src/components/Wrappers'

import 'src/vendor/libs/react-bootstrap-table2/react-bootstrap-table2.scss'
import 'src/vendor/libs/spinkit/spinkit.scss'

import { dictionary } from 'src/utils/dictionary'

const { SearchBar } = Search
const { ExportCSVButton } = CSVExport

export interface IColumn<D> {
  text: string
  dataField: string
  sort?: boolean
  classes?: string
  formatter?: (cell, row: D) => JSX.Element
}

// описание интерфейса изменения внутреннего состояния таблицы
interface ITableChangeState {
  page: number
  sizePerPage: number
  sortField: string
  sortOrder: 'asc' | 'desc'
  filters: any
  data: any
  searchText: string
}

type WhereCondition = {
  like: string
  eq: string
  isNotNull: boolean
}

type Where = Record<string | 'isDeleted', Partial<WhereCondition> | boolean>

type ArgsHandleTable = {
  page: number
  limit: number
  where: Where[]
  sort: Array<{ field: string; order: number }>
}

interface State {
  where: Where[]
}

interface Props<D, W> {
  isLoading: boolean
  columns: Array<IColumn<D>>
  data: D[]
  handleTableChange: (args: ArgsHandleTable) => void
  paginationOptions?: {
    page?: number
    limit?: number
    total?: number
  }

  onClickRow?: (row: D) => void
  whereProps?: Where | W
  searchBy?: Array<keyof D>
  needExport?: boolean
}

export class TableList<D, W> extends React.Component<Props<D, W>, State> {
  constructor(props: Props<D, W>) {
    super(props)
    const { whereProps } = props

    this.state = {
      where: [
        {
          ...whereProps,
          isDeleted: false,
        },
      ],
    }
  }

  private renderEmptyState = () => {
    const { isLoading } = this.props

    return (
      <SpinnerWrapper isLoading={isLoading}>
        <div className={'text-big text-center text-muted p-5'}>{'Список пуст'}</div>
      </SpinnerWrapper>
    )
  }

  private getPaginateOptions = () => {
    if (this.props.paginationOptions) {
      const {
        paginationOptions: { page = 0, limit = 0, total = 0 },
        isLoading,
      } = this.props

      return {
        page,
        sizePerPage: limit,
        totalSize: total,
        showTotal: !isLoading,
        sizePerPageList: [
          {
            text: '10',
            value: 10,
          },
          {
            text: '50',
            value: 50,
          },
          {
            text: '100',
            value: 100,
          },
        ],
        withFirstAndLast: true,
        hideSizePerPage: isLoading,
        hidePageListOnlyOnePage: isLoading,
      }
    }
    return undefined
  }

  private handleTableChange = (type?: string, newState?: ITableChangeState) => {
    const { searchBy } = this.props

    // поиск по параметру
    if (newState && newState.searchText && searchBy) {
      this.setState(
        (prevState) => ({
          where:
            // приводим where к виду [{},{}, ...]
            searchBy.reduce((acc: Where[], searchParam) => {
              acc.push({ [searchParam]: { ...prevState.where[0], like: newState.searchText } })
              return acc
            }, []),
        }),
        () => {
          this.callbackTableChange(this.state.where, newState)
        }
      )
    }

    // переключение параметра isDeleted
    if (type === 'toggleIsDeleted') {
      this.callbackTableChange(this.state.where, newState)
    }

    // пустой пустой запрос поиска
    if (newState && !newState.searchText) {
      const { whereProps } = this.props

      this.setState(
        (prevState) => ({
          where: [
            {
              ...whereProps,
              isDeleted: prevState.where[0].isDeleted,
            },
          ],
        }),
        () => {
          this.callbackTableChange(this.state.where, newState)
        }
      )
    }
  }

  private callbackTableChange = (where: Where[], newState?: ITableChangeState) => {
    const { handleTableChange } = this.props

    const page = newState?.page || 1
    const limit = newState?.sizePerPage || 10
    const sort = newState?.sortField
      ? [
          {
            field: newState.sortField,
            order: newState.sortOrder === 'asc' ? 1 : 2,
          },
        ]
      : []

    handleTableChange({ limit, page, sort, where })
  }

  render() {
    const { columns, onClickRow, data, isLoading, searchBy, needExport } = this.props

    const rowEvents = {
      onClick: (e, row: D) => {
        if (onClickRow) {
          onClickRow(row)
        }
      },
    }

    return (
      <Card className={'mb-3 mt-4'}>
        <ToolkitProvider keyField={'id'} data={data} columns={columns} bootstrap4 search exportCSV>
          {(props: any) => (
            <React.Fragment>
              {!!needExport ||
                (!!searchBy && (
                  <div
                    className={
                      'd-flex align-items-start border-bottom justify-content-end card-body pb-2'
                    }
                  >
                    {!!needExport && (
                      <ExportCSVButton {...props.csvProps} className={'mr-2'}>
                        {'Экспортировать в csv'}
                      </ExportCSVButton>
                    )}
                    {!!searchBy && <SearchBar {...props.searchProps} placeholder={'Поиск...'} />}
                  </div>
                ))}
              <BootstrapTable
                {...props.baseProps}
                loading={isLoading}
                noDataIndication={this.renderEmptyState}
                wrapperClasses={'table-responsive react-bs-table-card'}
                classes={'card-table border-top'}
                pagination={
                  this.props.paginationOptions
                    ? paginationFactory(this.getPaginateOptions())
                    : undefined
                }
                hover={true}
                remote={{
                  filter: true,
                  pagination: true,
                  sort: true,
                  cellEdit: false,
                }}
                rowEvents={rowEvents}
                rowStyle={{ cursor: 'pointer' }}
                filter={filterFactory()}
                onTableChange={this.handleTableChange}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Card>
    )
  }
}
