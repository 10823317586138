import React from 'react'
import _ from 'lodash'
import { Card } from 'react-bootstrap'

import { ExecutorLinkDto } from 'src/store/OrderStore'
import InfoPanel from 'src/components/InfoPanel'
import { dictionary } from 'src/utils/dictionary'
import { formatPhone } from 'src/utils/formatters'

interface IProps {
  executors: ExecutorLinkDto[]
}

const Executors = (props: IProps) => {
  const { executors } = props
  const executorsByRoles = _.groupBy(executors, 'role')
  return (
    <Card>
      {Object.keys(executorsByRoles).map((key) => (
        <InfoPanel
          title={dictionary.selectOptions.executorRoles.find((role) => role.id === +key)?.plural}
          key={key}
          rows={executorsByRoles[key].map((executor: ExecutorLinkDto) => ({
            key: executor.name ? executor.name : 'Исполнитель',
            value: executor.phone ? (
              <Card.Link target="_blank" href={`tel:${executor.phone}`}>
                {formatPhone(executor.phone)}
              </Card.Link>
            ) : (
              ' '
            ),
          }))}
        />
      ))}
    </Card>
  )
}

export default Executors
