import moment from 'moment'
import 'moment/locale/ru'
import { PriceElementDto, PriceElementMatrixDto, PriceElementOptionDto } from 'src/store/MetaStore'
import { PlatformCRMWeb } from 'src/services/GrpcService'
import { CalculateOptionDto } from 'src/store/OrderStore'

export function parseMinutes(minutes: number): string {
  const realmin = Math.floor(minutes % 60)
  const hours = Math.floor(minutes / 60)

  return `${hours ? `${hours} ч.` : ''}${realmin ? ` ${realmin} мин.` : ''}`;
}

export function parseDate(date: string) {
  moment.locale('ru')

  return moment(date).format('HH:mm DD.MM.YY')
}

export const parsePrice = (price: number) => {
  return `${Math.trunc(price / 100)} р.`
}

export const parseDuration = (duration: number) => {
  return `${Math.trunc(duration / 60)} ч.${
    Math.trunc(duration % 60) ? ` ${Math.trunc(duration % 60)} мин.` : ''
  }`
}

export const getFullName = (
  firstName?: string | null,
  lastName?: string | null,
  middleName?: string | null
): string => {
  const firstNameInFull = firstName ? `${firstName} ` : ''
  const lastNameInFull = lastName ? `${lastName} ` : ''

  return `${firstNameInFull}${lastNameInFull}${middleName || ''}`
}

export const formatPhone = (phone: string) => {
  return phone
    .replace(/\D/g, '')
    .replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      ''.concat(phone.startsWith('8') ? '' : '+', '$1\xA0($2)\xA0$3-$4-$5')
    )
}

export interface ITargetOption {
  slug: string
  value?: number
  totalPrice?: number
  totalDuration?: number
  id?: string
  createdAt?: string
  updatedAt?: string
  priceElement: PriceElementDto
  costLinear: number
  durationLinear: number
  durationMatrix: PriceElementMatrixDto
  isFree: boolean
  defaultValue: number
  tags: string[]
  title: string
  shortDesc: string
  iconName: string
  type: PlatformCRMWeb.PriceElementOptionType
}

export const parseChosedOptions = (
  priceElementOptions: PriceElementOptionDto[],
  calculateOptions: CalculateOptionDto[]
): ITargetOption[] => {
  return calculateOptions.reduce((result: ITargetOption[], calculateOption) => {
    const foundedPriceElementOption = priceElementOptions.find(
      (priceElementOption) =>
        priceElementOption.slug === calculateOption.slug &&
        !priceElementOption.isFree &&
        !['deliver-keys', 'pickup-keys'].includes(priceElementOption.slug)
    )
    if (foundedPriceElementOption) {
      result.push({
        ...calculateOption,
        ...foundedPriceElementOption,
      })
    }
    return result
  }, [])
}
