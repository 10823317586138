import React from 'react'

import { Button, Form, InputGroup } from 'react-bootstrap'
import Select from 'react-select'
import { Field, FieldInputProps } from 'react-final-form'
import { FieldLabel } from 'src/components/Fields/FieldLabel'
import { FieldError } from 'src/components/Fields/FieldError'

import { TFieldProp } from 'src/components/Fields/field.interface'

interface AnyObject {
  [key: string]: any
}

export interface ISelectFieldProp {
  optionValueKey: Partial<keyof AnyObject>
  optionLabelKey: Partial<keyof AnyObject>
  options?: AnyObject[]
  variant?: 'dropdown' | 'radio' | 'switcher'
  onClearClick?: () => void
  onChange?: (value: any) => void
}

export type TSelectFieldProp = ISelectFieldProp & TFieldProp<AnyObject>

export class SelectField extends React.Component<TSelectFieldProp> {
  static defaultProps: Partial<TSelectFieldProp> = {
    isReadonly: false,
    isRequired: false,
  }

  private renderDropDown = (input: FieldInputProps<any, HTMLElement>) => {
    const {
      isReadonly,
      placeholder = 'Выбор',
      optionValueKey,
      optionLabelKey,
      options,
      initialValue,
      onChange,
    } = this.props

    return (
      <Select
        {...input}
        options={options}
        getOptionLabel={(option) => option[optionLabelKey]}
        getOptionValue={(option) => option[optionValueKey]}
        defaultValue={initialValue}
        placeholder={placeholder}
        isSearchable={true}
        isDisabled={isReadonly}
        onChange={(evt) => {
          input.onChange(evt)
          if (onChange) {
            onChange(evt)
          }
        }}
        className="flex-grow-1"
      />
    )
  }

  private renderRadio = ({
    onChange,
    checked,
    ...inputRender
  }: FieldInputProps<any, HTMLElement>) => {
    const {
      isReadonly,
      optionValueKey,
      optionLabelKey,
      options = [],
      onChange: customChange,
    } = this.props

    return (
      <div>
        {options.map((option, key) => (
          <Form.Check
            {...inputRender}
            type="radio"
            custom
            key={key}
            defaultChecked={this.isDefaultChecked(option)}
            id={option[optionValueKey]}
            label={option[optionLabelKey]}
            disabled={isReadonly}
            onChange={() => {
              onChange(option)

              if (customChange) {
                customChange(option)
              }
            }}
            className="flex-grow-1"
          />
        ))}
      </div>
    )
  }

  private renderSwitcher = ({
    onChange,
    checked,
    ...inputRender
  }: FieldInputProps<any, HTMLElement>) => {
    const { isReadonly, optionValueKey, optionLabelKey, options = [] } = this.props

    return options.map((option, key) => (
      <div className="switchers-stacked flex-grow-1" key={key}>
        <label className="switcher">
          <input
            {...inputRender}
            type="radio"
            className="switcher-input"
            defaultChecked={this.isDefaultChecked(option)}
            id={option[optionValueKey]}
            disabled={isReadonly}
            onChange={() => onChange(option)}
          />
          <span className="switcher-indicator">
            <span className="switcher-yes"></span>
            <span className="switcher-no"></span>
          </span>
          <span className="switcher-label">{option[optionLabelKey]}</span>
        </label>
      </div>
    ))
  }

  private isDefaultChecked = (option: AnyObject) => {
    const { optionValueKey, initialValue } = this.props

    return initialValue && initialValue[optionValueKey] === option[optionValueKey]
  }

  render() {
    const {
      name,
      isRequired,
      label,
      variant = 'dropdown',
      initialValue,
      optionValueKey,
      onClearClick,
    } = this.props

    return (
      <Field
        name={name}
        type={variant === 'radio' ? 'radio' : undefined}
        initialValue={initialValue}
        render={({ input, meta, form }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <Form.Control
              hidden
              readOnly={true}
              value={initialValue ? initialValue[optionValueKey] : ''}
              isInvalid={FieldError.isInvalid(meta)}
            />
            <InputGroup>
              {/dropdown/.test(variant) && this.renderDropDown(input)}
              {/radio/.test(variant) && this.renderRadio(input)}
              {/switcher/.test(variant) && this.renderSwitcher(input)}
              {onClearClick && (
                <InputGroup.Append>
                  <Button
                    onClick={() => {
                      onClearClick()
                      input.onChange(undefined)
                    }}
                    variant="danger"
                  >
                    Убрать
                  </Button>
                </InputGroup.Append>
              )}
            </InputGroup>
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    )
  }
}
