import React from 'react'
import { Card, ListGroup } from 'react-bootstrap'

export interface IInfoPanelRow {
  key: string
  value?: string | JSX.Element | JSX.Element[]
  keyType?: 'small' | 'default'
}

interface IProps {
  title?: string
  rows: IInfoPanelRow[]
}

const InfoPanel = ({ title, rows }: IProps) => {
  return (
    <div>
      {title && <Card.Header as="h6">{title}</Card.Header>}
      <ListGroup variant="flush">
        {rows.map(({ key, value, keyType }) =>
          value ? (
            <ListGroup.Item
              key={key}
              className={`${
                keyType === 'small' ? '' : 'd-flex justify-content-between align-items-center'
              }`}
            >
              <div className={`text-muted ${keyType === 'small' ? 'small' : ''}`}>{key}</div>
              <div className={`${keyType === 'small' ? 'd-flex flex-wrap' : ''}`}>{value}</div>
            </ListGroup.Item>
          ) : null
        )}
      </ListGroup>
    </div>
  )
}

export default InfoPanel
