
/* eslint-disable */
import { AbstractClientBase, GrpcWebClientBase, Metadata, Error, ClientReadableStream } from 'grpc-web';
import { PlatformCRMWeb, sso } from './codegen/app_pb';

type Options = {
  ignoreInterceptors?: boolean
}

export class GrpcService {
  private client: GrpcWebClientBase;
  private metadata: Metadata = {};
  private hostname: string;
  private interceptingPromise?: Promise<any>;
  public interceptors: { errors: ((e: any) => Promise<any>)[] } = {
    errors: []
  };
  constructor(hostname: string) {
    this.client = new GrpcWebClientBase({});
    this.hostname = hostname;
  }
  private makeInterceptedUnaryCall = <Result, Params, MethodInfo>(command: string, params: Params, methodInfo: MethodInfo, options: Options = {}): Promise<Result> => {
    const unaryCallHandler = (): Promise<Result> => this.client.unaryCall(command, params, this.metadata, methodInfo)
    
    if (options.ignoreInterceptors) {
      return unaryCallHandler()
    }
    
    if (this.interceptingPromise) {
      return this.interceptingPromise.then(() => {
        this.interceptingPromise = undefined;
        return unaryCallHandler()
      });
    }
    
    return new Promise((resolve, reject) => {
      unaryCallHandler().then(resolve).catch(e => {
        this.chainingInterceptors(this.interceptors.errors, e).then(() => {
          this.makeInterceptedUnaryCall<Result, Params, MethodInfo>(command, params, methodInfo).then(resolve).catch(reject)
        }).catch(reject)
      });
    });
  }
  private chainingInterceptors = (interceptors: ((e: any) => Promise<any>)[], value: any) => {
    this.interceptingPromise = interceptors.reduce(
      (chain, nextInterceptor) => chain.then(nextInterceptor),
      Promise.resolve(value)
    );
    return this.interceptingPromise;
  }
  public setMetadata = (metadata: Metadata = {}) => {
    this.metadata = Object.assign({}, this.metadata, metadata);
  };

  public PlatformCRMWeb = {
    LoginService: {
      Login: (params: PlatformCRMWeb.ILoginRequest, options: Options = {}): Promise<PlatformCRMWeb.TokenResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.TokenResponse,
          (request: PlatformCRMWeb.LoginRequest) => PlatformCRMWeb.LoginRequest.encode(request).finish(),
          PlatformCRMWeb.TokenResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.LoginService/Login', params, methodInfo, options);
      },
      RequestOtp: (params: PlatformCRMWeb.IOtpRequest, options: Options = {}): Promise<PlatformCRMWeb.OtpResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.OtpResponse,
          (request: PlatformCRMWeb.OtpRequest) => PlatformCRMWeb.OtpRequest.encode(request).finish(),
          PlatformCRMWeb.OtpResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.LoginService/RequestOtp', params, methodInfo, options);
      },
      RefreshToken: (params: PlatformCRMWeb.IRefreshTokenRequest, options: Options = {}): Promise<PlatformCRMWeb.TokenResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.TokenResponse,
          (request: PlatformCRMWeb.RefreshTokenRequest) => PlatformCRMWeb.RefreshTokenRequest.encode(request).finish(),
          PlatformCRMWeb.TokenResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.LoginService/RefreshToken', params, methodInfo, options);
      },
      SendEmailAndPass: (params: PlatformCRMWeb.ISendEmailAndPassRequest, options: Options = {}): Promise<PlatformCRMWeb.TokenResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.TokenResponse,
          (request: PlatformCRMWeb.SendEmailAndPassRequest) => PlatformCRMWeb.SendEmailAndPassRequest.encode(request).finish(),
          PlatformCRMWeb.TokenResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.LoginService/SendEmailAndPass', params, methodInfo, options);
      },
    },
        OrderService: {
      GetOrder: (params: PlatformCRMWeb.IGetOrderRequest, options: Options = {}): Promise<PlatformCRMWeb.GetOrderResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.GetOrderResponse,
          (request: PlatformCRMWeb.GetOrderRequest) => PlatformCRMWeb.GetOrderRequest.encode(request).finish(),
          PlatformCRMWeb.GetOrderResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.OrderService/GetOrder', params, methodInfo, options);
      },
      GetOrders: (params: PlatformCRMWeb.IGetOrdersRequest, options: Options = {}): Promise<PlatformCRMWeb.GetOrdersResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.GetOrdersResponse,
          (request: PlatformCRMWeb.GetOrdersRequest) => PlatformCRMWeb.GetOrdersRequest.encode(request).finish(),
          PlatformCRMWeb.GetOrdersResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.OrderService/GetOrders', params, methodInfo, options);
      },
      SaveOrderReport: (params: PlatformCRMWeb.ISaveOrderReportRequest, options: Options = {}): Promise<PlatformCRMWeb.SaveOrderReportResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.SaveOrderReportResponse,
          (request: PlatformCRMWeb.SaveOrderReportRequest) => PlatformCRMWeb.SaveOrderReportRequest.encode(request).finish(),
          PlatformCRMWeb.SaveOrderReportResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.OrderService/SaveOrderReport', params, methodInfo, options);
      },
      SendInvoice: (params: PlatformCRMWeb.ISendInvoiceRequest, options: Options = {}): Promise<PlatformCRMWeb.SendInvoiceResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.SendInvoiceResponse,
          (request: PlatformCRMWeb.SendInvoiceRequest) => PlatformCRMWeb.SendInvoiceRequest.encode(request).finish(),
          PlatformCRMWeb.SendInvoiceResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.OrderService/SendInvoice', params, methodInfo, options);
      },
    },
        QuestionnaireService: {
      GetQuestionnaire: (params: PlatformCRMWeb.IQuestionnaireRequest, options: Options = {}): Promise<PlatformCRMWeb.ClientQuestionnaireResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.ClientQuestionnaireResponse,
          (request: PlatformCRMWeb.QuestionnaireRequest) => PlatformCRMWeb.QuestionnaireRequest.encode(request).finish(),
          PlatformCRMWeb.ClientQuestionnaireResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.QuestionnaireService/GetQuestionnaire', params, methodInfo, options);
      },
      GetOptionsByQuestion: (params: PlatformCRMWeb.IOptionsByQuestionRequest, options: Options = {}): Promise<PlatformCRMWeb.ClientOptionsByQuestionResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.ClientOptionsByQuestionResponse,
          (request: PlatformCRMWeb.OptionsByQuestionRequest) => PlatformCRMWeb.OptionsByQuestionRequest.encode(request).finish(),
          PlatformCRMWeb.ClientOptionsByQuestionResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.QuestionnaireService/GetOptionsByQuestion', params, methodInfo, options);
      },
      SubmitQuestionnaire: (params: PlatformCRMWeb.IClientSubmitQuestionnaireRequest, options: Options = {}): Promise<PlatformCRMWeb.ClientSubmitQuestionnaireResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.ClientSubmitQuestionnaireResponse,
          (request: PlatformCRMWeb.ClientSubmitQuestionnaireRequest) => PlatformCRMWeb.ClientSubmitQuestionnaireRequest.encode(request).finish(),
          PlatformCRMWeb.ClientSubmitQuestionnaireResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.QuestionnaireService/SubmitQuestionnaire', params, methodInfo, options);
      },
    },
        MetaService: {
      FindPriceElement: (params: PlatformCRMWeb.IFindPriceElementRequest, options: Options = {}): Promise<PlatformCRMWeb.FindPriceElementResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          PlatformCRMWeb.FindPriceElementResponse,
          (request: PlatformCRMWeb.FindPriceElementRequest) => PlatformCRMWeb.FindPriceElementRequest.encode(request).finish(),
          PlatformCRMWeb.FindPriceElementResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/PlatformCRMWeb.MetaService/FindPriceElement', params, methodInfo, options);
      },
    },
  };
  public sso = {
    ProfileV2Service: {
      CreateProfile: (params: sso.ICreateProfileRequest, options: Options = {}): Promise<sso.CreateProfileResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.CreateProfileResponse,
          (request: sso.CreateProfileRequest) => sso.CreateProfileRequest.encode(request).finish(),
          sso.CreateProfileResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/CreateProfile', params, methodInfo, options);
      },
      GetProfile: (params: sso.IGetProfileRequest, options: Options = {}): Promise<sso.GetProfileResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.GetProfileResponse,
          (request: sso.GetProfileRequest) => sso.GetProfileRequest.encode(request).finish(),
          sso.GetProfileResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/GetProfile', params, methodInfo, options);
      },
      UpdateProfile: (params: sso.IUpdateProfileRequest, options: Options = {}): Promise<sso.UpdateProfileResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.UpdateProfileResponse,
          (request: sso.UpdateProfileRequest) => sso.UpdateProfileRequest.encode(request).finish(),
          sso.UpdateProfileResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/UpdateProfile', params, methodInfo, options);
      },
      FindOrCreateProfile: (params: sso.IFindOrCreateProfileRequest, options: Options = {}): Promise<sso.FindOrCreateProfileResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.FindOrCreateProfileResponse,
          (request: sso.FindOrCreateProfileRequest) => sso.FindOrCreateProfileRequest.encode(request).finish(),
          sso.FindOrCreateProfileResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/FindOrCreateProfile', params, methodInfo, options);
      },
      UpsertProfile: (params: sso.IUpsertProfileRequest, options: Options = {}): Promise<sso.UpsertProfileResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.UpsertProfileResponse,
          (request: sso.UpsertProfileRequest) => sso.UpsertProfileRequest.encode(request).finish(),
          sso.UpsertProfileResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/UpsertProfile', params, methodInfo, options);
      },
      AddContact: (params: sso.IAddContactRequest, options: Options = {}): Promise<sso.AddContactResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.AddContactResponse,
          (request: sso.AddContactRequest) => sso.AddContactRequest.encode(request).finish(),
          sso.AddContactResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/AddContact', params, methodInfo, options);
      },
      GetContact: (params: sso.IGetContactRequest, options: Options = {}): Promise<sso.GetContactResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.GetContactResponse,
          (request: sso.GetContactRequest) => sso.GetContactRequest.encode(request).finish(),
          sso.GetContactResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/GetContact', params, methodInfo, options);
      },
      AddAddress: (params: sso.IAddAddressRequest, options: Options = {}): Promise<sso.AddAddressResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.AddAddressResponse,
          (request: sso.AddAddressRequest) => sso.AddAddressRequest.encode(request).finish(),
          sso.AddAddressResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/AddAddress', params, methodInfo, options);
      },
      GetAddresses: (params: sso.IGetAddressesRequest, options: Options = {}): Promise<sso.GetAddressesResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.GetAddressesResponse,
          (request: sso.GetAddressesRequest) => sso.GetAddressesRequest.encode(request).finish(),
          sso.GetAddressesResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/GetAddresses', params, methodInfo, options);
      },
      GetPassportAddresses: (params: sso.IGetPassportAddressesRequest, options: Options = {}): Promise<sso.GetPassportAddressesResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.GetPassportAddressesResponse,
          (request: sso.GetPassportAddressesRequest) => sso.GetPassportAddressesRequest.encode(request).finish(),
          sso.GetPassportAddressesResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/GetPassportAddresses', params, methodInfo, options);
      },
      UpdateAddress: (params: sso.IUpdateAddressRequest, options: Options = {}): Promise<sso.UpdateAddressResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.UpdateAddressResponse,
          (request: sso.UpdateAddressRequest) => sso.UpdateAddressRequest.encode(request).finish(),
          sso.UpdateAddressResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/UpdateAddress', params, methodInfo, options);
      },
      DeleteAddress: (params: sso.IDeleteAddressRequest, options: Options = {}): Promise<sso.DeleteAddressResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.DeleteAddressResponse,
          (request: sso.DeleteAddressRequest) => sso.DeleteAddressRequest.encode(request).finish(),
          sso.DeleteAddressResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/DeleteAddress', params, methodInfo, options);
      },
      AddPassport: (params: sso.IAddPassportRequest, options: Options = {}): Promise<sso.AddPassportResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.AddPassportResponse,
          (request: sso.AddPassportRequest) => sso.AddPassportRequest.encode(request).finish(),
          sso.AddPassportResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/AddPassport', params, methodInfo, options);
      },
      GetPassport: (params: sso.IGetPassportRequest, options: Options = {}): Promise<sso.GetPassportResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.GetPassportResponse,
          (request: sso.GetPassportRequest) => sso.GetPassportRequest.encode(request).finish(),
          sso.GetPassportResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/GetPassport', params, methodInfo, options);
      },
      AddPhoto: (params: sso.IAddPhotoRequest, options: Options = {}): Promise<sso.AddPhotoResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.AddPhotoResponse,
          (request: sso.AddPhotoRequest) => sso.AddPhotoRequest.encode(request).finish(),
          sso.AddPhotoResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/AddPhoto', params, methodInfo, options);
      },
      GetPhoto: (params: sso.IGetPhotoRequest, options: Options = {}): Promise<sso.GetPhotoResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.GetPhotoResponse,
          (request: sso.GetPhotoRequest) => sso.GetPhotoRequest.encode(request).finish(),
          sso.GetPhotoResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/GetPhoto', params, methodInfo, options);
      },
      UpdatePhoto: (params: sso.IUpdatePhotoRequest, options: Options = {}): Promise<sso.UpdatePhotoResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.UpdatePhotoResponse,
          (request: sso.UpdatePhotoRequest) => sso.UpdatePhotoRequest.encode(request).finish(),
          sso.UpdatePhotoResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/UpdatePhoto', params, methodInfo, options);
      },
      DeletePhoto: (params: sso.IDeletePhotoRequest, options: Options = {}): Promise<sso.DeletePhotoResponse> => {
        const methodInfo = new AbstractClientBase.MethodInfo(
          sso.DeletePhotoResponse,
          (request: sso.DeletePhotoRequest) => sso.DeletePhotoRequest.encode(request).finish(),
          sso.DeletePhotoResponse.decode
        );
        return this.makeInterceptedUnaryCall(this.hostname + '/sso.ProfileV2Service/DeletePhoto', params, methodInfo, options);
      },
    },
  };
};