import React from 'react'
import { observer } from 'mobx-react'

import OrderStore from 'src/store/OrderStore'

@observer
export default class Demo extends React.Component {
  private getOrder = () => {
    const { getOrder } = OrderStore
    getOrder({ orderId: 'testUid' })
  }

  private getOrdersByExecutor = () => {
    const { getOrdersByExecutor } = OrderStore
    getOrdersByExecutor({})
  }

  render() {
    const { order, orders } = OrderStore

    return (
      <>
        <div>
          <input type="submit" value="getOrder" onClick={this.getOrder} />
          <div>{`order: ${JSON.stringify(order, null, 2)}`}</div>
          <input type="submit" value="getOrdersByExecutor" onClick={this.getOrdersByExecutor} />
          <div>{`orders: ${JSON.stringify(orders, null, 2)}`}</div>
        </div>
      </>
    )
  }
}
