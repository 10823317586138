import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'

import LoginStore from 'src/store/LoginStore'

import { DefaultLayout, titleTemplate, routes } from 'src/routes'

@observer
export default class PrivateRoute extends Route {
  private fillingRoutes = () => {
    const { accessToken } = LoginStore

    return routes(Boolean(accessToken)).map((route) => {
      route.layout = route.layout || DefaultLayout
      route.exact = typeof route.exact === 'undefined' ? true : route.exact
      return route
    })
  }

  render() {
    const { accessToken } = LoginStore

    if (accessToken) {
      return this.fillingRoutes().map((route) => (
        <Route
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <route.layout {...props}>
              <route.pureComponent
                setTitle={() =>
                  (document.title = titleTemplate.replace('%s', route.title ? route.title : 'Page'))
                }
                {...props}
              />
            </route.layout>
          )}
          key={route.path}
        />
      ))
    }

    return <Redirect to={{ pathname: '/login', state: { from: this.props.location?.pathname } }} />
  }
}
