import { action, observable, runInAction } from 'mobx'
import { plainToClass } from 'class-transformer'

import GrpcService, { PlatformCRMWeb } from 'src/services/GrpcService'
import { PriceElementDto } from './MetaStore.dto'

export default class ProspectStore {
  @observable isLoading: boolean = false
  @observable priceElement?: PriceElementDto

  @action findPriceElement = (args?: PlatformCRMWeb.FindPriceElementRequest): void => {
    this.isLoading = true
    const req = { ...args }

    GrpcService.PlatformCRMWeb.MetaService.FindPriceElement(req)
      .then((res) => {
        const { priceElement } = res
        this.priceElement = plainToClass(PriceElementDto, priceElement)
        this.isLoading = false
      })
      .catch(() => {
        this.isLoading = false
      })
  }
}
