import React from 'react'
import { Form, InputGroup, Button /*, DropdownButton, Dropdown*/ } from 'react-bootstrap'
import { Field } from 'react-final-form'
import formatString from 'format-string-by-pattern'
import { TFieldProp } from '../field.interface'
import { FieldLabel } from '../FieldLabel'
import { FieldError } from '../FieldError'

/**
 * TODO Так сделано только для примера, на деле это поле нужно делать по другому:
 * - слева должен быть DropDown с выбором страны
 * - маска поставляется автоматически в зависимости от выбранной страны
 * - маска не содержит код
 * - Хороший пример - форма ввода телефона в телеграмме или гугле
 */

export enum COUNTRY {
  RU = '+7',
}

export interface IMobilePhoneFieldProp {
  readonly country: COUNTRY
}

export class MobilePhoneField extends React.Component<IMobilePhoneFieldProp & TFieldProp<string>> {
  static defaultProps: Partial<IMobilePhoneFieldProp & TFieldProp<string>> = {
    country: COUNTRY.RU,
    isReadonly: false,
    isRequired: false,
  }

  getMask() {
    switch (this.props.country) {
      case COUNTRY.RU:
      default:
        return '(999) 999-99-99'
    }
  }

  parser(value?: string): string {
    const { country } = this.props
    const mask = this.getMask()

    if (typeof value === 'string') {
      // TODO Тут можно добавить больше вариантов парсинга
      const countryRegExp = new RegExp('^\\' + country + '|([^\\d])', 'g')
      value = value.replace(countryRegExp, '')
    }

    return formatString(mask)(value)
  }

  render() {
    const { name, isRequired, isReadonly, initialValue, placeholder, label } = this.props

    return (
      <Field
        name={name}
        parse={this.parser.bind(this)}
        initialValue={this.parser(initialValue)}
        render={({ input, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <InputGroup>
              <InputGroup.Prepend>
                <Button disabled variant="secondary">
                  {this.props.country}
                </Button>
              </InputGroup.Prepend>
              {/*<DropdownButton id={name} as={ InputGroup.Prepend } variant='secondary' title={ this.props.country }>*/}
              {/*<Dropdown.Item>{ COUNTRY.RU }</Dropdown.Item>*/}
              {/*</DropdownButton>*/}
              <Form.Control
                {...input}
                placeholder={placeholder || this.getMask()}
                readOnly={isReadonly}
                isInvalid={FieldError.isInvalid(meta)}
              />
              <FieldError error={meta.error} />
            </InputGroup>
          </Form.Group>
        )}
      />
    )
  }
}
