// https://metrofanat.ru/lines
export const METRO_LINE_COLORS = {
  undefined: '#D3D3D3',
  Сокольническая: '#ED1B35',
  Замоскворецкая: '#44B85C',
  'Арбатско-Покровская': '#0078BF',
  Филёвская: '#19C1F3',
  Кольцевая: '#894E35',
  'Большая кольцевая линия': '#654061',
  'Калужско-Рижская': '#F58631',
  'Таганско-Краснопресненская': '#8E479C',
  Калининская: '#FFCB31',
  'Серпуховско-Тимирязевская': '#A1A2A3',
  'Белорусско-Савеловский': '#A1A2A3',
  'Люблинско-Дмитровская': '#B3D445',
  Бутовская: '#ACBFE1',
  Некрасовская: '#FA97D0',
}
