import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import moment from 'moment'

import Sidenav from 'src/vendor/libs/sidenav'
import { Divider, RouterLink, Menu } from 'src/vendor/libs/sidenav'
import { ReactComponent as Logo } from './logo.svg'

import layoutHelpers from './helpers'

interface IProps {
  sidenavBg: string
  orientation?: 'vertical' | 'horizontal'
}

const LayoutSidenav = (props: IProps) => {
  const { sidenavBg, orientation } = props
  const location = useLocation()

  const layoutSidenavClasses = () => {
    let bg = sidenavBg

    if (
      orientation === 'horizontal' &&
      (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)
    ) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '')
    }

    return (
      `bg-${bg} ` +
      (orientation !== 'horizontal'
        ? 'layout-sidenav'
        : 'layout-sidenav-horizontal container-p-x flex-grow-0')
    )
  }

  const isMenuActive = (url: string) => {
    return location.pathname.indexOf(url) === 0
  }

  const isMenuOpen = (url: string) => {
    return location.pathname.indexOf(url) === 0
  }

  const toggleSidenav = (e: React.MouseEvent) => {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  return (
    <Sidenav orientation={orientation} className={layoutSidenavClasses()}>
      <div className={'app-brand demo'}>
        <Logo className={'app-brand-logo demo bg-primary'} />
        <Link to={'/'} className={'app-brand-text demo sidenav-text font-weight-normal ml-2'}>
          Executors CRM
        </Link>
        <a
          href="#toggle"
          className="layout-sidenav-toggle sidenav-link text-large ml-auto"
          onClick={toggleSidenav}
        >
          <i className="ion ion-md-menu align-middle"></i>
        </a>
      </div>
      <Divider className={'mt-0'} />

      {/* Inner */}
      <div className={'sidenav-inner'}>
        <Menu
          icon={'ion ion-ios-apps'}
          linkText={'Заказы'}
          active={isMenuActive('/orders')}
          open={isMenuOpen('/orders')}
        >
          <RouterLink to={`/orders/${moment().format('YYYY-MM-DD')}`}>{'Сегодня'}</RouterLink>
          <RouterLink
            to={`/orders/${moment()
              .add(1, 'day')
              .format('YYYY-MM-DD')}`}
          >
            {'Завтра'}
          </RouterLink>
        </Menu>
        <Menu
          icon={'ion ion-ios-cog'}
          linkText={'Разработка'}
          active={isMenuActive('/dev')}
          open={isMenuOpen('/dev')}
        >
          <RouterLink to={'/demo/'}>{'Демо'}</RouterLink>
        </Menu>
        <RouterLink
          onClick={() => {
            localStorage.clear()
          }}
          to={'/login/'}
        >
          {'Выход'}
        </RouterLink>
      </div>
    </Sidenav>
  )
}

export default LayoutSidenav
