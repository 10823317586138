import React, { useEffect } from 'react'
import moment from 'moment'
import { useHistory, useParams } from 'react-router'
import { observer } from 'mobx-react'
import { Media } from 'react-bootstrap'

import OrderStore, { ShortOrderDto } from 'src/store/OrderStore'
import { TableList, IColumn } from 'src/components/TableList'

interface IProps {
  setTitle: (title?: string) => void
}

interface IParams {
  date: string
}

const Orders = observer((props: IProps) => {
  const { setTitle } = props
  const { isLoading, getOrdersByExecutor, orders } = OrderStore
  const { date } = useParams<IParams>()
  const history = useHistory()

  useEffect(() => {
    getOrdersByExecutor({ date: date ? date : undefined })
    setTitle()
  }, [date])

  const onClickRow = (row: ShortOrderDto) => {
    history.push(`/order/${row.id}`)
  }

  const columns: Array<IColumn<ShortOrderDto>> = [
    {
      text: 'Заказ',
      dataField: 'orderNumber',
      classes: 'py-2 align-middle',
      sort: false,
      formatter: (cell, row: ShortOrderDto) => {
        return <Media className={'align-items-center'}>{row.orderNumber}</Media>
      },
    },
    {
      text: 'Время',
      dataField: 'startAt',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row: ShortOrderDto) => {
        return (
          <Media className={'align-items-center'}>{moment(row.startDate).format('HH:mm')}</Media>
        )
      },
    },
    {
      text: 'Адрес',
      dataField: 'address',
      sort: false,
      classes: 'align-middle',
      formatter: (cell, row: ShortOrderDto) => {
        return <Media className={'align-items-center'}>{row.address.value}</Media>
      },
    },
  ]

  console.log('!!!', orders);

  return (
    <TableList
      isLoading={isLoading}
      columns={columns}
      data={orders}
      onClickRow={onClickRow}
      handleTableChange={() => {}}
    />
  )
})

export default Orders
