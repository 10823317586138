import { action, observable, runInAction } from 'mobx';
import 'mobx-react-lite/batchingForReactDom';

import GrpcService from 'src/services/GrpcService';
import { genCsrfToken } from '@qlean/nest-csrf-gen';
import {
  LoginRequestDto,
  OtpRequestDto,
  AuthTokenValues,
  TokenResponseDto,
} from './LoginStore.dto';

import { logger } from '@qlean/front-logger';
import { plainToClass } from 'class-transformer';

export default class LoginStore {
  @observable isLoading: boolean = false;
  @observable accessToken?: string;
  @observable refreshToken?: string;
  @observable phone?: string;
  private refreshPromise?: Promise<any> | null = null;

  constructor() {
    this.accessToken = localStorage.getItem('accessToken') || undefined;
    this.refreshToken = localStorage.getItem('refreshToken') || undefined;
    this.phone = localStorage.getItem('phone') || undefined;
    if (this.accessToken) {
      GrpcService.setMetadata({ token: this.accessToken });
    }
  }

  setTokens(accessToken = '', refreshToken = '') {
    GrpcService.setMetadata({ token: String(accessToken) });
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }

  private resetState() {
    this.phone = '';
    localStorage.removeItem('phone');
    this.setTokens();
  }

  @action exit = () => {
    this.resetState()
  };

  @action submitEmail = (values: AuthTokenValues) => {
    this.isLoading = true;

    const { isRememberMe, ...credentials } = values;

    return GrpcService.PlatformCRMWeb.LoginService.SendEmailAndPass(credentials)
      .then((res) => {
        const { accessToken, refreshToken } = plainToClass(TokenResponseDto, res);
        logger.info('-res-getAuthToken-', res);

        if (accessToken) {
          const id = this.parceToken(accessToken)?.userId;

          if (isRememberMe) {
            localStorage.setItem('user_email', credentials.email);
            localStorage.setItem('user_id', id || '');
          } else {
            sessionStorage.setItem('user_email', credentials.email);
            sessionStorage.setItem('user_id', id || '');
          }
        }

        this.isLoading = false;
        this.setTokens(accessToken, refreshToken);
        return res;
      })
      .catch((e) => {
        logger.error('[LoginStore::submitEmail]', e);
        this.isLoading = false;
      });
  };

  @action submitPhone = async ({ phone }: OtpRequestDto) => {
    this.isLoading = true;

    localStorage.setItem('phone', phone);
    logger.setConfig({ uuid: phone });

    const payload = {
      connection: 3,
      userType: 2,
      login: phone,
      send: 1,
    };

    const { userAgent } = window.navigator;

    const csrfToken = genCsrfToken({ payload, userAgent });

    return GrpcService.PlatformCRMWeb.LoginService.RequestOtp({ csrfToken, userAgent, ...payload })
      .then((res) => {
        logger.info(`Auth code is`, res.code); // проверить что это будет только для стейджа
        runInAction(() => {
          this.isLoading = false;
          this.phone = phone;
        });
        return res;
      })
      .catch((e) => {
        logger.error('[LoginStore::submitPhone]', e);
        this.isLoading = false;
      });
  };

  @action submitCode = (args: LoginRequestDto) => {
    this.isLoading = true;
    const req = { ...args };

    return GrpcService.PlatformCRMWeb.LoginService.Login(req)
      .then((res) => {
        const { accessToken, refreshToken } = res;

        runInAction(() => {
          this.setTokens(accessToken, refreshToken);
          this.isLoading = false;
        });
        return res;
      })
      .catch((e) => {
        logger.error('[LoginStore::submitCode]', e);
        this.isLoading = false;
      });
  };

  @action refresh = () => {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }
    if (!this.refreshToken) {
      logger.warn(`[refreshAccessToken] empty refresh token`);
      this.resetState();
      return Promise.reject(new Error(`refreshToken empty`));
    }
    this.refreshPromise = GrpcService.PlatformCRMWeb.LoginService.RefreshToken(
      {
        refreshToken: this.refreshToken,
      },
      { ignoreInterceptors: true },
    )
      .then(({ refreshToken, accessToken }) => {
        this.setTokens(accessToken, refreshToken);
        logger.debug(`[RefreshToken] refreshed ok`);
        this.refreshPromise = null;
      })
    return this.refreshPromise;
  };

  private parceToken = (token: string): { userId?: string } => {
    return JSON.parse(window.atob(token.split('.')[1]));
  };
}
