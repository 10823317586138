import 'reflect-metadata'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { SignInByPhone } from '@qlean/admin-web-login'

import LoginStore from 'src/store/LoginStore'
import PrivateRoute from 'src/features/PrivateRoute'

import 'src/vendor/styles/pages/authentication.scss'
import 'src/vendor/styles/bootstrap.scss'
import 'src/vendor/styles/appwork.scss'
import 'src/vendor/styles/theme-corporate.scss'
import 'src/vendor/styles/colors.scss'
import 'src/vendor/styles/uikit.scss'
import 'src/App.scss'

import { titleTemplate } from './routes'
import GrpcService from 'src/services/GrpcService'

const setTitle = (title) => {
  document.title = titleTemplate.replace('%s', title)
}

const CloseButton = ({ closeToast }) => (
  <button className="Toastify__close-button" type="button" aria-label="close" onClick={closeToast}>
    &times;
  </button>
)

const App = () => {
  const { accessToken } = LoginStore
  const onSubmitPhone = (values) => {
    const { ...credentials } = values
    const { submitPhone } = LoginStore

    return submitPhone({ phone: credentials.phone })
  }

  const onSubmitCode = (values) => {
    const { ...credentials } = values
    const { submitCode } = LoginStore

    return submitCode({ phone: credentials.phone, code: credentials.code })
  }

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        <Route
          path="/login"
          exact
          render={(props) => (
            <div className="authentication-wrapper authentication-1 px-4">
              <div className="authentication-inner py-5">
                <SignInByPhone
                  {...props}
                  dictionary={[
                    {
                      error: 'CANCELLED - Error: login error Request failed with status code 401',
                      message: 'Введен не верный код из смс',
                    },
                    {
                      error:
                        'CANCELLED - Error: requestOtp error Request failed with status code 400',
                      message: 'Попробуйте позже, слишком много запросов',
                    },
                    {
                      error:
                        'CANCELLED - Error: requestOtp error Request failed with status code 429',
                      message: 'Попробуйте позже, слишком много запросов',
                    },
                  ]}
                  setTitle={setTitle}
                  path={{ linkSubmit: '/' }}
                  onSubmitPhone={onSubmitPhone}
                  onSubmitCode={onSubmitCode}
                />
              </div>
            </div>
          )}
        />
        <PrivateRoute />
        <Redirect from="*" to="/demo" exact={true} />
      </Switch>
      <ToastContainer closeButton={<CloseButton closeToast={() => {}} />} />
    </BrowserRouter>
  )
}

export default App
