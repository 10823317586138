import React, { useState, useEffect } from 'react'

import DatePicker from 'react-datepicker'
import { observer } from 'mobx-react'
import { Form, Col } from 'react-bootstrap'
import { Field } from 'react-final-form'
import { FieldLabel } from 'src/components/Fields/FieldLabel'
import { FieldError } from 'src/components/Fields/FieldError'

import { TFieldProp } from 'src/components/Fields/field.interface'

import ru from 'date-fns/locale/ru'

import 'src/vendor/libs/react-datepicker/react-datepicker.scss'

// import MetaStore from 'src/store/MetaStore'

export interface ITimeSlotField {
  prospectId: string
}

export type TTimeSlotField = ITimeSlotField & TFieldProp<string>

export const TimeSlotField = observer((props: TTimeSlotField) => {
  const {
    name,
    isRequired,
    isReadonly,
    regionId,
    productSlug,
    // prospectId,
  } = props
  const initialValue = props.initialValue ? new Date(props.initialValue) : undefined
  // const {
  //   // getAvailableDates,
  //   // getAvailableTimeSlots,
  // } = MetaStore

  // const [includedDates, setIncludedDates] = useState<Date[]>([])
  // const [includedTimes, setIncludedTimes] = useState<Date[]>([])
  const [chosedDate, setChosedDate] = useState<Date | undefined>(undefined)
  const [chosedTime, setChosedTime] = useState<Date | undefined>(undefined)

  useEffect(() => {
    const fetchDates = async () => {
      if (regionId && productSlug) {
        // const availableDates = await getAvailableDates({ regionId, productSlug })
        // setIncludedDates(availableDates.map((date) => new Date(date)))
        setChosedDate(initialValue)
        setChosedTime(initialValue)
      }
    }
    fetchDates()
  }, [])

  // useEffect(() => {
  //   const fetchTimeSlots = async () => {
  //     if (regionId && productSlug && chosedDate) {
  //       const availableTimes = await getAvailableTimeSlots({
  //         prospectId,
  //         date: chosedDate.toUTCString(),
  //       })
  //       setIncludedTimes(availableTimes.map((date) => new Date(date)))
  //       if (!initialValue || chosedDate.toUTCString() !== initialValue.toUTCString()) {
  //         setChosedTime(undefined)
  //       }
  //     }
  //   }
  //   fetchTimeSlots()
  // }, [chosedDate])

  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <Form.Row>
          <Form.Group controlId={name} as={Col} md={6}>
            <FieldLabel label="Дата" isRequired={isRequired} />
            <DatePicker
              className="form-control"
              selected={chosedDate}
              dateFormat="dd MMMM yyyy"
              locale={ru}
              placeholderText="Выберите дату"
              disabled={isReadonly}
              // includeDates={includedDates}
              filterDate={(date) => {
                return date >= new Date()
              }}
              onChange={(date) => {
                input.onChange(date)
                setChosedDate(date)
              }}
            />
          </Form.Group>
          <Form.Group controlId={name} as={Col} md={6}>
            <FieldLabel label="Время" isRequired={isRequired} />
            <DatePicker
              className="form-control"
              selected={chosedTime}
              onChange={(date) => {
                input.onChange(date)
                setChosedTime(date)
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              locale={ru}
              placeholderText="Выберите время"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              timeCaption="Время"
              // includeTimes={includedTimes}
            />
          </Form.Group>
          <FieldError error={meta.error} />
        </Form.Row>
      )}
    />
  )
})
