import { validate as baseValidate, ValidationError, ValidationSchema } from 'class-validator'
import { IForm } from './form.interface'

export interface IValidateErrorMessages {
  readonly [k: string]: string
}

export function getValidatorSchemaName(form: IForm): string {
  return `${form.name}_ValidatorSchema`
}

export function getValidatorSchema(form: IForm): ValidationSchema {
  return Array.prototype.concat.apply([], form.rows).reduce(
    (memo, field) => {
      if (Array.isArray(field.validators)) {
        memo.properties[field.name] = field.validators
      }

      return memo
    },
    {
      name: getValidatorSchemaName(form),
      properties: {},
    }
  )
}

export async function validate(form: IForm, values: any): Promise<IValidateErrorMessages | void> {
  const validationErrors: ValidationError[] = await baseValidate(
    getValidatorSchemaName(form),
    values
  )

  const toError = (memo, error: ValidationError) => {
    const property = error.property

    memo[property] = Object.keys(error.constraints).map((key) => error.constraints[key])

    return memo
  }

  if (validationErrors.length) {
    return validationErrors.reduce(toError, {})
  }

  return
}
