import { Type } from 'class-transformer'
import { PlatformCRMWeb } from 'src/services/GrpcService'

export class PriceElementMatrixItemDto implements PlatformCRMWeb.IPriceElementMatrixItem {
  range!: string
  value!: number
}

export class PriceElementMatrixDto implements PlatformCRMWeb.IPriceElementMatrix {
  per!: PlatformCRMWeb.MatrixUnit
  @Type(() => PriceElementMatrixItemDto)
  items!: PriceElementMatrixItemDto[]
}

export class PriceElementOptionDto implements PlatformCRMWeb.IPriceElementOption {
  @Type(() => PriceElementDto)
  priceElement!: PriceElementDto
  slug!: string
  costLinear!: number
  @Type(() => PriceElementMatrixDto)
  costMatrix!: PriceElementMatrixDto
  durationLinear!: number
  @Type(() => PriceElementMatrixDto)
  durationMatrix!: PriceElementMatrixDto
  isFree!: boolean
  defaultValue!: number
  tags!: string[]
  title!: string
  shortDesc!: string
  iconName!: string
  type!: PlatformCRMWeb.PriceElementOptionType
}

export class PriceElementDto implements PlatformCRMWeb.IPriceElement {
  id!: string
  productSlug!: string
  @Type(() => PriceElementOptionDto)
  options!: PriceElementOptionDto[]
  cost!: number
  duration!: number
}
