import { action, observable, runInAction } from 'mobx'
import { plainToClass } from 'class-transformer'

import GrpcService from 'src/services/GrpcService'
import {
  OrderDto,
  ShortOrderDto,
  GetOrderRequestDto,
  GetOrdersByExecutorRequestDto,
  SaveOrderReportDto,
  ExecutorLinkDto,
  SendInvoiceRequestDto,
} from './OrderStore.dto'

import LoginStore from '../LoginStore'
import { Metadata } from 'grpc-web'
export default class OrderStore {
  @observable isLoading: boolean = false
  @observable order?: OrderDto
  @observable executors: ExecutorLinkDto[] = []
  @observable orders: ShortOrderDto[] = []

  @action getOrder = (args: GetOrderRequestDto) => {
    this.isLoading = true
    const req = { ...args }

    GrpcService.PlatformCRMWeb.OrderService.GetOrder(req)
      .then((res) => {
        const { order, executors } = res

        runInAction(() => {
          this.order = plainToClass(OrderDto, order)
          this.executors = executors.map((item) => plainToClass(ExecutorLinkDto, item))
          this.isLoading = false
        })
      })
      .catch((err) => {
        console.error(err)
        this.isLoading = false
      })
  }

  @action getOrdersByExecutor = (args: GetOrdersByExecutorRequestDto) => {
    this.isLoading = true
    const req = { ...args }
    console.debug('[OrderService.GetOrders] start', req)

    GrpcService.PlatformCRMWeb.OrderService.GetOrders(req)
      .then((res) => {
        const { orders } = res
        runInAction(() => {
          this.orders = plainToClass(ShortOrderDto, orders)
          this.isLoading = false
        })
      })
      .catch((err) => {
        console.error(err)
        this.isLoading = false
      })
  }

  @action sendOrderReport = (args: SaveOrderReportDto) => {
    this.isLoading = true
    const req = { ...args }

    return GrpcService.PlatformCRMWeb.OrderService.SaveOrderReport(req)
      .then((res) => {
        this.isLoading = false
        return true
      })
      .catch((err) => {
        console.error(err)
        this.isLoading = false
        return false
      })
  }

  @action sendInvoice = (args: SendInvoiceRequestDto) => {
    this.isLoading = true
    const req = { ...args }

    return GrpcService.PlatformCRMWeb.OrderService.SendInvoice(req)
      .then((res) => {
        this.isLoading = false
        return true
      })
      .catch((err) => {
        console.error(err)
        this.isLoading = false
        return false
      })
  }
}
