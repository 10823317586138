import { action, observable, runInAction } from 'mobx'
import GrpcService, { PlatformCRMWeb } from 'src/services/GrpcService'
import { logger } from '@qlean/front-logger'
import * as formInterface from 'src/components/Forms/form.interface'
import { toast } from 'react-toastify'
import { ControlProps } from 'src/components/Formik'

type OptionId = string
const OTHER_OPTION_KEY = 'other'
export interface FormValues {
  [reviewerSubjectKey: string]: { [questionKey: string]: OptionId | OptionId[] }
}

interface FormConfig {
  [subjectId: string]: ControlProps[]
}

export default class QuestionnaireStore {
  @observable reviewer: PlatformCRMWeb.IQuestionnaireExecutor | undefined | null
  @observable reviewSubjects: PlatformCRMWeb.IQuestionnaireExecutor[] = []
  @observable isLoading: boolean = false
  @observable hasAnswers: boolean = false
  @observable isLoadingQuestionnaire = true

  private orderId: string | undefined | null

  private get optionsMap() {
    return this.reviewSubjects.reduce((optionsMapAcc, currSubject) => {
      currSubject.questions?.forEach((question) => {
        question.options?.forEach((option) => {
          optionsMapAcc[option.id!] = option
        })
      })

      return optionsMapAcc
    }, {})
  }

  variantsMap = {
    checkbox: 'radio',
    checkbox_with_other_option: 'checkbox',
    radio: 'radio',
  }

  get formConfig(): FormConfig {
    return this.reviewSubjects.reduce((config, currReviewerSubject) => {
      config[currReviewerSubject.id!] = []

      currReviewerSubject.questions?.forEach((question) => {
        const controlConfig = {
          name: `${currReviewerSubject.id}.question-${question.id}`,
          control: this.variantsMap[question.type!],
          optionLabelKey: 'title',
          optionValueKey: 'id',
          label: question.question,
          options: question.options || [],
          required: question.required,
        }

        if (!!question.parentOptionId) {
          controlConfig['hiddenByDefault'] = true
          controlConfig['on'] = {
            fieldName: `${currReviewerSubject.id}.question-${
              this.optionsMap[question.parentOptionId].questionId
            }`,
            toBe: String(question.parentOptionId),
          }
        }

        config[currReviewerSubject.id!].push(controlConfig)

        if (question.type === 'checkbox_with_other_option') {
          config[currReviewerSubject.id!].push({
            hiddenByDefault: true,
            name: `${currReviewerSubject.id}.question-${question.id}-${OTHER_OPTION_KEY}`,
            control: 'input',
            variant: 'textarea',
            rows: 8,
            label: 'Опишите ситуацию',
            on: {
              fieldName: `${currReviewerSubject.id}.question-${question.id}`,
              contains: 'other',
            },
          })
        }
      })

      return config
    }, {}) as FormConfig
  }

  @action async getQuestionnaire(orderId: string) {
    try {
      console.log(orderId);
      
      const {
        reviewer,
        reviewSubjects,
        hasAnswers,
      } = await GrpcService.PlatformCRMWeb.QuestionnaireService.GetQuestionnaire({
        orderId,
      })

      runInAction(() => {
        this.reviewer = reviewer
        this.reviewSubjects = reviewSubjects
        this.orderId = orderId
        this.hasAnswers = hasAnswers
        this.isLoadingQuestionnaire = false
      })
    } catch (error) {
      logger.error(error)
      this.isLoadingQuestionnaire = false
    }
  }

  @action async submitQuestionnaire(formValues: FormValues) {
    this.isLoading = true

    try {
      const requestBodies: PlatformCRMWeb.IClientSubmitQuestionnaireRequest[] = Object.keys(
        formValues
      ).reduce(
        (requestBodies: PlatformCRMWeb.IClientSubmitQuestionnaireRequest[], currSubjectKey) => {
          const otherOptionKey = Object.keys(formValues[currSubjectKey]).find((questionKey) =>
            questionKey.includes('other')
          )

          Object.keys(formValues[currSubjectKey]).forEach((questionKey) => {
            if (questionKey === otherOptionKey) {
              return
            }

            if (Array.isArray(formValues[currSubjectKey][questionKey])) {
              (formValues[currSubjectKey][questionKey] as OptionId[]).forEach(
                (optionId: OptionId) => {
                  requestBodies.push({
                    orderId: this.orderId,
                    optionId: optionId === OTHER_OPTION_KEY ? undefined : Number(optionId),
                    questionId: this.optionsMap[optionId].questionId,
                    reviewerUserId: this.reviewer!.id,
                    reviewerSubjectUserId: currSubjectKey,
                    answer:
                      optionId === OTHER_OPTION_KEY
                        ? (formValues[currSubjectKey][otherOptionKey!] as string)
                        : undefined,
                  })
                }
              )

              return
            }

            requestBodies.push({
              orderId: this.orderId,
              optionId: Number(formValues[currSubjectKey][questionKey]),
              questionId: this.optionsMap[formValues[currSubjectKey][questionKey] as string]
                .questionId,
              reviewerUserId: this.reviewer!.id,
              reviewerSubjectUserId: currSubjectKey,
              answer: undefined,
            })
          })
          return requestBodies
        },
        []
      )

      const isValidForm = this.validateForm(requestBodies)

      if (!isValidForm) {
        toast('Вы не ответили на все обязательные вопросы', { type: 'error' })
        this.isLoading = false

        return
      }

      if (requestBodies.some((body) => !body.optionId && !body.answer)) {
        toast('Если выбрана опция "Другое", необходимммо заполнить описание', { type: 'error' })
        this.isLoading = false

        return
      }

      const requests: Promise<
        PlatformCRMWeb.IClientSubmitQuestionnaireResponse
      >[] = requestBodies.map((requestBody) =>
        GrpcService.PlatformCRMWeb.QuestionnaireService.SubmitQuestionnaire(requestBody)
      )

      const response = await Promise.allSettled(requests)

      toast('Вы успешно отвелили на вопросы. Спасибо, что уделили время', { type: 'success' })
      this.isLoading = false

      return response
    } catch (error) {
      toast('Не удалось сохранить ответы на вопросы', { type: 'error' })
      this.isLoading = false

      return
    }
  }

  validateForm(requestBodies: PlatformCRMWeb.IClientSubmitQuestionnaireRequest[]): boolean {
    const requiredQuestions = this.reviewSubjects
      .reduce((acc: any[], subject) => {
        return [...acc, ...(subject.questions || [])]
      }, [])
      .filter((question) => question.required)
      .reduce((acc, curr) => {
        acc[curr.id!] = true

        return acc
      }, {})

    requestBodies.forEach(
      (body: PlatformCRMWeb.IClientSubmitQuestionnaireRequest) =>
        delete requiredQuestions[body.questionId!]
    )

    return !Object.keys(requiredQuestions).length
  }
}
