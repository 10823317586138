import React from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react'

import { Navbar, Nav } from 'react-bootstrap'
import { ReactComponent as Logo } from './logo.svg'

import layoutHelpers from './helpers'

interface IProps {
  navbarBg?: string
  sidenavToggle?: boolean
}

const LayoutHeader = observer((props: IProps) => {
  const toggleSidenav = (e: React.MouseEvent) => {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }
  const Divider = (
    <div
      className={
        'nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1'
      }
    >
      {'|'}
    </div>
  )

  return (
    <Navbar
      bg={props.navbarBg}
      expand={'lg'}
      className={
        'layout-navbar align-items-lg-center container-p-x navbar navbar-expand-lg navbar-light bg-navbar-theme'
      }
    >
      {/* Brand demo (see src/demo.css) */}
      <Navbar.Brand as={NavLink} to={'/'} className={'app-brand demo d-lg-none py-0 mr-4'}>
        <Logo className={'app-brand-logo demo bg-primary'} />
        <span className={'app-brand-text demo sidenav-text font-weight-normal ml-2'}>
          Executors CRM
        </span>
      </Navbar.Brand>

      {/* Sidenav toggle (see src/demo.css) */}
      {props.sidenavToggle && (
        <Nav className={'layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto'}>
          <Nav.Item
            as={'a'}
            className={'nav-link px-0 mr-lg-4'}
            href={'#toggle'}
            onClick={toggleSidenav}
          >
            <i className={'ion ion-md-menu text-large align-middle'} />
          </Nav.Item>
        </Nav>
      )}

      {/* Navbar toggle */}
      <Navbar.Toggle />

      <Navbar.Collapse>
        <Nav className={'align-items-lg-center ml-auto'}>
          <span>Исполнитель</span>
          {Divider}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
})

export default LayoutHeader
