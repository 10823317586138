import React from 'react'
import { Form } from 'react-bootstrap'
import { Field } from 'react-final-form'
import formatString from 'format-string-by-pattern'
import { FieldLabel } from '../FieldLabel'
import { TFieldProp } from '../field.interface'
import { FieldError } from '../FieldError'

/**
 * @see https://www.npmjs.com/package/format-string-by-pattern
 */
export interface IMaskedFieldProp {
  readonly mask?: string
}

export class MaskedField extends React.Component<IMaskedFieldProp & TFieldProp<string>> {
  static defaultProps: Partial<IMaskedFieldProp & TFieldProp<string>> = {
    isReadonly: false,
    isRequired: false,
  }

  parser(value?: string): string {
    const { mask = '' } = this.props
    return formatString(mask)(value)
  }

  render() {
    const { mask = '', name, isRequired, isReadonly, initialValue, placeholder, label } = this.props

    return (
      <Field
        name={name}
        parse={this.parser.bind(this)}
        initialValue={this.parser(initialValue)}
        render={({ input, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <Form.Control
              {...input}
              placeholder={placeholder || mask}
              readOnly={isReadonly}
              isInvalid={FieldError.isInvalid(meta)}
            />
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    )
  }
}
