import React from 'react'
import { logger } from '@qlean/front-logger'
import * as ReactDOM from 'react-dom'

import App from './App'

const rootElement = document.getElementById('root')

const { REACT_APP_SENTRY_DSN_CLIENT, REACT_APP_SENTRY_ENVIRONMENT } = process.env
const phone = localStorage.getItem('phone')
logger.setConfig({ uuid: phone || undefined, tag: 'platform-crm-executors-web' })
logger.info('environment', {
  dsn: REACT_APP_SENTRY_DSN_CLIENT,
  environment: REACT_APP_SENTRY_ENVIRONMENT,
})
logger.init({ dsn: REACT_APP_SENTRY_DSN_CLIENT, environment: REACT_APP_SENTRY_ENVIRONMENT })

ReactDOM.render(<App />, rootElement)
