import React from 'react'

import { Form } from 'react-bootstrap'
import { Field } from 'react-final-form'
import { FieldLabel } from 'src/components/Fields/FieldLabel'
import { FieldError } from 'src/components/Fields/FieldError'

import { TFieldProp } from 'src/components/Fields/field.interface'

export interface IBooleanFieldProp {
  variant?: 'default' | 'with-icon' | 'square' | 'checkbox'
  onChange: (checked: boolean) => void
}

export type TBooleanFieldProp = IBooleanFieldProp & TFieldProp<boolean>

export class BooleanField extends React.Component<TBooleanFieldProp> {
  static defaultProps: Partial<TBooleanFieldProp> = {
    isReadonly: false,
    isRequired: false,
  }

  private renderIndicator = (needIcons: boolean) => (
    <span className="switcher-indicator">
      <span className="switcher-yes">
        <span hidden={!needIcons} className="ion ion-md-checkmark"></span>
      </span>
      <span className="switcher-no">
        <span hidden={!needIcons} className="ion ion-md-close"></span>
      </span>
    </span>
  )

  render() {
    const {
      name,
      isRequired,
      isReadonly,
      label,
      variant = 'default',
      placeholder = 'Описание',
      initialValue,
      onChange,
      ...rest
    } = this.props

    let labelClassName = /checkbox/.test(variant) ? 'custom-control custom-checkbox' : 'switcher'
    labelClassName += /square/.test(variant) ? ' switcher-square' : ''

    const inputClassName = /checkbox/.test(variant) ? 'custom-control' : 'switcher'

    return (
      <Field
        name={name}
        type="checkbox"
        initialValue={initialValue}
        render={({ input, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <div>
              <label {...input} {...rest} className={labelClassName}>
                <input
                  type="checkbox"
                  className={`${inputClassName}-input`}
                  disabled={isReadonly}
                  defaultChecked={initialValue}
                  onChange={(evt) => {
                    onChange(evt.target.checked)
                    input.onChange(evt.target.checked)
                  }}
                />
                {this.renderIndicator(/with-icon/.test(variant))}
                <span className={`${inputClassName}-label`}>{placeholder}</span>
              </label>
            </div>
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    )
  }
}
