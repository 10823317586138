import React from 'react'
import { Form, Card } from 'react-bootstrap'
import { Field } from 'react-final-form'
import { FieldLabel } from '../FieldLabel'
import { TFieldProp } from '../field.interface'
import { FieldError } from '../FieldError'
import TextareaAutosize from 'react-autosize-textarea'

export interface IStringFieldProp {
  variant?: 'default' | 'textarea'
}

export type TStringFieldProp = IStringFieldProp & TFieldProp<string>

export class StringField extends React.Component<TStringFieldProp> {
  static defaultProps: Partial<TFieldProp<string>> = {
    isReadonly: false,
    isRequired: false,
  }

  renderField = (input, meta) => {
    const { variant, isReadonly, placeholder, disabled, initialValue, href } = this.props
    if (variant === 'textarea') {
      return (
        <TextareaAutosize
          {...input}
          rows={3}
          maxRows={15}
          className="form-control"
          readOnly={isReadonly}
          placeholder={placeholder}
          disabled={disabled}
        />
      )
    }
    if (isReadonly) {
      return (
        <div className="form-control" style={{ border: 'none' }}>
          <Card.Link target="_blank" href={href}>
            {initialValue}
          </Card.Link>
        </div>
      )
    }
    return (
      <Form.Control
        {...input}
        readOnly={isReadonly}
        placeholder={placeholder}
        isInvalid={FieldError.isInvalid(meta)}
        disabled={disabled}
      />
    )
  }

  render() {
    const { name, initialValue, isRequired, label } = this.props

    return (
      <Field
        name={name}
        initialValue={initialValue}
        render={({ input, meta }) => {
          return (
            <Form.Group controlId={name}>
              <FieldLabel {...label} isRequired={isRequired} />
              {this.renderField(input, meta)}
              <FieldError error={meta.error} />
            </Form.Group>
          )
        }}
      />
    )
  }
}
