/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.PlatformCRMWeb = (function() {

    /**
     * Namespace PlatformCRMWeb.
     * @exports PlatformCRMWeb
     * @namespace
     */
    var PlatformCRMWeb = {};

    PlatformCRMWeb.LoginService = (function() {

        /**
         * Constructs a new LoginService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a LoginService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function LoginService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (LoginService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = LoginService;

        /**
         * Callback as used by {@link PlatformCRMWeb.LoginService#login}.
         * @memberof PlatformCRMWeb.LoginService
         * @typedef LoginCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.TokenResponse} [response] TokenResponse
         */

        /**
         * Calls Login.
         * @function login
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.ILoginRequest} request LoginRequest message or plain object
         * @param {PlatformCRMWeb.LoginService.LoginCallback} callback Node-style callback called with the error, if any, and TokenResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoginService.prototype.login = function login(request, callback) {
            return this.rpcCall(login, $root.PlatformCRMWeb.LoginRequest, $root.PlatformCRMWeb.TokenResponse, request, callback);
        }, "name", { value: "Login" });

        /**
         * Calls Login.
         * @function login
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.ILoginRequest} request LoginRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.TokenResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.LoginService#requestOtp}.
         * @memberof PlatformCRMWeb.LoginService
         * @typedef RequestOtpCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.OtpResponse} [response] OtpResponse
         */

        /**
         * Calls RequestOtp.
         * @function requestOtp
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.IOtpRequest} request OtpRequest message or plain object
         * @param {PlatformCRMWeb.LoginService.RequestOtpCallback} callback Node-style callback called with the error, if any, and OtpResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoginService.prototype.requestOtp = function requestOtp(request, callback) {
            return this.rpcCall(requestOtp, $root.PlatformCRMWeb.OtpRequest, $root.PlatformCRMWeb.OtpResponse, request, callback);
        }, "name", { value: "RequestOtp" });

        /**
         * Calls RequestOtp.
         * @function requestOtp
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.IOtpRequest} request OtpRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.OtpResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.LoginService#refreshToken}.
         * @memberof PlatformCRMWeb.LoginService
         * @typedef RefreshTokenCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.TokenResponse} [response] TokenResponse
         */

        /**
         * Calls RefreshToken.
         * @function refreshToken
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.IRefreshTokenRequest} request RefreshTokenRequest message or plain object
         * @param {PlatformCRMWeb.LoginService.RefreshTokenCallback} callback Node-style callback called with the error, if any, and TokenResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoginService.prototype.refreshToken = function refreshToken(request, callback) {
            return this.rpcCall(refreshToken, $root.PlatformCRMWeb.RefreshTokenRequest, $root.PlatformCRMWeb.TokenResponse, request, callback);
        }, "name", { value: "RefreshToken" });

        /**
         * Calls RefreshToken.
         * @function refreshToken
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.IRefreshTokenRequest} request RefreshTokenRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.TokenResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.LoginService#sendEmailAndPass}.
         * @memberof PlatformCRMWeb.LoginService
         * @typedef SendEmailAndPassCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.TokenResponse} [response] TokenResponse
         */

        /**
         * Calls SendEmailAndPass.
         * @function sendEmailAndPass
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.ISendEmailAndPassRequest} request SendEmailAndPassRequest message or plain object
         * @param {PlatformCRMWeb.LoginService.SendEmailAndPassCallback} callback Node-style callback called with the error, if any, and TokenResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoginService.prototype.sendEmailAndPass = function sendEmailAndPass(request, callback) {
            return this.rpcCall(sendEmailAndPass, $root.PlatformCRMWeb.SendEmailAndPassRequest, $root.PlatformCRMWeb.TokenResponse, request, callback);
        }, "name", { value: "SendEmailAndPass" });

        /**
         * Calls SendEmailAndPass.
         * @function sendEmailAndPass
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.ISendEmailAndPassRequest} request SendEmailAndPassRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.TokenResponse>} Promise
         * @variation 2
         */

        return LoginService;
    })();

    PlatformCRMWeb.OtpRequest = (function() {

        /**
         * Properties of an OtpRequest.
         * @memberof PlatformCRMWeb
         * @interface IOtpRequest
         * @property {number|null} [connection] OtpRequest connection
         * @property {number|null} [userType] OtpRequest userType
         * @property {string|null} [login] OtpRequest login
         * @property {number|null} [send] OtpRequest send
         * @property {string|null} [userAgent] OtpRequest userAgent
         * @property {string|null} [csrfToken] OtpRequest csrfToken
         */

        /**
         * Constructs a new OtpRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OtpRequest.
         * @implements IOtpRequest
         * @constructor
         * @param {PlatformCRMWeb.IOtpRequest=} [properties] Properties to set
         */
        function OtpRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OtpRequest connection.
         * @member {number} connection
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.connection = 0;

        /**
         * OtpRequest userType.
         * @member {number} userType
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.userType = 0;

        /**
         * OtpRequest login.
         * @member {string} login
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.login = "";

        /**
         * OtpRequest send.
         * @member {number} send
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.send = 0;

        /**
         * OtpRequest userAgent.
         * @member {string} userAgent
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.userAgent = "";

        /**
         * OtpRequest csrfToken.
         * @member {string} csrfToken
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.csrfToken = "";

        /**
         * Encodes the specified OtpRequest message. Does not implicitly {@link PlatformCRMWeb.OtpRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.OtpRequest
         * @static
         * @param {PlatformCRMWeb.IOtpRequest} message OtpRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OtpRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.connection != null && Object.hasOwnProperty.call(message, "connection"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.connection);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.userType);
            if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.login);
            if (message.send != null && Object.hasOwnProperty.call(message, "send"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.send);
            if (message.userAgent != null && Object.hasOwnProperty.call(message, "userAgent"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.userAgent);
            if (message.csrfToken != null && Object.hasOwnProperty.call(message, "csrfToken"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.csrfToken);
            return writer;
        };

        /**
         * Decodes an OtpRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.OtpRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.OtpRequest} OtpRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OtpRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.OtpRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.connection = reader.int32();
                    break;
                case 3:
                    message.userType = reader.int32();
                    break;
                case 4:
                    message.login = reader.string();
                    break;
                case 5:
                    message.send = reader.int32();
                    break;
                case 6:
                    message.userAgent = reader.string();
                    break;
                case 7:
                    message.csrfToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OtpRequest;
    })();

    PlatformCRMWeb.OtpResponse = (function() {

        /**
         * Properties of an OtpResponse.
         * @memberof PlatformCRMWeb
         * @interface IOtpResponse
         * @property {string|null} [code] OtpResponse code
         */

        /**
         * Constructs a new OtpResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OtpResponse.
         * @implements IOtpResponse
         * @constructor
         * @param {PlatformCRMWeb.IOtpResponse=} [properties] Properties to set
         */
        function OtpResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OtpResponse code.
         * @member {string} code
         * @memberof PlatformCRMWeb.OtpResponse
         * @instance
         */
        OtpResponse.prototype.code = "";

        /**
         * Encodes the specified OtpResponse message. Does not implicitly {@link PlatformCRMWeb.OtpResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.OtpResponse
         * @static
         * @param {PlatformCRMWeb.IOtpResponse} message OtpResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OtpResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            return writer;
        };

        /**
         * Decodes an OtpResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.OtpResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.OtpResponse} OtpResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OtpResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.OtpResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OtpResponse;
    })();

    PlatformCRMWeb.LoginRequest = (function() {

        /**
         * Properties of a LoginRequest.
         * @memberof PlatformCRMWeb
         * @interface ILoginRequest
         * @property {string|null} [phone] LoginRequest phone
         * @property {string|null} [code] LoginRequest code
         */

        /**
         * Constructs a new LoginRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a LoginRequest.
         * @implements ILoginRequest
         * @constructor
         * @param {PlatformCRMWeb.ILoginRequest=} [properties] Properties to set
         */
        function LoginRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginRequest phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.LoginRequest
         * @instance
         */
        LoginRequest.prototype.phone = "";

        /**
         * LoginRequest code.
         * @member {string} code
         * @memberof PlatformCRMWeb.LoginRequest
         * @instance
         */
        LoginRequest.prototype.code = "";

        /**
         * Encodes the specified LoginRequest message. Does not implicitly {@link PlatformCRMWeb.LoginRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.LoginRequest
         * @static
         * @param {PlatformCRMWeb.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            return writer;
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.LoginRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.phone = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return LoginRequest;
    })();

    PlatformCRMWeb.RefreshTokenRequest = (function() {

        /**
         * Properties of a RefreshTokenRequest.
         * @memberof PlatformCRMWeb
         * @interface IRefreshTokenRequest
         * @property {string|null} [refreshToken] RefreshTokenRequest refreshToken
         */

        /**
         * Constructs a new RefreshTokenRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a RefreshTokenRequest.
         * @implements IRefreshTokenRequest
         * @constructor
         * @param {PlatformCRMWeb.IRefreshTokenRequest=} [properties] Properties to set
         */
        function RefreshTokenRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RefreshTokenRequest refreshToken.
         * @member {string} refreshToken
         * @memberof PlatformCRMWeb.RefreshTokenRequest
         * @instance
         */
        RefreshTokenRequest.prototype.refreshToken = "";

        /**
         * Encodes the specified RefreshTokenRequest message. Does not implicitly {@link PlatformCRMWeb.RefreshTokenRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.RefreshTokenRequest
         * @static
         * @param {PlatformCRMWeb.IRefreshTokenRequest} message RefreshTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.refreshToken);
            return writer;
        };

        /**
         * Decodes a RefreshTokenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.RefreshTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.RefreshTokenRequest} RefreshTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.RefreshTokenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.refreshToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RefreshTokenRequest;
    })();

    PlatformCRMWeb.SendEmailAndPassRequest = (function() {

        /**
         * Properties of a SendEmailAndPassRequest.
         * @memberof PlatformCRMWeb
         * @interface ISendEmailAndPassRequest
         * @property {string|null} [email] SendEmailAndPassRequest email
         * @property {string|null} [password] SendEmailAndPassRequest password
         */

        /**
         * Constructs a new SendEmailAndPassRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SendEmailAndPassRequest.
         * @implements ISendEmailAndPassRequest
         * @constructor
         * @param {PlatformCRMWeb.ISendEmailAndPassRequest=} [properties] Properties to set
         */
        function SendEmailAndPassRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendEmailAndPassRequest email.
         * @member {string} email
         * @memberof PlatformCRMWeb.SendEmailAndPassRequest
         * @instance
         */
        SendEmailAndPassRequest.prototype.email = "";

        /**
         * SendEmailAndPassRequest password.
         * @member {string} password
         * @memberof PlatformCRMWeb.SendEmailAndPassRequest
         * @instance
         */
        SendEmailAndPassRequest.prototype.password = "";

        /**
         * Encodes the specified SendEmailAndPassRequest message. Does not implicitly {@link PlatformCRMWeb.SendEmailAndPassRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SendEmailAndPassRequest
         * @static
         * @param {PlatformCRMWeb.ISendEmailAndPassRequest} message SendEmailAndPassRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendEmailAndPassRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Decodes a SendEmailAndPassRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SendEmailAndPassRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SendEmailAndPassRequest} SendEmailAndPassRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendEmailAndPassRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SendEmailAndPassRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.email = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendEmailAndPassRequest;
    })();

    PlatformCRMWeb.TokenResponse = (function() {

        /**
         * Properties of a TokenResponse.
         * @memberof PlatformCRMWeb
         * @interface ITokenResponse
         * @property {string|null} [accessToken] TokenResponse accessToken
         * @property {string|null} [refreshToken] TokenResponse refreshToken
         */

        /**
         * Constructs a new TokenResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a TokenResponse.
         * @implements ITokenResponse
         * @constructor
         * @param {PlatformCRMWeb.ITokenResponse=} [properties] Properties to set
         */
        function TokenResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TokenResponse accessToken.
         * @member {string} accessToken
         * @memberof PlatformCRMWeb.TokenResponse
         * @instance
         */
        TokenResponse.prototype.accessToken = "";

        /**
         * TokenResponse refreshToken.
         * @member {string} refreshToken
         * @memberof PlatformCRMWeb.TokenResponse
         * @instance
         */
        TokenResponse.prototype.refreshToken = "";

        /**
         * Encodes the specified TokenResponse message. Does not implicitly {@link PlatformCRMWeb.TokenResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.TokenResponse
         * @static
         * @param {PlatformCRMWeb.ITokenResponse} message TokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.accessToken);
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.refreshToken);
            return writer;
        };

        /**
         * Decodes a TokenResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.TokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.TokenResponse} TokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.TokenResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.accessToken = reader.string();
                    break;
                case 2:
                    message.refreshToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TokenResponse;
    })();

    PlatformCRMWeb.EmptyResponse = (function() {

        /**
         * Properties of an EmptyResponse.
         * @memberof PlatformCRMWeb
         * @interface IEmptyResponse
         */

        /**
         * Constructs a new EmptyResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an EmptyResponse.
         * @implements IEmptyResponse
         * @constructor
         * @param {PlatformCRMWeb.IEmptyResponse=} [properties] Properties to set
         */
        function EmptyResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified EmptyResponse message. Does not implicitly {@link PlatformCRMWeb.EmptyResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.EmptyResponse
         * @static
         * @param {PlatformCRMWeb.IEmptyResponse} message EmptyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmptyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an EmptyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.EmptyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.EmptyResponse} EmptyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmptyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.EmptyResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return EmptyResponse;
    })();

    PlatformCRMWeb.GetOrdersRequest = (function() {

        /**
         * Properties of a GetOrdersRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetOrdersRequest
         * @property {string|null} [date] GetOrdersRequest date
         */

        /**
         * Constructs a new GetOrdersRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetOrdersRequest.
         * @implements IGetOrdersRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetOrdersRequest=} [properties] Properties to set
         */
        function GetOrdersRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOrdersRequest date.
         * @member {string} date
         * @memberof PlatformCRMWeb.GetOrdersRequest
         * @instance
         */
        GetOrdersRequest.prototype.date = "";

        /**
         * Encodes the specified GetOrdersRequest message. Does not implicitly {@link PlatformCRMWeb.GetOrdersRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetOrdersRequest
         * @static
         * @param {PlatformCRMWeb.IGetOrdersRequest} message GetOrdersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrdersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.date);
            return writer;
        };

        /**
         * Decodes a GetOrdersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetOrdersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetOrdersRequest} GetOrdersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrdersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetOrdersRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.date = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetOrdersRequest;
    })();

    PlatformCRMWeb.SendInvoiceRequest = (function() {

        /**
         * Properties of a SendInvoiceRequest.
         * @memberof PlatformCRMWeb
         * @interface ISendInvoiceRequest
         * @property {string|null} [orderId] SendInvoiceRequest orderId
         */

        /**
         * Constructs a new SendInvoiceRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SendInvoiceRequest.
         * @implements ISendInvoiceRequest
         * @constructor
         * @param {PlatformCRMWeb.ISendInvoiceRequest=} [properties] Properties to set
         */
        function SendInvoiceRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendInvoiceRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.SendInvoiceRequest
         * @instance
         */
        SendInvoiceRequest.prototype.orderId = "";

        /**
         * Encodes the specified SendInvoiceRequest message. Does not implicitly {@link PlatformCRMWeb.SendInvoiceRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SendInvoiceRequest
         * @static
         * @param {PlatformCRMWeb.ISendInvoiceRequest} message SendInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendInvoiceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            return writer;
        };

        /**
         * Decodes a SendInvoiceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SendInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SendInvoiceRequest} SendInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendInvoiceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SendInvoiceRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendInvoiceRequest;
    })();

    PlatformCRMWeb.GetOrdersResponse = (function() {

        /**
         * Properties of a GetOrdersResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetOrdersResponse
         * @property {Array.<PlatformCRMWeb.IShortOrder>|null} [orders] GetOrdersResponse orders
         */

        /**
         * Constructs a new GetOrdersResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetOrdersResponse.
         * @implements IGetOrdersResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetOrdersResponse=} [properties] Properties to set
         */
        function GetOrdersResponse(properties) {
            this.orders = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOrdersResponse orders.
         * @member {Array.<PlatformCRMWeb.IShortOrder>} orders
         * @memberof PlatformCRMWeb.GetOrdersResponse
         * @instance
         */
        GetOrdersResponse.prototype.orders = $util.emptyArray;

        /**
         * Encodes the specified GetOrdersResponse message. Does not implicitly {@link PlatformCRMWeb.GetOrdersResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetOrdersResponse
         * @static
         * @param {PlatformCRMWeb.IGetOrdersResponse} message GetOrdersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrdersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orders != null && message.orders.length)
                for (var i = 0; i < message.orders.length; ++i)
                    $root.PlatformCRMWeb.ShortOrder.encode(message.orders[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetOrdersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetOrdersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetOrdersResponse} GetOrdersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrdersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetOrdersResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.orders && message.orders.length))
                        message.orders = [];
                    message.orders.push($root.PlatformCRMWeb.ShortOrder.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetOrdersResponse;
    })();

    PlatformCRMWeb.GetOrderRequest = (function() {

        /**
         * Properties of a GetOrderRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetOrderRequest
         * @property {string|null} [orderId] GetOrderRequest orderId
         */

        /**
         * Constructs a new GetOrderRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetOrderRequest.
         * @implements IGetOrderRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetOrderRequest=} [properties] Properties to set
         */
        function GetOrderRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOrderRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.GetOrderRequest
         * @instance
         */
        GetOrderRequest.prototype.orderId = "";

        /**
         * Encodes the specified GetOrderRequest message. Does not implicitly {@link PlatformCRMWeb.GetOrderRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetOrderRequest
         * @static
         * @param {PlatformCRMWeb.IGetOrderRequest} message GetOrderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            return writer;
        };

        /**
         * Decodes a GetOrderRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetOrderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetOrderRequest} GetOrderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetOrderRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetOrderRequest;
    })();

    PlatformCRMWeb.GetOrderResponse = (function() {

        /**
         * Properties of a GetOrderResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetOrderResponse
         * @property {PlatformCRMWeb.IOrder|null} [order] GetOrderResponse order
         * @property {Array.<PlatformCRMWeb.IExecutorLink>|null} [executors] GetOrderResponse executors
         */

        /**
         * Constructs a new GetOrderResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetOrderResponse.
         * @implements IGetOrderResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetOrderResponse=} [properties] Properties to set
         */
        function GetOrderResponse(properties) {
            this.executors = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOrderResponse order.
         * @member {PlatformCRMWeb.IOrder|null|undefined} order
         * @memberof PlatformCRMWeb.GetOrderResponse
         * @instance
         */
        GetOrderResponse.prototype.order = null;

        /**
         * GetOrderResponse executors.
         * @member {Array.<PlatformCRMWeb.IExecutorLink>} executors
         * @memberof PlatformCRMWeb.GetOrderResponse
         * @instance
         */
        GetOrderResponse.prototype.executors = $util.emptyArray;

        /**
         * Encodes the specified GetOrderResponse message. Does not implicitly {@link PlatformCRMWeb.GetOrderResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetOrderResponse
         * @static
         * @param {PlatformCRMWeb.IGetOrderResponse} message GetOrderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                $root.PlatformCRMWeb.Order.encode(message.order, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.executors != null && message.executors.length)
                for (var i = 0; i < message.executors.length; ++i)
                    $root.PlatformCRMWeb.ExecutorLink.encode(message.executors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetOrderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetOrderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetOrderResponse} GetOrderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetOrderResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.order = $root.PlatformCRMWeb.Order.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.executors && message.executors.length))
                        message.executors = [];
                    message.executors.push($root.PlatformCRMWeb.ExecutorLink.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetOrderResponse;
    })();

    PlatformCRMWeb.SaveOrderReportRequest = (function() {

        /**
         * Properties of a SaveOrderReportRequest.
         * @memberof PlatformCRMWeb
         * @interface ISaveOrderReportRequest
         * @property {string|null} [orderId] SaveOrderReportRequest orderId
         * @property {Array.<PlatformCRMWeb.IOrderReportItem>|null} [items] SaveOrderReportRequest items
         * @property {boolean|null} [skipInvoice] SaveOrderReportRequest skipInvoice
         * @property {number|null} [actualTotalDuration] SaveOrderReportRequest actualTotalDuration
         * @property {string|null} [comment] SaveOrderReportRequest comment
         * @property {number|null} [parkingPriceCents] SaveOrderReportRequest parkingPriceCents
         * @property {number|null} [otherExpensesCents] SaveOrderReportRequest otherExpensesCents
         * @property {boolean|null} [isCanceledOrder] SaveOrderReportRequest isCanceledOrder
         */

        /**
         * Constructs a new SaveOrderReportRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SaveOrderReportRequest.
         * @implements ISaveOrderReportRequest
         * @constructor
         * @param {PlatformCRMWeb.ISaveOrderReportRequest=} [properties] Properties to set
         */
        function SaveOrderReportRequest(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveOrderReportRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @instance
         */
        SaveOrderReportRequest.prototype.orderId = "";

        /**
         * SaveOrderReportRequest items.
         * @member {Array.<PlatformCRMWeb.IOrderReportItem>} items
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @instance
         */
        SaveOrderReportRequest.prototype.items = $util.emptyArray;

        /**
         * SaveOrderReportRequest skipInvoice.
         * @member {boolean} skipInvoice
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @instance
         */
        SaveOrderReportRequest.prototype.skipInvoice = false;

        /**
         * SaveOrderReportRequest actualTotalDuration.
         * @member {number} actualTotalDuration
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @instance
         */
        SaveOrderReportRequest.prototype.actualTotalDuration = 0;

        /**
         * SaveOrderReportRequest comment.
         * @member {string} comment
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @instance
         */
        SaveOrderReportRequest.prototype.comment = "";

        /**
         * SaveOrderReportRequest parkingPriceCents.
         * @member {number} parkingPriceCents
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @instance
         */
        SaveOrderReportRequest.prototype.parkingPriceCents = 0;

        /**
         * SaveOrderReportRequest otherExpensesCents.
         * @member {number} otherExpensesCents
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @instance
         */
        SaveOrderReportRequest.prototype.otherExpensesCents = 0;

        /**
         * SaveOrderReportRequest isCanceledOrder.
         * @member {boolean} isCanceledOrder
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @instance
         */
        SaveOrderReportRequest.prototype.isCanceledOrder = false;

        /**
         * Encodes the specified SaveOrderReportRequest message. Does not implicitly {@link PlatformCRMWeb.SaveOrderReportRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @static
         * @param {PlatformCRMWeb.ISaveOrderReportRequest} message SaveOrderReportRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveOrderReportRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PlatformCRMWeb.OrderReportItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.skipInvoice != null && Object.hasOwnProperty.call(message, "skipInvoice"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.skipInvoice);
            if (message.actualTotalDuration != null && Object.hasOwnProperty.call(message, "actualTotalDuration"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.actualTotalDuration);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.comment);
            if (message.parkingPriceCents != null && Object.hasOwnProperty.call(message, "parkingPriceCents"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.parkingPriceCents);
            if (message.otherExpensesCents != null && Object.hasOwnProperty.call(message, "otherExpensesCents"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.otherExpensesCents);
            if (message.isCanceledOrder != null && Object.hasOwnProperty.call(message, "isCanceledOrder"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isCanceledOrder);
            return writer;
        };

        /**
         * Decodes a SaveOrderReportRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SaveOrderReportRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SaveOrderReportRequest} SaveOrderReportRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveOrderReportRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SaveOrderReportRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PlatformCRMWeb.OrderReportItem.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.skipInvoice = reader.bool();
                    break;
                case 4:
                    message.actualTotalDuration = reader.float();
                    break;
                case 5:
                    message.comment = reader.string();
                    break;
                case 6:
                    message.parkingPriceCents = reader.int32();
                    break;
                case 7:
                    message.otherExpensesCents = reader.int32();
                    break;
                case 8:
                    message.isCanceledOrder = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SaveOrderReportRequest;
    })();

    PlatformCRMWeb.SaveOrderReportResponse = (function() {

        /**
         * Properties of a SaveOrderReportResponse.
         * @memberof PlatformCRMWeb
         * @interface ISaveOrderReportResponse
         */

        /**
         * Constructs a new SaveOrderReportResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SaveOrderReportResponse.
         * @implements ISaveOrderReportResponse
         * @constructor
         * @param {PlatformCRMWeb.ISaveOrderReportResponse=} [properties] Properties to set
         */
        function SaveOrderReportResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified SaveOrderReportResponse message. Does not implicitly {@link PlatformCRMWeb.SaveOrderReportResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SaveOrderReportResponse
         * @static
         * @param {PlatformCRMWeb.ISaveOrderReportResponse} message SaveOrderReportResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveOrderReportResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a SaveOrderReportResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SaveOrderReportResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SaveOrderReportResponse} SaveOrderReportResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveOrderReportResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SaveOrderReportResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SaveOrderReportResponse;
    })();

    PlatformCRMWeb.SendInvoiceResponse = (function() {

        /**
         * Properties of a SendInvoiceResponse.
         * @memberof PlatformCRMWeb
         * @interface ISendInvoiceResponse
         */

        /**
         * Constructs a new SendInvoiceResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SendInvoiceResponse.
         * @implements ISendInvoiceResponse
         * @constructor
         * @param {PlatformCRMWeb.ISendInvoiceResponse=} [properties] Properties to set
         */
        function SendInvoiceResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified SendInvoiceResponse message. Does not implicitly {@link PlatformCRMWeb.SendInvoiceResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SendInvoiceResponse
         * @static
         * @param {PlatformCRMWeb.ISendInvoiceResponse} message SendInvoiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendInvoiceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a SendInvoiceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SendInvoiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SendInvoiceResponse} SendInvoiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendInvoiceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SendInvoiceResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendInvoiceResponse;
    })();

    PlatformCRMWeb.OrderService = (function() {

        /**
         * Constructs a new OrderService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OrderService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function OrderService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (OrderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OrderService;

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#getOrder}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef GetOrderCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetOrderResponse} [response] GetOrderResponse
         */

        /**
         * Calls GetOrder.
         * @function getOrder
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IGetOrderRequest} request GetOrderRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.GetOrderCallback} callback Node-style callback called with the error, if any, and GetOrderResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.getOrder = function getOrder(request, callback) {
            return this.rpcCall(getOrder, $root.PlatformCRMWeb.GetOrderRequest, $root.PlatformCRMWeb.GetOrderResponse, request, callback);
        }, "name", { value: "GetOrder" });

        /**
         * Calls GetOrder.
         * @function getOrder
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IGetOrderRequest} request GetOrderRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetOrderResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#getOrders}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef GetOrdersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetOrdersResponse} [response] GetOrdersResponse
         */

        /**
         * Calls GetOrders.
         * @function getOrders
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IGetOrdersRequest} request GetOrdersRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.GetOrdersCallback} callback Node-style callback called with the error, if any, and GetOrdersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.getOrders = function getOrders(request, callback) {
            return this.rpcCall(getOrders, $root.PlatformCRMWeb.GetOrdersRequest, $root.PlatformCRMWeb.GetOrdersResponse, request, callback);
        }, "name", { value: "GetOrders" });

        /**
         * Calls GetOrders.
         * @function getOrders
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IGetOrdersRequest} request GetOrdersRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetOrdersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#saveOrderReport}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef SaveOrderReportCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.SaveOrderReportResponse} [response] SaveOrderReportResponse
         */

        /**
         * Calls SaveOrderReport.
         * @function saveOrderReport
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISaveOrderReportRequest} request SaveOrderReportRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.SaveOrderReportCallback} callback Node-style callback called with the error, if any, and SaveOrderReportResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.saveOrderReport = function saveOrderReport(request, callback) {
            return this.rpcCall(saveOrderReport, $root.PlatformCRMWeb.SaveOrderReportRequest, $root.PlatformCRMWeb.SaveOrderReportResponse, request, callback);
        }, "name", { value: "SaveOrderReport" });

        /**
         * Calls SaveOrderReport.
         * @function saveOrderReport
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISaveOrderReportRequest} request SaveOrderReportRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.SaveOrderReportResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#sendInvoice}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef SendInvoiceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.SendInvoiceResponse} [response] SendInvoiceResponse
         */

        /**
         * Calls SendInvoice.
         * @function sendInvoice
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISendInvoiceRequest} request SendInvoiceRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.SendInvoiceCallback} callback Node-style callback called with the error, if any, and SendInvoiceResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.sendInvoice = function sendInvoice(request, callback) {
            return this.rpcCall(sendInvoice, $root.PlatformCRMWeb.SendInvoiceRequest, $root.PlatformCRMWeb.SendInvoiceResponse, request, callback);
        }, "name", { value: "SendInvoice" });

        /**
         * Calls SendInvoice.
         * @function sendInvoice
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISendInvoiceRequest} request SendInvoiceRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.SendInvoiceResponse>} Promise
         * @variation 2
         */

        return OrderService;
    })();

    PlatformCRMWeb.FIO = (function() {

        /**
         * Properties of a FIO.
         * @memberof PlatformCRMWeb
         * @interface IFIO
         * @property {string|null} [fullName] FIO fullName
         * @property {string|null} [lastName] FIO lastName
         * @property {string|null} [middleName] FIO middleName
         * @property {string|null} [firstName] FIO firstName
         * @property {boolean|null} [isValid] FIO isValid
         * @property {number|null} [gender] FIO gender
         * @property {PlatformCRMWeb.IIDadataFIO|null} [object] FIO object
         */

        /**
         * Constructs a new FIO.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FIO.
         * @implements IFIO
         * @constructor
         * @param {PlatformCRMWeb.IFIO=} [properties] Properties to set
         */
        function FIO(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FIO fullName.
         * @member {string} fullName
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.fullName = "";

        /**
         * FIO lastName.
         * @member {string} lastName
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.lastName = "";

        /**
         * FIO middleName.
         * @member {string} middleName
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.middleName = "";

        /**
         * FIO firstName.
         * @member {string} firstName
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.firstName = "";

        /**
         * FIO isValid.
         * @member {boolean} isValid
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.isValid = false;

        /**
         * FIO gender.
         * @member {number} gender
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.gender = 0;

        /**
         * FIO object.
         * @member {PlatformCRMWeb.IIDadataFIO|null|undefined} object
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.object = null;

        /**
         * Encodes the specified FIO message. Does not implicitly {@link PlatformCRMWeb.FIO.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FIO
         * @static
         * @param {PlatformCRMWeb.IFIO} message FIO message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FIO.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fullName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.middleName);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstName);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isValid);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.gender);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformCRMWeb.IDadataFIO.encode(message.object, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FIO message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FIO
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FIO} FIO
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FIO.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FIO();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fullName = reader.string();
                    break;
                case 2:
                    message.lastName = reader.string();
                    break;
                case 3:
                    message.middleName = reader.string();
                    break;
                case 4:
                    message.firstName = reader.string();
                    break;
                case 5:
                    message.isValid = reader.bool();
                    break;
                case 6:
                    message.gender = reader.int32();
                    break;
                case 7:
                    message.object = $root.PlatformCRMWeb.IDadataFIO.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FIO;
    })();

    PlatformCRMWeb.IDadataFIO = (function() {

        /**
         * Properties of a IDadataFIO.
         * @memberof PlatformCRMWeb
         * @interface IIDadataFIO
         * @property {string|null} [source] IDadataFIO source
         * @property {string|null} [result] IDadataFIO result
         * @property {string|null} [resultGenitive] IDadataFIO resultGenitive
         * @property {string|null} [resultDative] IDadataFIO resultDative
         * @property {string|null} [resultAblative] IDadataFIO resultAblative
         * @property {string|null} [surname] IDadataFIO surname
         * @property {string|null} [name] IDadataFIO name
         * @property {string|null} [patronymic] IDadataFIO patronymic
         * @property {string|null} [gender] IDadataFIO gender
         * @property {number|null} [qc] IDadataFIO qc
         */

        /**
         * Constructs a new IDadataFIO.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a IDadataFIO.
         * @implements IIDadataFIO
         * @constructor
         * @param {PlatformCRMWeb.IIDadataFIO=} [properties] Properties to set
         */
        function IDadataFIO(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IDadataFIO source.
         * @member {string} source
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.source = "";

        /**
         * IDadataFIO result.
         * @member {string} result
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.result = "";

        /**
         * IDadataFIO resultGenitive.
         * @member {string} resultGenitive
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.resultGenitive = "";

        /**
         * IDadataFIO resultDative.
         * @member {string} resultDative
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.resultDative = "";

        /**
         * IDadataFIO resultAblative.
         * @member {string} resultAblative
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.resultAblative = "";

        /**
         * IDadataFIO surname.
         * @member {string} surname
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.surname = "";

        /**
         * IDadataFIO name.
         * @member {string} name
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.name = "";

        /**
         * IDadataFIO patronymic.
         * @member {string} patronymic
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.patronymic = "";

        /**
         * IDadataFIO gender.
         * @member {string} gender
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.gender = "";

        /**
         * IDadataFIO qc.
         * @member {number} qc
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.qc = 0;

        /**
         * Encodes the specified IDadataFIO message. Does not implicitly {@link PlatformCRMWeb.IDadataFIO.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.IDadataFIO
         * @static
         * @param {PlatformCRMWeb.IIDadataFIO} message IDadataFIO message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IDadataFIO.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.source);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.result);
            if (message.resultGenitive != null && Object.hasOwnProperty.call(message, "resultGenitive"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.resultGenitive);
            if (message.resultDative != null && Object.hasOwnProperty.call(message, "resultDative"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.resultDative);
            if (message.resultAblative != null && Object.hasOwnProperty.call(message, "resultAblative"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.resultAblative);
            if (message.surname != null && Object.hasOwnProperty.call(message, "surname"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.surname);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.name);
            if (message.patronymic != null && Object.hasOwnProperty.call(message, "patronymic"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.patronymic);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.gender);
            if (message.qc != null && Object.hasOwnProperty.call(message, "qc"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.qc);
            return writer;
        };

        /**
         * Decodes a IDadataFIO message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.IDadataFIO
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.IDadataFIO} IDadataFIO
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IDadataFIO.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.IDadataFIO();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.source = reader.string();
                    break;
                case 2:
                    message.result = reader.string();
                    break;
                case 3:
                    message.resultGenitive = reader.string();
                    break;
                case 4:
                    message.resultDative = reader.string();
                    break;
                case 5:
                    message.resultAblative = reader.string();
                    break;
                case 6:
                    message.surname = reader.string();
                    break;
                case 7:
                    message.name = reader.string();
                    break;
                case 8:
                    message.patronymic = reader.string();
                    break;
                case 9:
                    message.gender = reader.string();
                    break;
                case 10:
                    message.qc = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return IDadataFIO;
    })();

    PlatformCRMWeb.Person = (function() {

        /**
         * Properties of a Person.
         * @memberof PlatformCRMWeb
         * @interface IPerson
         * @property {string|null} [id] Person id
         * @property {string|null} [email] Person email
         * @property {string|null} [phone] Person phone
         * @property {string|null} [status] Person status
         * @property {string|null} [ssoId] Person ssoId
         * @property {string|null} [commentForCleaner] Person commentForCleaner
         * @property {string|null} [commentForSupport] Person commentForSupport
         * @property {PlatformCRMWeb.IFIO|null} [fio] Person fio
         * @property {string|null} [createdAt] Person createdAt
         * @property {string|null} [updatedAt] Person updatedAt
         */

        /**
         * Constructs a new Person.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Person.
         * @implements IPerson
         * @constructor
         * @param {PlatformCRMWeb.IPerson=} [properties] Properties to set
         */
        function Person(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Person id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.id = "";

        /**
         * Person email.
         * @member {string} email
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.email = "";

        /**
         * Person phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.phone = "";

        /**
         * Person status.
         * @member {string} status
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.status = "";

        /**
         * Person ssoId.
         * @member {string} ssoId
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.ssoId = "";

        /**
         * Person commentForCleaner.
         * @member {string} commentForCleaner
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.commentForCleaner = "";

        /**
         * Person commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.commentForSupport = "";

        /**
         * Person fio.
         * @member {PlatformCRMWeb.IFIO|null|undefined} fio
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.fio = null;

        /**
         * Person createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.createdAt = "";

        /**
         * Person updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.updatedAt = "";

        /**
         * Encodes the specified Person message. Does not implicitly {@link PlatformCRMWeb.Person.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Person
         * @static
         * @param {PlatformCRMWeb.IPerson} message Person message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Person.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.status);
            if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.ssoId);
            if (message.commentForCleaner != null && Object.hasOwnProperty.call(message, "commentForCleaner"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.commentForCleaner);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.commentForSupport);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformCRMWeb.FIO.encode(message.fio, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.updatedAt);
            return writer;
        };

        /**
         * Decodes a Person message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Person
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Person} Person
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Person.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Person();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.email = reader.string();
                    break;
                case 3:
                    message.phone = reader.string();
                    break;
                case 4:
                    message.status = reader.string();
                    break;
                case 5:
                    message.ssoId = reader.string();
                    break;
                case 6:
                    message.commentForCleaner = reader.string();
                    break;
                case 7:
                    message.commentForSupport = reader.string();
                    break;
                case 8:
                    message.fio = $root.PlatformCRMWeb.FIO.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Person;
    })();

    PlatformCRMWeb.CalculateOption = (function() {

        /**
         * Properties of a CalculateOption.
         * @memberof PlatformCRMWeb
         * @interface ICalculateOption
         * @property {string|null} [slug] CalculateOption slug
         * @property {number|null} [value] CalculateOption value
         * @property {number|null} [totalPrice] CalculateOption totalPrice
         * @property {number|null} [totalDuration] CalculateOption totalDuration
         * @property {string|null} [id] CalculateOption id
         * @property {string|null} [createdAt] CalculateOption createdAt
         * @property {string|null} [updatedAt] CalculateOption updatedAt
         * @property {boolean|null} [isFree] CalculateOption isFree
         * @property {number|null} [factor] CalculateOption factor
         */

        /**
         * Constructs a new CalculateOption.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CalculateOption.
         * @implements ICalculateOption
         * @constructor
         * @param {PlatformCRMWeb.ICalculateOption=} [properties] Properties to set
         */
        function CalculateOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalculateOption slug.
         * @member {string} slug
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.slug = "";

        /**
         * CalculateOption value.
         * @member {number} value
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.value = 0;

        /**
         * CalculateOption totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.totalPrice = 0;

        /**
         * CalculateOption totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.totalDuration = 0;

        /**
         * CalculateOption id.
         * @member {string} id
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.id = "";

        /**
         * CalculateOption createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.createdAt = "";

        /**
         * CalculateOption updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.updatedAt = "";

        /**
         * CalculateOption isFree.
         * @member {boolean} isFree
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.isFree = false;

        /**
         * CalculateOption factor.
         * @member {number} factor
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.factor = 0;

        /**
         * Encodes the specified CalculateOption message. Does not implicitly {@link PlatformCRMWeb.CalculateOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CalculateOption
         * @static
         * @param {PlatformCRMWeb.ICalculateOption} message CalculateOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalculateOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.slug);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalDuration);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.updatedAt);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isFree);
            if (message.factor != null && Object.hasOwnProperty.call(message, "factor"))
                writer.uint32(/* id 9, wireType 5 =*/77).float(message.factor);
            return writer;
        };

        /**
         * Decodes a CalculateOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CalculateOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CalculateOption} CalculateOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalculateOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CalculateOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slug = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                case 3:
                    message.totalPrice = reader.int32();
                    break;
                case 4:
                    message.totalDuration = reader.int32();
                    break;
                case 5:
                    message.id = reader.string();
                    break;
                case 6:
                    message.createdAt = reader.string();
                    break;
                case 7:
                    message.updatedAt = reader.string();
                    break;
                case 8:
                    message.isFree = reader.bool();
                    break;
                case 9:
                    message.factor = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CalculateOption;
    })();

    PlatformCRMWeb.CalculateProduct = (function() {

        /**
         * Properties of a CalculateProduct.
         * @memberof PlatformCRMWeb
         * @interface ICalculateProduct
         * @property {string|null} [productSlug] CalculateProduct productSlug
         * @property {Array.<PlatformCRMWeb.ICalculateOption>|null} [options] CalculateProduct options
         * @property {number|null} [totalPrice] CalculateProduct totalPrice
         * @property {number|null} [totalDuration] CalculateProduct totalDuration
         * @property {number|null} [subTotalPrice] CalculateProduct subTotalPrice
         * @property {number|null} [baseCost] CalculateProduct baseCost
         * @property {number|null} [baseDuration] CalculateProduct baseDuration
         * @property {string|null} [id] CalculateProduct id
         * @property {string|null} [createdAt] CalculateProduct createdAt
         * @property {string|null} [updatedAt] CalculateProduct updatedAt
         */

        /**
         * Constructs a new CalculateProduct.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CalculateProduct.
         * @implements ICalculateProduct
         * @constructor
         * @param {PlatformCRMWeb.ICalculateProduct=} [properties] Properties to set
         */
        function CalculateProduct(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalculateProduct productSlug.
         * @member {string} productSlug
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.productSlug = "";

        /**
         * CalculateProduct options.
         * @member {Array.<PlatformCRMWeb.ICalculateOption>} options
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.options = $util.emptyArray;

        /**
         * CalculateProduct totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.totalPrice = 0;

        /**
         * CalculateProduct totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.totalDuration = 0;

        /**
         * CalculateProduct subTotalPrice.
         * @member {number} subTotalPrice
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.subTotalPrice = 0;

        /**
         * CalculateProduct baseCost.
         * @member {number} baseCost
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.baseCost = 0;

        /**
         * CalculateProduct baseDuration.
         * @member {number} baseDuration
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.baseDuration = 0;

        /**
         * CalculateProduct id.
         * @member {string} id
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.id = "";

        /**
         * CalculateProduct createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.createdAt = "";

        /**
         * CalculateProduct updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.updatedAt = "";

        /**
         * Encodes the specified CalculateProduct message. Does not implicitly {@link PlatformCRMWeb.CalculateProduct.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CalculateProduct
         * @static
         * @param {PlatformCRMWeb.ICalculateProduct} message CalculateProduct message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalculateProduct.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productSlug);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformCRMWeb.CalculateOption.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalDuration);
            if (message.subTotalPrice != null && Object.hasOwnProperty.call(message, "subTotalPrice"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.subTotalPrice);
            if (message.baseCost != null && Object.hasOwnProperty.call(message, "baseCost"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.baseCost);
            if (message.baseDuration != null && Object.hasOwnProperty.call(message, "baseDuration"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.baseDuration);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.updatedAt);
            return writer;
        };

        /**
         * Decodes a CalculateProduct message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CalculateProduct
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CalculateProduct} CalculateProduct
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalculateProduct.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CalculateProduct();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productSlug = reader.string();
                    break;
                case 2:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformCRMWeb.CalculateOption.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.totalPrice = reader.int32();
                    break;
                case 4:
                    message.totalDuration = reader.int32();
                    break;
                case 5:
                    message.subTotalPrice = reader.int32();
                    break;
                case 6:
                    message.baseCost = reader.int32();
                    break;
                case 7:
                    message.baseDuration = reader.int32();
                    break;
                case 8:
                    message.id = reader.string();
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CalculateProduct;
    })();

    PlatformCRMWeb.CalculatePrice = (function() {

        /**
         * Properties of a CalculatePrice.
         * @memberof PlatformCRMWeb
         * @interface ICalculatePrice
         * @property {Array.<PlatformCRMWeb.ICalculateProduct>|null} [products] CalculatePrice products
         * @property {number|null} [totalPrice] CalculatePrice totalPrice
         * @property {number|null} [totalDuration] CalculatePrice totalDuration
         * @property {number|null} [subTotalPrice] CalculatePrice subTotalPrice
         * @property {string|null} [relevantUntil] CalculatePrice relevantUntil
         * @property {string|null} [priceListTitle] CalculatePrice priceListTitle
         * @property {string|null} [priceListId] CalculatePrice priceListId
         * @property {string|null} [id] CalculatePrice id
         * @property {string|null} [createdAt] CalculatePrice createdAt
         * @property {string|null} [updatedAt] CalculatePrice updatedAt
         */

        /**
         * Constructs a new CalculatePrice.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CalculatePrice.
         * @implements ICalculatePrice
         * @constructor
         * @param {PlatformCRMWeb.ICalculatePrice=} [properties] Properties to set
         */
        function CalculatePrice(properties) {
            this.products = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalculatePrice products.
         * @member {Array.<PlatformCRMWeb.ICalculateProduct>} products
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.products = $util.emptyArray;

        /**
         * CalculatePrice totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.totalPrice = 0;

        /**
         * CalculatePrice totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.totalDuration = 0;

        /**
         * CalculatePrice subTotalPrice.
         * @member {number} subTotalPrice
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.subTotalPrice = 0;

        /**
         * CalculatePrice relevantUntil.
         * @member {string} relevantUntil
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.relevantUntil = "";

        /**
         * CalculatePrice priceListTitle.
         * @member {string} priceListTitle
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.priceListTitle = "";

        /**
         * CalculatePrice priceListId.
         * @member {string} priceListId
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.priceListId = "";

        /**
         * CalculatePrice id.
         * @member {string} id
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.id = "";

        /**
         * CalculatePrice createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.createdAt = "";

        /**
         * CalculatePrice updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.updatedAt = "";

        /**
         * Encodes the specified CalculatePrice message. Does not implicitly {@link PlatformCRMWeb.CalculatePrice.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CalculatePrice
         * @static
         * @param {PlatformCRMWeb.ICalculatePrice} message CalculatePrice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalculatePrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.products != null && message.products.length)
                for (var i = 0; i < message.products.length; ++i)
                    $root.PlatformCRMWeb.CalculateProduct.encode(message.products[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalDuration);
            if (message.subTotalPrice != null && Object.hasOwnProperty.call(message, "subTotalPrice"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.subTotalPrice);
            if (message.relevantUntil != null && Object.hasOwnProperty.call(message, "relevantUntil"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.relevantUntil);
            if (message.priceListTitle != null && Object.hasOwnProperty.call(message, "priceListTitle"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.priceListTitle);
            if (message.priceListId != null && Object.hasOwnProperty.call(message, "priceListId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.priceListId);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.updatedAt);
            return writer;
        };

        /**
         * Decodes a CalculatePrice message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CalculatePrice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CalculatePrice} CalculatePrice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalculatePrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CalculatePrice();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.products && message.products.length))
                        message.products = [];
                    message.products.push($root.PlatformCRMWeb.CalculateProduct.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.totalPrice = reader.int32();
                    break;
                case 3:
                    message.totalDuration = reader.int32();
                    break;
                case 4:
                    message.subTotalPrice = reader.int32();
                    break;
                case 5:
                    message.relevantUntil = reader.string();
                    break;
                case 6:
                    message.priceListTitle = reader.string();
                    break;
                case 7:
                    message.priceListId = reader.string();
                    break;
                case 8:
                    message.id = reader.string();
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CalculatePrice;
    })();

    PlatformCRMWeb.ShortOrder = (function() {

        /**
         * Properties of a ShortOrder.
         * @memberof PlatformCRMWeb
         * @interface IShortOrder
         * @property {string|null} [id] ShortOrder id
         * @property {string|null} [startDate] ShortOrder startDate
         * @property {sso.IAddress|null} [address] ShortOrder address
         * @property {number|null} [orderNumber] ShortOrder orderNumber
         */

        /**
         * Constructs a new ShortOrder.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ShortOrder.
         * @implements IShortOrder
         * @constructor
         * @param {PlatformCRMWeb.IShortOrder=} [properties] Properties to set
         */
        function ShortOrder(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ShortOrder id.
         * @member {string} id
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.id = "";

        /**
         * ShortOrder startDate.
         * @member {string} startDate
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.startDate = "";

        /**
         * ShortOrder address.
         * @member {sso.IAddress|null|undefined} address
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.address = null;

        /**
         * ShortOrder orderNumber.
         * @member {number} orderNumber
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.orderNumber = 0;

        /**
         * Encodes the specified ShortOrder message. Does not implicitly {@link PlatformCRMWeb.ShortOrder.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ShortOrder
         * @static
         * @param {PlatformCRMWeb.IShortOrder} message ShortOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ShortOrder.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.startDate);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.sso.Address.encode(message.address, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.orderNumber != null && Object.hasOwnProperty.call(message, "orderNumber"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.orderNumber);
            return writer;
        };

        /**
         * Decodes a ShortOrder message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ShortOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ShortOrder} ShortOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ShortOrder.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ShortOrder();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.startDate = reader.string();
                    break;
                case 3:
                    message.address = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.orderNumber = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ShortOrder;
    })();

    PlatformCRMWeb.Order = (function() {

        /**
         * Properties of an Order.
         * @memberof PlatformCRMWeb
         * @interface IOrder
         * @property {string|null} [id] Order id
         * @property {number|null} [orderNumber] Order orderNumber
         * @property {string|null} [status] Order status
         * @property {string|null} [personId] Order personId
         * @property {string|null} [prospectId] Order prospectId
         * @property {string|null} [startDate] Order startDate
         * @property {string|null} [contractId] Order contractId
         * @property {sso.IAddress|null} [address] Order address
         * @property {sso.IAddress|null} [keysDelivery] Order keysDelivery
         * @property {sso.IAddress|null} [keysPickup] Order keysPickup
         * @property {number|null} [regionId] Order regionId
         * @property {PlatformCRMWeb.ICalculatePrice|null} [price] Order price
         * @property {string|null} [promocode] Order promocode
         * @property {string|null} [serviceType] Order serviceType
         * @property {string|null} [paymentType] Order paymentType
         * @property {string|null} [qleanAddress] Order qleanAddress
         * @property {string|null} [qleanOrderId] Order qleanOrderId
         * @property {string|null} [qleanPromocodeId] Order qleanPromocodeId
         * @property {string|null} [qleanCleanerId] Order qleanCleanerId
         * @property {string|null} [commentForExecutor] Order commentForExecutor
         * @property {string|null} [commentForSupport] Order commentForSupport
         * @property {number|null} [score] Order score
         * @property {string|null} [cancelReason] Order cancelReason
         * @property {number|null} [totalPrice] Order totalPrice
         * @property {number|null} [totalDuration] Order totalDuration
         * @property {boolean|null} [isOvertime] Order isOvertime
         * @property {PlatformCRMWeb.IPerson|null} [person] Order person
         * @property {number|null} [discount] Order discount
         */

        /**
         * Constructs a new Order.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an Order.
         * @implements IOrder
         * @constructor
         * @param {PlatformCRMWeb.IOrder=} [properties] Properties to set
         */
        function Order(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Order id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.id = "";

        /**
         * Order orderNumber.
         * @member {number} orderNumber
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.orderNumber = 0;

        /**
         * Order status.
         * @member {string} status
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.status = "";

        /**
         * Order personId.
         * @member {string} personId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.personId = "";

        /**
         * Order prospectId.
         * @member {string} prospectId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.prospectId = "";

        /**
         * Order startDate.
         * @member {string} startDate
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.startDate = "";

        /**
         * Order contractId.
         * @member {string} contractId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.contractId = "";

        /**
         * Order address.
         * @member {sso.IAddress|null|undefined} address
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.address = null;

        /**
         * Order keysDelivery.
         * @member {sso.IAddress|null|undefined} keysDelivery
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.keysDelivery = null;

        /**
         * Order keysPickup.
         * @member {sso.IAddress|null|undefined} keysPickup
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.keysPickup = null;

        /**
         * Order regionId.
         * @member {number} regionId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.regionId = 0;

        /**
         * Order price.
         * @member {PlatformCRMWeb.ICalculatePrice|null|undefined} price
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.price = null;

        /**
         * Order promocode.
         * @member {string} promocode
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.promocode = "";

        /**
         * Order serviceType.
         * @member {string} serviceType
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.serviceType = "";

        /**
         * Order paymentType.
         * @member {string} paymentType
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.paymentType = "";

        /**
         * Order qleanAddress.
         * @member {string} qleanAddress
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.qleanAddress = "";

        /**
         * Order qleanOrderId.
         * @member {string} qleanOrderId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.qleanOrderId = "";

        /**
         * Order qleanPromocodeId.
         * @member {string} qleanPromocodeId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.qleanPromocodeId = "";

        /**
         * Order qleanCleanerId.
         * @member {string} qleanCleanerId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.qleanCleanerId = "";

        /**
         * Order commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.commentForExecutor = "";

        /**
         * Order commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.commentForSupport = "";

        /**
         * Order score.
         * @member {number} score
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.score = 0;

        /**
         * Order cancelReason.
         * @member {string} cancelReason
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.cancelReason = "";

        /**
         * Order totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.totalPrice = 0;

        /**
         * Order totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.totalDuration = 0;

        /**
         * Order isOvertime.
         * @member {boolean} isOvertime
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.isOvertime = false;

        /**
         * Order person.
         * @member {PlatformCRMWeb.IPerson|null|undefined} person
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.person = null;

        /**
         * Order discount.
         * @member {number} discount
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.discount = 0;

        /**
         * Encodes the specified Order message. Does not implicitly {@link PlatformCRMWeb.Order.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Order
         * @static
         * @param {PlatformCRMWeb.IOrder} message Order message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Order.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.orderNumber != null && Object.hasOwnProperty.call(message, "orderNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderNumber);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
            if (message.personId != null && Object.hasOwnProperty.call(message, "personId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.personId);
            if (message.prospectId != null && Object.hasOwnProperty.call(message, "prospectId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.prospectId);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.startDate);
            if (message.contractId != null && Object.hasOwnProperty.call(message, "contractId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.contractId);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.sso.Address.encode(message.address, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.keysDelivery != null && Object.hasOwnProperty.call(message, "keysDelivery"))
                $root.sso.Address.encode(message.keysDelivery, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.keysPickup != null && Object.hasOwnProperty.call(message, "keysPickup"))
                $root.sso.Address.encode(message.keysPickup, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.regionId);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                $root.PlatformCRMWeb.CalculatePrice.encode(message.price, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.promocode);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.serviceType);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.paymentType);
            if (message.qleanAddress != null && Object.hasOwnProperty.call(message, "qleanAddress"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.qleanAddress);
            if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.qleanOrderId);
            if (message.qleanPromocodeId != null && Object.hasOwnProperty.call(message, "qleanPromocodeId"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.qleanPromocodeId);
            if (message.qleanCleanerId != null && Object.hasOwnProperty.call(message, "qleanCleanerId"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.qleanCleanerId);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.commentForSupport);
            if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                writer.uint32(/* id 22, wireType 0 =*/176).int32(message.score);
            if (message.cancelReason != null && Object.hasOwnProperty.call(message, "cancelReason"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.cancelReason);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 24, wireType 0 =*/192).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 25, wireType 0 =*/200).int32(message.totalDuration);
            if (message.isOvertime != null && Object.hasOwnProperty.call(message, "isOvertime"))
                writer.uint32(/* id 26, wireType 0 =*/208).bool(message.isOvertime);
            if (message.person != null && Object.hasOwnProperty.call(message, "person"))
                $root.PlatformCRMWeb.Person.encode(message.person, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 28, wireType 0 =*/224).int32(message.discount);
            return writer;
        };

        /**
         * Decodes an Order message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Order
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Order} Order
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Order.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Order();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.orderNumber = reader.int32();
                    break;
                case 3:
                    message.status = reader.string();
                    break;
                case 4:
                    message.personId = reader.string();
                    break;
                case 5:
                    message.prospectId = reader.string();
                    break;
                case 6:
                    message.startDate = reader.string();
                    break;
                case 7:
                    message.contractId = reader.string();
                    break;
                case 8:
                    message.address = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.keysDelivery = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.keysPickup = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.regionId = reader.int32();
                    break;
                case 12:
                    message.price = $root.PlatformCRMWeb.CalculatePrice.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.promocode = reader.string();
                    break;
                case 14:
                    message.serviceType = reader.string();
                    break;
                case 15:
                    message.paymentType = reader.string();
                    break;
                case 16:
                    message.qleanAddress = reader.string();
                    break;
                case 17:
                    message.qleanOrderId = reader.string();
                    break;
                case 18:
                    message.qleanPromocodeId = reader.string();
                    break;
                case 19:
                    message.qleanCleanerId = reader.string();
                    break;
                case 20:
                    message.commentForExecutor = reader.string();
                    break;
                case 21:
                    message.commentForSupport = reader.string();
                    break;
                case 22:
                    message.score = reader.int32();
                    break;
                case 23:
                    message.cancelReason = reader.string();
                    break;
                case 24:
                    message.totalPrice = reader.int32();
                    break;
                case 25:
                    message.totalDuration = reader.int32();
                    break;
                case 26:
                    message.isOvertime = reader.bool();
                    break;
                case 27:
                    message.person = $root.PlatformCRMWeb.Person.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.discount = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Order;
    })();

    PlatformCRMWeb.OrderReportItem = (function() {

        /**
         * Properties of an OrderReportItem.
         * @memberof PlatformCRMWeb
         * @interface IOrderReportItem
         * @property {string|null} [executorId] OrderReportItem executorId
         * @property {number|null} [duration] OrderReportItem duration
         * @property {boolean|null} [keyDelivery] OrderReportItem keyDelivery
         * @property {boolean|null} [keyPickup] OrderReportItem keyPickup
         * @property {number|null} [bonus] OrderReportItem bonus
         * @property {number|null} [penalty] OrderReportItem penalty
         */

        /**
         * Constructs a new OrderReportItem.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OrderReportItem.
         * @implements IOrderReportItem
         * @constructor
         * @param {PlatformCRMWeb.IOrderReportItem=} [properties] Properties to set
         */
        function OrderReportItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderReportItem executorId.
         * @member {string} executorId
         * @memberof PlatformCRMWeb.OrderReportItem
         * @instance
         */
        OrderReportItem.prototype.executorId = "";

        /**
         * OrderReportItem duration.
         * @member {number} duration
         * @memberof PlatformCRMWeb.OrderReportItem
         * @instance
         */
        OrderReportItem.prototype.duration = 0;

        /**
         * OrderReportItem keyDelivery.
         * @member {boolean} keyDelivery
         * @memberof PlatformCRMWeb.OrderReportItem
         * @instance
         */
        OrderReportItem.prototype.keyDelivery = false;

        /**
         * OrderReportItem keyPickup.
         * @member {boolean} keyPickup
         * @memberof PlatformCRMWeb.OrderReportItem
         * @instance
         */
        OrderReportItem.prototype.keyPickup = false;

        /**
         * OrderReportItem bonus.
         * @member {number} bonus
         * @memberof PlatformCRMWeb.OrderReportItem
         * @instance
         */
        OrderReportItem.prototype.bonus = 0;

        /**
         * OrderReportItem penalty.
         * @member {number} penalty
         * @memberof PlatformCRMWeb.OrderReportItem
         * @instance
         */
        OrderReportItem.prototype.penalty = 0;

        /**
         * Encodes the specified OrderReportItem message. Does not implicitly {@link PlatformCRMWeb.OrderReportItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.OrderReportItem
         * @static
         * @param {PlatformCRMWeb.IOrderReportItem} message OrderReportItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderReportItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.executorId != null && Object.hasOwnProperty.call(message, "executorId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.executorId);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.duration);
            if (message.keyDelivery != null && Object.hasOwnProperty.call(message, "keyDelivery"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.keyDelivery);
            if (message.keyPickup != null && Object.hasOwnProperty.call(message, "keyPickup"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.keyPickup);
            if (message.bonus != null && Object.hasOwnProperty.call(message, "bonus"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.bonus);
            if (message.penalty != null && Object.hasOwnProperty.call(message, "penalty"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.penalty);
            return writer;
        };

        /**
         * Decodes an OrderReportItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.OrderReportItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.OrderReportItem} OrderReportItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderReportItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.OrderReportItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.executorId = reader.string();
                    break;
                case 2:
                    message.duration = reader.float();
                    break;
                case 3:
                    message.keyDelivery = reader.bool();
                    break;
                case 4:
                    message.keyPickup = reader.bool();
                    break;
                case 5:
                    message.bonus = reader.int32();
                    break;
                case 6:
                    message.penalty = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderReportItem;
    })();

    PlatformCRMWeb.ExecutorLink = (function() {

        /**
         * Properties of an ExecutorLink.
         * @memberof PlatformCRMWeb
         * @interface IExecutorLink
         * @property {string|null} [executorId] ExecutorLink executorId
         * @property {number|null} [duration] ExecutorLink duration
         * @property {boolean|null} [keyDelivery] ExecutorLink keyDelivery
         * @property {boolean|null} [keyPickup] ExecutorLink keyPickup
         * @property {number|null} [bonus] ExecutorLink bonus
         * @property {number|null} [penalty] ExecutorLink penalty
         * @property {number|null} [rate] ExecutorLink rate
         * @property {string|null} [name] ExecutorLink name
         * @property {string|null} [phone] ExecutorLink phone
         * @property {number|null} [qleanId] ExecutorLink qleanId
         * @property {number|null} [role] ExecutorLink role
         * @property {string|null} [ssoId] ExecutorLink ssoId
         */

        /**
         * Constructs a new ExecutorLink.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an ExecutorLink.
         * @implements IExecutorLink
         * @constructor
         * @param {PlatformCRMWeb.IExecutorLink=} [properties] Properties to set
         */
        function ExecutorLink(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExecutorLink executorId.
         * @member {string} executorId
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.executorId = "";

        /**
         * ExecutorLink duration.
         * @member {number} duration
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.duration = 0;

        /**
         * ExecutorLink keyDelivery.
         * @member {boolean} keyDelivery
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.keyDelivery = false;

        /**
         * ExecutorLink keyPickup.
         * @member {boolean} keyPickup
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.keyPickup = false;

        /**
         * ExecutorLink bonus.
         * @member {number} bonus
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.bonus = 0;

        /**
         * ExecutorLink penalty.
         * @member {number} penalty
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.penalty = 0;

        /**
         * ExecutorLink rate.
         * @member {number} rate
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.rate = 0;

        /**
         * ExecutorLink name.
         * @member {string} name
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.name = "";

        /**
         * ExecutorLink phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.phone = "";

        /**
         * ExecutorLink qleanId.
         * @member {number} qleanId
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.qleanId = 0;

        /**
         * ExecutorLink role.
         * @member {number} role
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.role = 0;

        /**
         * ExecutorLink ssoId.
         * @member {string} ssoId
         * @memberof PlatformCRMWeb.ExecutorLink
         * @instance
         */
        ExecutorLink.prototype.ssoId = "";

        /**
         * Encodes the specified ExecutorLink message. Does not implicitly {@link PlatformCRMWeb.ExecutorLink.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ExecutorLink
         * @static
         * @param {PlatformCRMWeb.IExecutorLink} message ExecutorLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExecutorLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.executorId != null && Object.hasOwnProperty.call(message, "executorId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.executorId);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.duration);
            if (message.keyDelivery != null && Object.hasOwnProperty.call(message, "keyDelivery"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.keyDelivery);
            if (message.keyPickup != null && Object.hasOwnProperty.call(message, "keyPickup"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.keyPickup);
            if (message.bonus != null && Object.hasOwnProperty.call(message, "bonus"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.bonus);
            if (message.penalty != null && Object.hasOwnProperty.call(message, "penalty"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.penalty);
            if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.rate);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.name);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.phone);
            if (message.qleanId != null && Object.hasOwnProperty.call(message, "qleanId"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.qleanId);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.role);
            if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.ssoId);
            return writer;
        };

        /**
         * Decodes an ExecutorLink message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ExecutorLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ExecutorLink} ExecutorLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExecutorLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ExecutorLink();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.executorId = reader.string();
                    break;
                case 2:
                    message.duration = reader.float();
                    break;
                case 3:
                    message.keyDelivery = reader.bool();
                    break;
                case 4:
                    message.keyPickup = reader.bool();
                    break;
                case 5:
                    message.bonus = reader.int32();
                    break;
                case 6:
                    message.penalty = reader.int32();
                    break;
                case 7:
                    message.rate = reader.int32();
                    break;
                case 8:
                    message.name = reader.string();
                    break;
                case 9:
                    message.phone = reader.string();
                    break;
                case 10:
                    message.qleanId = reader.int32();
                    break;
                case 11:
                    message.role = reader.int32();
                    break;
                case 12:
                    message.ssoId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ExecutorLink;
    })();

    PlatformCRMWeb.EmptyObject = (function() {

        /**
         * Properties of an EmptyObject.
         * @memberof PlatformCRMWeb
         * @interface IEmptyObject
         */

        /**
         * Constructs a new EmptyObject.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an EmptyObject.
         * @implements IEmptyObject
         * @constructor
         * @param {PlatformCRMWeb.IEmptyObject=} [properties] Properties to set
         */
        function EmptyObject(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified EmptyObject message. Does not implicitly {@link PlatformCRMWeb.EmptyObject.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.EmptyObject
         * @static
         * @param {PlatformCRMWeb.IEmptyObject} message EmptyObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmptyObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an EmptyObject message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.EmptyObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.EmptyObject} EmptyObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmptyObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.EmptyObject();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return EmptyObject;
    })();

    PlatformCRMWeb.FindPriceElementRequest = (function() {

        /**
         * Properties of a FindPriceElementRequest.
         * @memberof PlatformCRMWeb
         * @interface IFindPriceElementRequest
         * @property {string|null} [productSlug] FindPriceElementRequest productSlug
         * @property {number|null} [regionId] FindPriceElementRequest regionId
         */

        /**
         * Constructs a new FindPriceElementRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FindPriceElementRequest.
         * @implements IFindPriceElementRequest
         * @constructor
         * @param {PlatformCRMWeb.IFindPriceElementRequest=} [properties] Properties to set
         */
        function FindPriceElementRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindPriceElementRequest productSlug.
         * @member {string} productSlug
         * @memberof PlatformCRMWeb.FindPriceElementRequest
         * @instance
         */
        FindPriceElementRequest.prototype.productSlug = "";

        /**
         * FindPriceElementRequest regionId.
         * @member {number} regionId
         * @memberof PlatformCRMWeb.FindPriceElementRequest
         * @instance
         */
        FindPriceElementRequest.prototype.regionId = 0;

        /**
         * Encodes the specified FindPriceElementRequest message. Does not implicitly {@link PlatformCRMWeb.FindPriceElementRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FindPriceElementRequest
         * @static
         * @param {PlatformCRMWeb.IFindPriceElementRequest} message FindPriceElementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindPriceElementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productSlug);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.regionId);
            return writer;
        };

        /**
         * Decodes a FindPriceElementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FindPriceElementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FindPriceElementRequest} FindPriceElementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindPriceElementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FindPriceElementRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productSlug = reader.string();
                    break;
                case 2:
                    message.regionId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindPriceElementRequest;
    })();

    PlatformCRMWeb.FindPriceElementResponse = (function() {

        /**
         * Properties of a FindPriceElementResponse.
         * @memberof PlatformCRMWeb
         * @interface IFindPriceElementResponse
         * @property {PlatformCRMWeb.IPriceElement|null} [priceElement] FindPriceElementResponse priceElement
         */

        /**
         * Constructs a new FindPriceElementResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FindPriceElementResponse.
         * @implements IFindPriceElementResponse
         * @constructor
         * @param {PlatformCRMWeb.IFindPriceElementResponse=} [properties] Properties to set
         */
        function FindPriceElementResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindPriceElementResponse priceElement.
         * @member {PlatformCRMWeb.IPriceElement|null|undefined} priceElement
         * @memberof PlatformCRMWeb.FindPriceElementResponse
         * @instance
         */
        FindPriceElementResponse.prototype.priceElement = null;

        /**
         * Encodes the specified FindPriceElementResponse message. Does not implicitly {@link PlatformCRMWeb.FindPriceElementResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FindPriceElementResponse
         * @static
         * @param {PlatformCRMWeb.IFindPriceElementResponse} message FindPriceElementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindPriceElementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.priceElement != null && Object.hasOwnProperty.call(message, "priceElement"))
                $root.PlatformCRMWeb.PriceElement.encode(message.priceElement, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FindPriceElementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FindPriceElementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FindPriceElementResponse} FindPriceElementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindPriceElementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FindPriceElementResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.priceElement = $root.PlatformCRMWeb.PriceElement.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindPriceElementResponse;
    })();

    PlatformCRMWeb.MetaService = (function() {

        /**
         * Constructs a new MetaService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a MetaService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MetaService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MetaService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MetaService;

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#findPriceElement}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef FindPriceElementCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.FindPriceElementResponse} [response] FindPriceElementResponse
         */

        /**
         * Calls FindPriceElement.
         * @function findPriceElement
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IFindPriceElementRequest} request FindPriceElementRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.FindPriceElementCallback} callback Node-style callback called with the error, if any, and FindPriceElementResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.findPriceElement = function findPriceElement(request, callback) {
            return this.rpcCall(findPriceElement, $root.PlatformCRMWeb.FindPriceElementRequest, $root.PlatformCRMWeb.FindPriceElementResponse, request, callback);
        }, "name", { value: "FindPriceElement" });

        /**
         * Calls FindPriceElement.
         * @function findPriceElement
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IFindPriceElementRequest} request FindPriceElementRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.FindPriceElementResponse>} Promise
         * @variation 2
         */

        return MetaService;
    })();

    /**
     * MatrixUnit enum.
     * @name PlatformCRMWeb.MatrixUnit
     * @enum {number}
     * @property {number} UNDEF=0 UNDEF value
     * @property {number} UNIT=1 UNIT value
     * @property {number} SERVICE=2 SERVICE value
     */
    PlatformCRMWeb.MatrixUnit = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEF"] = 0;
        values[valuesById[1] = "UNIT"] = 1;
        values[valuesById[2] = "SERVICE"] = 2;
        return values;
    })();

    /**
     * PriceElementOptionType enum.
     * @name PlatformCRMWeb.PriceElementOptionType
     * @enum {number}
     * @property {number} UNDEF=0 UNDEF value
     * @property {number} BASE=1 BASE value
     * @property {number} OPTION=2 OPTION value
     * @property {number} CROSS_SALE=3 CROSS_SALE value
     */
    PlatformCRMWeb.PriceElementOptionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEF"] = 0;
        values[valuesById[1] = "BASE"] = 1;
        values[valuesById[2] = "OPTION"] = 2;
        values[valuesById[3] = "CROSS_SALE"] = 3;
        return values;
    })();

    PlatformCRMWeb.PriceElementMatrixItem = (function() {

        /**
         * Properties of a PriceElementMatrixItem.
         * @memberof PlatformCRMWeb
         * @interface IPriceElementMatrixItem
         * @property {string|null} [range] PriceElementMatrixItem range
         * @property {number|null} [value] PriceElementMatrixItem value
         */

        /**
         * Constructs a new PriceElementMatrixItem.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PriceElementMatrixItem.
         * @implements IPriceElementMatrixItem
         * @constructor
         * @param {PlatformCRMWeb.IPriceElementMatrixItem=} [properties] Properties to set
         */
        function PriceElementMatrixItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElementMatrixItem range.
         * @member {string} range
         * @memberof PlatformCRMWeb.PriceElementMatrixItem
         * @instance
         */
        PriceElementMatrixItem.prototype.range = "";

        /**
         * PriceElementMatrixItem value.
         * @member {number} value
         * @memberof PlatformCRMWeb.PriceElementMatrixItem
         * @instance
         */
        PriceElementMatrixItem.prototype.value = 0;

        /**
         * Encodes the specified PriceElementMatrixItem message. Does not implicitly {@link PlatformCRMWeb.PriceElementMatrixItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PriceElementMatrixItem
         * @static
         * @param {PlatformCRMWeb.IPriceElementMatrixItem} message PriceElementMatrixItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElementMatrixItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.range);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            return writer;
        };

        /**
         * Decodes a PriceElementMatrixItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PriceElementMatrixItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PriceElementMatrixItem} PriceElementMatrixItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElementMatrixItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PriceElementMatrixItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.range = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElementMatrixItem;
    })();

    PlatformCRMWeb.PriceElementMatrix = (function() {

        /**
         * Properties of a PriceElementMatrix.
         * @memberof PlatformCRMWeb
         * @interface IPriceElementMatrix
         * @property {PlatformCRMWeb.MatrixUnit|null} [per] PriceElementMatrix per
         * @property {Array.<PlatformCRMWeb.IPriceElementMatrixItem>|null} [items] PriceElementMatrix items
         */

        /**
         * Constructs a new PriceElementMatrix.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PriceElementMatrix.
         * @implements IPriceElementMatrix
         * @constructor
         * @param {PlatformCRMWeb.IPriceElementMatrix=} [properties] Properties to set
         */
        function PriceElementMatrix(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElementMatrix per.
         * @member {PlatformCRMWeb.MatrixUnit} per
         * @memberof PlatformCRMWeb.PriceElementMatrix
         * @instance
         */
        PriceElementMatrix.prototype.per = 0;

        /**
         * PriceElementMatrix items.
         * @member {Array.<PlatformCRMWeb.IPriceElementMatrixItem>} items
         * @memberof PlatformCRMWeb.PriceElementMatrix
         * @instance
         */
        PriceElementMatrix.prototype.items = $util.emptyArray;

        /**
         * Encodes the specified PriceElementMatrix message. Does not implicitly {@link PlatformCRMWeb.PriceElementMatrix.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PriceElementMatrix
         * @static
         * @param {PlatformCRMWeb.IPriceElementMatrix} message PriceElementMatrix message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElementMatrix.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.per != null && Object.hasOwnProperty.call(message, "per"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.per);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PlatformCRMWeb.PriceElementMatrixItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PriceElementMatrix message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PriceElementMatrix
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PriceElementMatrix} PriceElementMatrix
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElementMatrix.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PriceElementMatrix();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.per = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PlatformCRMWeb.PriceElementMatrixItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElementMatrix;
    })();

    PlatformCRMWeb.PriceElementOption = (function() {

        /**
         * Properties of a PriceElementOption.
         * @memberof PlatformCRMWeb
         * @interface IPriceElementOption
         * @property {PlatformCRMWeb.IPriceElement|null} [priceElement] PriceElementOption priceElement
         * @property {string|null} [slug] PriceElementOption slug
         * @property {number|null} [costLinear] PriceElementOption costLinear
         * @property {PlatformCRMWeb.IPriceElementMatrix|null} [costMatrix] PriceElementOption costMatrix
         * @property {number|null} [durationLinear] PriceElementOption durationLinear
         * @property {PlatformCRMWeb.IPriceElementMatrix|null} [durationMatrix] PriceElementOption durationMatrix
         * @property {boolean|null} [isFree] PriceElementOption isFree
         * @property {number|null} [defaultValue] PriceElementOption defaultValue
         * @property {Array.<string>|null} [tags] PriceElementOption tags
         * @property {string|null} [title] PriceElementOption title
         * @property {string|null} [shortDesc] PriceElementOption shortDesc
         * @property {string|null} [iconName] PriceElementOption iconName
         * @property {PlatformCRMWeb.PriceElementOptionType|null} [type] PriceElementOption type
         * @property {string|null} [display] PriceElementOption display
         * @property {number|null} [defaultFactor] PriceElementOption defaultFactor
         */

        /**
         * Constructs a new PriceElementOption.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PriceElementOption.
         * @implements IPriceElementOption
         * @constructor
         * @param {PlatformCRMWeb.IPriceElementOption=} [properties] Properties to set
         */
        function PriceElementOption(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElementOption priceElement.
         * @member {PlatformCRMWeb.IPriceElement|null|undefined} priceElement
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.priceElement = null;

        /**
         * PriceElementOption slug.
         * @member {string} slug
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.slug = "";

        /**
         * PriceElementOption costLinear.
         * @member {number} costLinear
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.costLinear = 0;

        /**
         * PriceElementOption costMatrix.
         * @member {PlatformCRMWeb.IPriceElementMatrix|null|undefined} costMatrix
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.costMatrix = null;

        /**
         * PriceElementOption durationLinear.
         * @member {number} durationLinear
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.durationLinear = 0;

        /**
         * PriceElementOption durationMatrix.
         * @member {PlatformCRMWeb.IPriceElementMatrix|null|undefined} durationMatrix
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.durationMatrix = null;

        /**
         * PriceElementOption isFree.
         * @member {boolean} isFree
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.isFree = false;

        /**
         * PriceElementOption defaultValue.
         * @member {number} defaultValue
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.defaultValue = 0;

        /**
         * PriceElementOption tags.
         * @member {Array.<string>} tags
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.tags = $util.emptyArray;

        /**
         * PriceElementOption title.
         * @member {string} title
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.title = "";

        /**
         * PriceElementOption shortDesc.
         * @member {string} shortDesc
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.shortDesc = "";

        /**
         * PriceElementOption iconName.
         * @member {string} iconName
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.iconName = "";

        /**
         * PriceElementOption type.
         * @member {PlatformCRMWeb.PriceElementOptionType} type
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.type = 0;

        /**
         * PriceElementOption display.
         * @member {string} display
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.display = "";

        /**
         * PriceElementOption defaultFactor.
         * @member {number} defaultFactor
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.defaultFactor = 0;

        /**
         * Encodes the specified PriceElementOption message. Does not implicitly {@link PlatformCRMWeb.PriceElementOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PriceElementOption
         * @static
         * @param {PlatformCRMWeb.IPriceElementOption} message PriceElementOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElementOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.priceElement != null && Object.hasOwnProperty.call(message, "priceElement"))
                $root.PlatformCRMWeb.PriceElement.encode(message.priceElement, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.slug);
            if (message.costLinear != null && Object.hasOwnProperty.call(message, "costLinear"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.costLinear);
            if (message.costMatrix != null && Object.hasOwnProperty.call(message, "costMatrix"))
                $root.PlatformCRMWeb.PriceElementMatrix.encode(message.costMatrix, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.durationLinear != null && Object.hasOwnProperty.call(message, "durationLinear"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.durationLinear);
            if (message.durationMatrix != null && Object.hasOwnProperty.call(message, "durationMatrix"))
                $root.PlatformCRMWeb.PriceElementMatrix.encode(message.durationMatrix, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isFree);
            if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.defaultValue);
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.tags[i]);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.title);
            if (message.shortDesc != null && Object.hasOwnProperty.call(message, "shortDesc"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.shortDesc);
            if (message.iconName != null && Object.hasOwnProperty.call(message, "iconName"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.iconName);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.type);
            if (message.display != null && Object.hasOwnProperty.call(message, "display"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.display);
            if (message.defaultFactor != null && Object.hasOwnProperty.call(message, "defaultFactor"))
                writer.uint32(/* id 15, wireType 5 =*/125).float(message.defaultFactor);
            return writer;
        };

        /**
         * Decodes a PriceElementOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PriceElementOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PriceElementOption} PriceElementOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElementOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PriceElementOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.priceElement = $root.PlatformCRMWeb.PriceElement.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.slug = reader.string();
                    break;
                case 3:
                    message.costLinear = reader.int32();
                    break;
                case 4:
                    message.costMatrix = $root.PlatformCRMWeb.PriceElementMatrix.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.durationLinear = reader.int32();
                    break;
                case 6:
                    message.durationMatrix = $root.PlatformCRMWeb.PriceElementMatrix.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.isFree = reader.bool();
                    break;
                case 8:
                    message.defaultValue = reader.int32();
                    break;
                case 9:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push(reader.string());
                    break;
                case 10:
                    message.title = reader.string();
                    break;
                case 11:
                    message.shortDesc = reader.string();
                    break;
                case 12:
                    message.iconName = reader.string();
                    break;
                case 13:
                    message.type = reader.int32();
                    break;
                case 14:
                    message.display = reader.string();
                    break;
                case 15:
                    message.defaultFactor = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElementOption;
    })();

    PlatformCRMWeb.PriceElement = (function() {

        /**
         * Properties of a PriceElement.
         * @memberof PlatformCRMWeb
         * @interface IPriceElement
         * @property {string|null} [id] PriceElement id
         * @property {string|null} [productSlug] PriceElement productSlug
         * @property {Array.<PlatformCRMWeb.IPriceElementOption>|null} [options] PriceElement options
         * @property {number|null} [cost] PriceElement cost
         * @property {number|null} [duration] PriceElement duration
         */

        /**
         * Constructs a new PriceElement.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PriceElement.
         * @implements IPriceElement
         * @constructor
         * @param {PlatformCRMWeb.IPriceElement=} [properties] Properties to set
         */
        function PriceElement(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElement id.
         * @member {string} id
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.id = "";

        /**
         * PriceElement productSlug.
         * @member {string} productSlug
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.productSlug = "";

        /**
         * PriceElement options.
         * @member {Array.<PlatformCRMWeb.IPriceElementOption>} options
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.options = $util.emptyArray;

        /**
         * PriceElement cost.
         * @member {number} cost
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.cost = 0;

        /**
         * PriceElement duration.
         * @member {number} duration
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.duration = 0;

        /**
         * Encodes the specified PriceElement message. Does not implicitly {@link PlatformCRMWeb.PriceElement.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PriceElement
         * @static
         * @param {PlatformCRMWeb.IPriceElement} message PriceElement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.productSlug);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformCRMWeb.PriceElementOption.encode(message.options[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.cost != null && Object.hasOwnProperty.call(message, "cost"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.cost);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.duration);
            return writer;
        };

        /**
         * Decodes a PriceElement message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PriceElement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PriceElement} PriceElement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PriceElement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.productSlug = reader.string();
                    break;
                case 3:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformCRMWeb.PriceElementOption.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.cost = reader.int32();
                    break;
                case 5:
                    message.duration = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElement;
    })();

    PlatformCRMWeb.QuestionnaireService = (function() {

        /**
         * Constructs a new QuestionnaireService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a QuestionnaireService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function QuestionnaireService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (QuestionnaireService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = QuestionnaireService;

        /**
         * Callback as used by {@link PlatformCRMWeb.QuestionnaireService#getQuestionnaire}.
         * @memberof PlatformCRMWeb.QuestionnaireService
         * @typedef GetQuestionnaireCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.ClientQuestionnaireResponse} [response] ClientQuestionnaireResponse
         */

        /**
         * Calls GetQuestionnaire.
         * @function getQuestionnaire
         * @memberof PlatformCRMWeb.QuestionnaireService
         * @instance
         * @param {PlatformCRMWeb.IQuestionnaireRequest} request QuestionnaireRequest message or plain object
         * @param {PlatformCRMWeb.QuestionnaireService.GetQuestionnaireCallback} callback Node-style callback called with the error, if any, and ClientQuestionnaireResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(QuestionnaireService.prototype.getQuestionnaire = function getQuestionnaire(request, callback) {
            return this.rpcCall(getQuestionnaire, $root.PlatformCRMWeb.QuestionnaireRequest, $root.PlatformCRMWeb.ClientQuestionnaireResponse, request, callback);
        }, "name", { value: "GetQuestionnaire" });

        /**
         * Calls GetQuestionnaire.
         * @function getQuestionnaire
         * @memberof PlatformCRMWeb.QuestionnaireService
         * @instance
         * @param {PlatformCRMWeb.IQuestionnaireRequest} request QuestionnaireRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.ClientQuestionnaireResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.QuestionnaireService#getOptionsByQuestion}.
         * @memberof PlatformCRMWeb.QuestionnaireService
         * @typedef GetOptionsByQuestionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.ClientOptionsByQuestionResponse} [response] ClientOptionsByQuestionResponse
         */

        /**
         * Calls GetOptionsByQuestion.
         * @function getOptionsByQuestion
         * @memberof PlatformCRMWeb.QuestionnaireService
         * @instance
         * @param {PlatformCRMWeb.IOptionsByQuestionRequest} request OptionsByQuestionRequest message or plain object
         * @param {PlatformCRMWeb.QuestionnaireService.GetOptionsByQuestionCallback} callback Node-style callback called with the error, if any, and ClientOptionsByQuestionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(QuestionnaireService.prototype.getOptionsByQuestion = function getOptionsByQuestion(request, callback) {
            return this.rpcCall(getOptionsByQuestion, $root.PlatformCRMWeb.OptionsByQuestionRequest, $root.PlatformCRMWeb.ClientOptionsByQuestionResponse, request, callback);
        }, "name", { value: "GetOptionsByQuestion" });

        /**
         * Calls GetOptionsByQuestion.
         * @function getOptionsByQuestion
         * @memberof PlatformCRMWeb.QuestionnaireService
         * @instance
         * @param {PlatformCRMWeb.IOptionsByQuestionRequest} request OptionsByQuestionRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.ClientOptionsByQuestionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.QuestionnaireService#submitQuestionnaire}.
         * @memberof PlatformCRMWeb.QuestionnaireService
         * @typedef SubmitQuestionnaireCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.ClientSubmitQuestionnaireResponse} [response] ClientSubmitQuestionnaireResponse
         */

        /**
         * Calls SubmitQuestionnaire.
         * @function submitQuestionnaire
         * @memberof PlatformCRMWeb.QuestionnaireService
         * @instance
         * @param {PlatformCRMWeb.IClientSubmitQuestionnaireRequest} request ClientSubmitQuestionnaireRequest message or plain object
         * @param {PlatformCRMWeb.QuestionnaireService.SubmitQuestionnaireCallback} callback Node-style callback called with the error, if any, and ClientSubmitQuestionnaireResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(QuestionnaireService.prototype.submitQuestionnaire = function submitQuestionnaire(request, callback) {
            return this.rpcCall(submitQuestionnaire, $root.PlatformCRMWeb.ClientSubmitQuestionnaireRequest, $root.PlatformCRMWeb.ClientSubmitQuestionnaireResponse, request, callback);
        }, "name", { value: "SubmitQuestionnaire" });

        /**
         * Calls SubmitQuestionnaire.
         * @function submitQuestionnaire
         * @memberof PlatformCRMWeb.QuestionnaireService
         * @instance
         * @param {PlatformCRMWeb.IClientSubmitQuestionnaireRequest} request ClientSubmitQuestionnaireRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.ClientSubmitQuestionnaireResponse>} Promise
         * @variation 2
         */

        return QuestionnaireService;
    })();

    PlatformCRMWeb.QuestionnaireRequest = (function() {

        /**
         * Properties of a QuestionnaireRequest.
         * @memberof PlatformCRMWeb
         * @interface IQuestionnaireRequest
         * @property {string|null} [orderId] QuestionnaireRequest orderId
         */

        /**
         * Constructs a new QuestionnaireRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a QuestionnaireRequest.
         * @implements IQuestionnaireRequest
         * @constructor
         * @param {PlatformCRMWeb.IQuestionnaireRequest=} [properties] Properties to set
         */
        function QuestionnaireRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QuestionnaireRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.QuestionnaireRequest
         * @instance
         */
        QuestionnaireRequest.prototype.orderId = "";

        /**
         * Encodes the specified QuestionnaireRequest message. Does not implicitly {@link PlatformCRMWeb.QuestionnaireRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.QuestionnaireRequest
         * @static
         * @param {PlatformCRMWeb.IQuestionnaireRequest} message QuestionnaireRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuestionnaireRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            return writer;
        };

        /**
         * Decodes a QuestionnaireRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.QuestionnaireRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.QuestionnaireRequest} QuestionnaireRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuestionnaireRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.QuestionnaireRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return QuestionnaireRequest;
    })();

    PlatformCRMWeb.QuestionnaireExecutor = (function() {

        /**
         * Properties of a QuestionnaireExecutor.
         * @memberof PlatformCRMWeb
         * @interface IQuestionnaireExecutor
         * @property {string|null} [id] QuestionnaireExecutor id
         * @property {string|null} [name] QuestionnaireExecutor name
         * @property {string|null} [role] QuestionnaireExecutor role
         * @property {string|null} [phone] QuestionnaireExecutor phone
         * @property {Array.<PlatformCRMWeb.IQuestion>|null} [questions] QuestionnaireExecutor questions
         */

        /**
         * Constructs a new QuestionnaireExecutor.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a QuestionnaireExecutor.
         * @implements IQuestionnaireExecutor
         * @constructor
         * @param {PlatformCRMWeb.IQuestionnaireExecutor=} [properties] Properties to set
         */
        function QuestionnaireExecutor(properties) {
            this.questions = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QuestionnaireExecutor id.
         * @member {string} id
         * @memberof PlatformCRMWeb.QuestionnaireExecutor
         * @instance
         */
        QuestionnaireExecutor.prototype.id = "";

        /**
         * QuestionnaireExecutor name.
         * @member {string} name
         * @memberof PlatformCRMWeb.QuestionnaireExecutor
         * @instance
         */
        QuestionnaireExecutor.prototype.name = "";

        /**
         * QuestionnaireExecutor role.
         * @member {string} role
         * @memberof PlatformCRMWeb.QuestionnaireExecutor
         * @instance
         */
        QuestionnaireExecutor.prototype.role = "";

        /**
         * QuestionnaireExecutor phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.QuestionnaireExecutor
         * @instance
         */
        QuestionnaireExecutor.prototype.phone = "";

        /**
         * QuestionnaireExecutor questions.
         * @member {Array.<PlatformCRMWeb.IQuestion>} questions
         * @memberof PlatformCRMWeb.QuestionnaireExecutor
         * @instance
         */
        QuestionnaireExecutor.prototype.questions = $util.emptyArray;

        /**
         * Encodes the specified QuestionnaireExecutor message. Does not implicitly {@link PlatformCRMWeb.QuestionnaireExecutor.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.QuestionnaireExecutor
         * @static
         * @param {PlatformCRMWeb.IQuestionnaireExecutor} message QuestionnaireExecutor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuestionnaireExecutor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.role);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
            if (message.questions != null && message.questions.length)
                for (var i = 0; i < message.questions.length; ++i)
                    $root.PlatformCRMWeb.Question.encode(message.questions[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a QuestionnaireExecutor message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.QuestionnaireExecutor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.QuestionnaireExecutor} QuestionnaireExecutor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuestionnaireExecutor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.QuestionnaireExecutor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.role = reader.string();
                    break;
                case 4:
                    message.phone = reader.string();
                    break;
                case 5:
                    if (!(message.questions && message.questions.length))
                        message.questions = [];
                    message.questions.push($root.PlatformCRMWeb.Question.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return QuestionnaireExecutor;
    })();

    PlatformCRMWeb.Question = (function() {

        /**
         * Properties of a Question.
         * @memberof PlatformCRMWeb
         * @interface IQuestion
         * @property {number|null} [id] Question id
         * @property {number|null} [categoryId] Question categoryId
         * @property {number|null} [parentOptionId] Question parentOptionId
         * @property {string|null} [question] Question question
         * @property {string|null} [strategy] Question strategy
         * @property {string|null} [type] Question type
         * @property {boolean|null} [required] Question required
         * @property {Array.<PlatformCRMWeb.IClientOptionByQuestion>|null} [options] Question options
         */

        /**
         * Constructs a new Question.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Question.
         * @implements IQuestion
         * @constructor
         * @param {PlatformCRMWeb.IQuestion=} [properties] Properties to set
         */
        function Question(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Question id.
         * @member {number} id
         * @memberof PlatformCRMWeb.Question
         * @instance
         */
        Question.prototype.id = 0;

        /**
         * Question categoryId.
         * @member {number} categoryId
         * @memberof PlatformCRMWeb.Question
         * @instance
         */
        Question.prototype.categoryId = 0;

        /**
         * Question parentOptionId.
         * @member {number} parentOptionId
         * @memberof PlatformCRMWeb.Question
         * @instance
         */
        Question.prototype.parentOptionId = 0;

        /**
         * Question question.
         * @member {string} question
         * @memberof PlatformCRMWeb.Question
         * @instance
         */
        Question.prototype.question = "";

        /**
         * Question strategy.
         * @member {string} strategy
         * @memberof PlatformCRMWeb.Question
         * @instance
         */
        Question.prototype.strategy = "";

        /**
         * Question type.
         * @member {string} type
         * @memberof PlatformCRMWeb.Question
         * @instance
         */
        Question.prototype.type = "";

        /**
         * Question required.
         * @member {boolean} required
         * @memberof PlatformCRMWeb.Question
         * @instance
         */
        Question.prototype.required = false;

        /**
         * Question options.
         * @member {Array.<PlatformCRMWeb.IClientOptionByQuestion>} options
         * @memberof PlatformCRMWeb.Question
         * @instance
         */
        Question.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified Question message. Does not implicitly {@link PlatformCRMWeb.Question.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Question
         * @static
         * @param {PlatformCRMWeb.IQuestion} message Question message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Question.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.categoryId != null && Object.hasOwnProperty.call(message, "categoryId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.categoryId);
            if (message.parentOptionId != null && Object.hasOwnProperty.call(message, "parentOptionId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.parentOptionId);
            if (message.question != null && Object.hasOwnProperty.call(message, "question"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.question);
            if (message.strategy != null && Object.hasOwnProperty.call(message, "strategy"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.strategy);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.type);
            if (message.required != null && Object.hasOwnProperty.call(message, "required"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.required);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformCRMWeb.ClientOptionByQuestion.encode(message.options[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Question message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Question
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Question} Question
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Question.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Question();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.categoryId = reader.int32();
                    break;
                case 3:
                    message.parentOptionId = reader.int32();
                    break;
                case 4:
                    message.question = reader.string();
                    break;
                case 5:
                    message.strategy = reader.string();
                    break;
                case 6:
                    message.type = reader.string();
                    break;
                case 7:
                    message.required = reader.bool();
                    break;
                case 8:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformCRMWeb.ClientOptionByQuestion.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Question;
    })();

    PlatformCRMWeb.ClientQuestionnaireResponse = (function() {

        /**
         * Properties of a ClientQuestionnaireResponse.
         * @memberof PlatformCRMWeb
         * @interface IClientQuestionnaireResponse
         * @property {PlatformCRMWeb.IQuestionnaireExecutor|null} [reviewer] ClientQuestionnaireResponse reviewer
         * @property {Array.<PlatformCRMWeb.IQuestionnaireExecutor>|null} [reviewSubjects] ClientQuestionnaireResponse reviewSubjects
         * @property {boolean|null} [hasAnswers] ClientQuestionnaireResponse hasAnswers
         */

        /**
         * Constructs a new ClientQuestionnaireResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ClientQuestionnaireResponse.
         * @implements IClientQuestionnaireResponse
         * @constructor
         * @param {PlatformCRMWeb.IClientQuestionnaireResponse=} [properties] Properties to set
         */
        function ClientQuestionnaireResponse(properties) {
            this.reviewSubjects = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientQuestionnaireResponse reviewer.
         * @member {PlatformCRMWeb.IQuestionnaireExecutor|null|undefined} reviewer
         * @memberof PlatformCRMWeb.ClientQuestionnaireResponse
         * @instance
         */
        ClientQuestionnaireResponse.prototype.reviewer = null;

        /**
         * ClientQuestionnaireResponse reviewSubjects.
         * @member {Array.<PlatformCRMWeb.IQuestionnaireExecutor>} reviewSubjects
         * @memberof PlatformCRMWeb.ClientQuestionnaireResponse
         * @instance
         */
        ClientQuestionnaireResponse.prototype.reviewSubjects = $util.emptyArray;

        /**
         * ClientQuestionnaireResponse hasAnswers.
         * @member {boolean} hasAnswers
         * @memberof PlatformCRMWeb.ClientQuestionnaireResponse
         * @instance
         */
        ClientQuestionnaireResponse.prototype.hasAnswers = false;

        /**
         * Encodes the specified ClientQuestionnaireResponse message. Does not implicitly {@link PlatformCRMWeb.ClientQuestionnaireResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ClientQuestionnaireResponse
         * @static
         * @param {PlatformCRMWeb.IClientQuestionnaireResponse} message ClientQuestionnaireResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientQuestionnaireResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reviewer != null && Object.hasOwnProperty.call(message, "reviewer"))
                $root.PlatformCRMWeb.QuestionnaireExecutor.encode(message.reviewer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.reviewSubjects != null && message.reviewSubjects.length)
                for (var i = 0; i < message.reviewSubjects.length; ++i)
                    $root.PlatformCRMWeb.QuestionnaireExecutor.encode(message.reviewSubjects[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.hasAnswers != null && Object.hasOwnProperty.call(message, "hasAnswers"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.hasAnswers);
            return writer;
        };

        /**
         * Decodes a ClientQuestionnaireResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ClientQuestionnaireResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ClientQuestionnaireResponse} ClientQuestionnaireResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientQuestionnaireResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ClientQuestionnaireResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.reviewer = $root.PlatformCRMWeb.QuestionnaireExecutor.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.reviewSubjects && message.reviewSubjects.length))
                        message.reviewSubjects = [];
                    message.reviewSubjects.push($root.PlatformCRMWeb.QuestionnaireExecutor.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.hasAnswers = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ClientQuestionnaireResponse;
    })();

    PlatformCRMWeb.OptionsByQuestionRequest = (function() {

        /**
         * Properties of an OptionsByQuestionRequest.
         * @memberof PlatformCRMWeb
         * @interface IOptionsByQuestionRequest
         * @property {number|null} [questionId] OptionsByQuestionRequest questionId
         */

        /**
         * Constructs a new OptionsByQuestionRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OptionsByQuestionRequest.
         * @implements IOptionsByQuestionRequest
         * @constructor
         * @param {PlatformCRMWeb.IOptionsByQuestionRequest=} [properties] Properties to set
         */
        function OptionsByQuestionRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OptionsByQuestionRequest questionId.
         * @member {number} questionId
         * @memberof PlatformCRMWeb.OptionsByQuestionRequest
         * @instance
         */
        OptionsByQuestionRequest.prototype.questionId = 0;

        /**
         * Encodes the specified OptionsByQuestionRequest message. Does not implicitly {@link PlatformCRMWeb.OptionsByQuestionRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.OptionsByQuestionRequest
         * @static
         * @param {PlatformCRMWeb.IOptionsByQuestionRequest} message OptionsByQuestionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OptionsByQuestionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.questionId != null && Object.hasOwnProperty.call(message, "questionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.questionId);
            return writer;
        };

        /**
         * Decodes an OptionsByQuestionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.OptionsByQuestionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.OptionsByQuestionRequest} OptionsByQuestionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OptionsByQuestionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.OptionsByQuestionRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.questionId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OptionsByQuestionRequest;
    })();

    PlatformCRMWeb.ClientOptionByQuestion = (function() {

        /**
         * Properties of a ClientOptionByQuestion.
         * @memberof PlatformCRMWeb
         * @interface IClientOptionByQuestion
         * @property {string|null} [id] ClientOptionByQuestion id
         * @property {number|null} [questionId] ClientOptionByQuestion questionId
         * @property {string|null} [slug] ClientOptionByQuestion slug
         * @property {string|null} [title] ClientOptionByQuestion title
         */

        /**
         * Constructs a new ClientOptionByQuestion.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ClientOptionByQuestion.
         * @implements IClientOptionByQuestion
         * @constructor
         * @param {PlatformCRMWeb.IClientOptionByQuestion=} [properties] Properties to set
         */
        function ClientOptionByQuestion(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientOptionByQuestion id.
         * @member {string} id
         * @memberof PlatformCRMWeb.ClientOptionByQuestion
         * @instance
         */
        ClientOptionByQuestion.prototype.id = "";

        /**
         * ClientOptionByQuestion questionId.
         * @member {number} questionId
         * @memberof PlatformCRMWeb.ClientOptionByQuestion
         * @instance
         */
        ClientOptionByQuestion.prototype.questionId = 0;

        /**
         * ClientOptionByQuestion slug.
         * @member {string} slug
         * @memberof PlatformCRMWeb.ClientOptionByQuestion
         * @instance
         */
        ClientOptionByQuestion.prototype.slug = "";

        /**
         * ClientOptionByQuestion title.
         * @member {string} title
         * @memberof PlatformCRMWeb.ClientOptionByQuestion
         * @instance
         */
        ClientOptionByQuestion.prototype.title = "";

        /**
         * Encodes the specified ClientOptionByQuestion message. Does not implicitly {@link PlatformCRMWeb.ClientOptionByQuestion.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ClientOptionByQuestion
         * @static
         * @param {PlatformCRMWeb.IClientOptionByQuestion} message ClientOptionByQuestion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientOptionByQuestion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.questionId != null && Object.hasOwnProperty.call(message, "questionId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.questionId);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.slug);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.title);
            return writer;
        };

        /**
         * Decodes a ClientOptionByQuestion message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ClientOptionByQuestion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ClientOptionByQuestion} ClientOptionByQuestion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientOptionByQuestion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ClientOptionByQuestion();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.questionId = reader.int32();
                    break;
                case 3:
                    message.slug = reader.string();
                    break;
                case 4:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ClientOptionByQuestion;
    })();

    PlatformCRMWeb.ClientOptionsByQuestionResponse = (function() {

        /**
         * Properties of a ClientOptionsByQuestionResponse.
         * @memberof PlatformCRMWeb
         * @interface IClientOptionsByQuestionResponse
         * @property {Array.<PlatformCRMWeb.IClientOptionByQuestion>|null} [options] ClientOptionsByQuestionResponse options
         */

        /**
         * Constructs a new ClientOptionsByQuestionResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ClientOptionsByQuestionResponse.
         * @implements IClientOptionsByQuestionResponse
         * @constructor
         * @param {PlatformCRMWeb.IClientOptionsByQuestionResponse=} [properties] Properties to set
         */
        function ClientOptionsByQuestionResponse(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientOptionsByQuestionResponse options.
         * @member {Array.<PlatformCRMWeb.IClientOptionByQuestion>} options
         * @memberof PlatformCRMWeb.ClientOptionsByQuestionResponse
         * @instance
         */
        ClientOptionsByQuestionResponse.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified ClientOptionsByQuestionResponse message. Does not implicitly {@link PlatformCRMWeb.ClientOptionsByQuestionResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ClientOptionsByQuestionResponse
         * @static
         * @param {PlatformCRMWeb.IClientOptionsByQuestionResponse} message ClientOptionsByQuestionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientOptionsByQuestionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformCRMWeb.ClientOptionByQuestion.encode(message.options[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ClientOptionsByQuestionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ClientOptionsByQuestionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ClientOptionsByQuestionResponse} ClientOptionsByQuestionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientOptionsByQuestionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ClientOptionsByQuestionResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformCRMWeb.ClientOptionByQuestion.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ClientOptionsByQuestionResponse;
    })();

    PlatformCRMWeb.ClientSubmitQuestionnaireRequest = (function() {

        /**
         * Properties of a ClientSubmitQuestionnaireRequest.
         * @memberof PlatformCRMWeb
         * @interface IClientSubmitQuestionnaireRequest
         * @property {string|null} [orderId] ClientSubmitQuestionnaireRequest orderId
         * @property {number|null} [optionId] ClientSubmitQuestionnaireRequest optionId
         * @property {number|null} [questionId] ClientSubmitQuestionnaireRequest questionId
         * @property {string|null} [reviewerUserId] ClientSubmitQuestionnaireRequest reviewerUserId
         * @property {string|null} [reviewerSubjectUserId] ClientSubmitQuestionnaireRequest reviewerSubjectUserId
         * @property {string|null} [answer] ClientSubmitQuestionnaireRequest answer
         */

        /**
         * Constructs a new ClientSubmitQuestionnaireRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ClientSubmitQuestionnaireRequest.
         * @implements IClientSubmitQuestionnaireRequest
         * @constructor
         * @param {PlatformCRMWeb.IClientSubmitQuestionnaireRequest=} [properties] Properties to set
         */
        function ClientSubmitQuestionnaireRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientSubmitQuestionnaireRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireRequest
         * @instance
         */
        ClientSubmitQuestionnaireRequest.prototype.orderId = "";

        /**
         * ClientSubmitQuestionnaireRequest optionId.
         * @member {number} optionId
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireRequest
         * @instance
         */
        ClientSubmitQuestionnaireRequest.prototype.optionId = 0;

        /**
         * ClientSubmitQuestionnaireRequest questionId.
         * @member {number} questionId
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireRequest
         * @instance
         */
        ClientSubmitQuestionnaireRequest.prototype.questionId = 0;

        /**
         * ClientSubmitQuestionnaireRequest reviewerUserId.
         * @member {string} reviewerUserId
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireRequest
         * @instance
         */
        ClientSubmitQuestionnaireRequest.prototype.reviewerUserId = "";

        /**
         * ClientSubmitQuestionnaireRequest reviewerSubjectUserId.
         * @member {string} reviewerSubjectUserId
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireRequest
         * @instance
         */
        ClientSubmitQuestionnaireRequest.prototype.reviewerSubjectUserId = "";

        /**
         * ClientSubmitQuestionnaireRequest answer.
         * @member {string} answer
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireRequest
         * @instance
         */
        ClientSubmitQuestionnaireRequest.prototype.answer = "";

        /**
         * Encodes the specified ClientSubmitQuestionnaireRequest message. Does not implicitly {@link PlatformCRMWeb.ClientSubmitQuestionnaireRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireRequest
         * @static
         * @param {PlatformCRMWeb.IClientSubmitQuestionnaireRequest} message ClientSubmitQuestionnaireRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientSubmitQuestionnaireRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            if (message.optionId != null && Object.hasOwnProperty.call(message, "optionId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.optionId);
            if (message.questionId != null && Object.hasOwnProperty.call(message, "questionId"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.questionId);
            if (message.reviewerUserId != null && Object.hasOwnProperty.call(message, "reviewerUserId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.reviewerUserId);
            if (message.reviewerSubjectUserId != null && Object.hasOwnProperty.call(message, "reviewerSubjectUserId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.reviewerSubjectUserId);
            if (message.answer != null && Object.hasOwnProperty.call(message, "answer"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.answer);
            return writer;
        };

        /**
         * Decodes a ClientSubmitQuestionnaireRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ClientSubmitQuestionnaireRequest} ClientSubmitQuestionnaireRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientSubmitQuestionnaireRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ClientSubmitQuestionnaireRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                case 2:
                    message.optionId = reader.int32();
                    break;
                case 3:
                    message.questionId = reader.int32();
                    break;
                case 4:
                    message.reviewerUserId = reader.string();
                    break;
                case 5:
                    message.reviewerSubjectUserId = reader.string();
                    break;
                case 6:
                    message.answer = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ClientSubmitQuestionnaireRequest;
    })();

    PlatformCRMWeb.ClientSubmitQuestionnaireResponse = (function() {

        /**
         * Properties of a ClientSubmitQuestionnaireResponse.
         * @memberof PlatformCRMWeb
         * @interface IClientSubmitQuestionnaireResponse
         * @property {boolean|null} [success] ClientSubmitQuestionnaireResponse success
         */

        /**
         * Constructs a new ClientSubmitQuestionnaireResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ClientSubmitQuestionnaireResponse.
         * @implements IClientSubmitQuestionnaireResponse
         * @constructor
         * @param {PlatformCRMWeb.IClientSubmitQuestionnaireResponse=} [properties] Properties to set
         */
        function ClientSubmitQuestionnaireResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientSubmitQuestionnaireResponse success.
         * @member {boolean} success
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireResponse
         * @instance
         */
        ClientSubmitQuestionnaireResponse.prototype.success = false;

        /**
         * Encodes the specified ClientSubmitQuestionnaireResponse message. Does not implicitly {@link PlatformCRMWeb.ClientSubmitQuestionnaireResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireResponse
         * @static
         * @param {PlatformCRMWeb.IClientSubmitQuestionnaireResponse} message ClientSubmitQuestionnaireResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientSubmitQuestionnaireResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            return writer;
        };

        /**
         * Decodes a ClientSubmitQuestionnaireResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ClientSubmitQuestionnaireResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ClientSubmitQuestionnaireResponse} ClientSubmitQuestionnaireResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientSubmitQuestionnaireResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ClientSubmitQuestionnaireResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ClientSubmitQuestionnaireResponse;
    })();

    return PlatformCRMWeb;
})();

$root.sso = (function() {

    /**
     * Namespace sso.
     * @exports sso
     * @namespace
     */
    var sso = {};

    sso.ProfileV2Service = (function() {

        /**
         * Constructs a new ProfileV2Service service.
         * @memberof sso
         * @classdesc Represents a ProfileV2Service
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ProfileV2Service(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ProfileV2Service.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProfileV2Service;

        /**
         * Callback as used by {@link sso.ProfileV2Service#createProfile}.
         * @memberof sso.ProfileV2Service
         * @typedef CreateProfileCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.CreateProfileResponse} [response] CreateProfileResponse
         */

        /**
         * Calls CreateProfile.
         * @function createProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.ICreateProfileRequest} request CreateProfileRequest message or plain object
         * @param {sso.ProfileV2Service.CreateProfileCallback} callback Node-style callback called with the error, if any, and CreateProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.createProfile = function createProfile(request, callback) {
            return this.rpcCall(createProfile, $root.sso.CreateProfileRequest, $root.sso.CreateProfileResponse, request, callback);
        }, "name", { value: "CreateProfile" });

        /**
         * Calls CreateProfile.
         * @function createProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.ICreateProfileRequest} request CreateProfileRequest message or plain object
         * @returns {Promise<sso.CreateProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#getProfile}.
         * @memberof sso.ProfileV2Service
         * @typedef GetProfileCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.GetProfileResponse} [response] GetProfileResponse
         */

        /**
         * Calls GetProfile.
         * @function getProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetProfileRequest} request GetProfileRequest message or plain object
         * @param {sso.ProfileV2Service.GetProfileCallback} callback Node-style callback called with the error, if any, and GetProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.getProfile = function getProfile(request, callback) {
            return this.rpcCall(getProfile, $root.sso.GetProfileRequest, $root.sso.GetProfileResponse, request, callback);
        }, "name", { value: "GetProfile" });

        /**
         * Calls GetProfile.
         * @function getProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetProfileRequest} request GetProfileRequest message or plain object
         * @returns {Promise<sso.GetProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#updateProfile}.
         * @memberof sso.ProfileV2Service
         * @typedef UpdateProfileCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.UpdateProfileResponse} [response] UpdateProfileResponse
         */

        /**
         * Calls UpdateProfile.
         * @function updateProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IUpdateProfileRequest} request UpdateProfileRequest message or plain object
         * @param {sso.ProfileV2Service.UpdateProfileCallback} callback Node-style callback called with the error, if any, and UpdateProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.updateProfile = function updateProfile(request, callback) {
            return this.rpcCall(updateProfile, $root.sso.UpdateProfileRequest, $root.sso.UpdateProfileResponse, request, callback);
        }, "name", { value: "UpdateProfile" });

        /**
         * Calls UpdateProfile.
         * @function updateProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IUpdateProfileRequest} request UpdateProfileRequest message or plain object
         * @returns {Promise<sso.UpdateProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#findOrCreateProfile}.
         * @memberof sso.ProfileV2Service
         * @typedef FindOrCreateProfileCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.FindOrCreateProfileResponse} [response] FindOrCreateProfileResponse
         */

        /**
         * Calls FindOrCreateProfile.
         * @function findOrCreateProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IFindOrCreateProfileRequest} request FindOrCreateProfileRequest message or plain object
         * @param {sso.ProfileV2Service.FindOrCreateProfileCallback} callback Node-style callback called with the error, if any, and FindOrCreateProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.findOrCreateProfile = function findOrCreateProfile(request, callback) {
            return this.rpcCall(findOrCreateProfile, $root.sso.FindOrCreateProfileRequest, $root.sso.FindOrCreateProfileResponse, request, callback);
        }, "name", { value: "FindOrCreateProfile" });

        /**
         * Calls FindOrCreateProfile.
         * @function findOrCreateProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IFindOrCreateProfileRequest} request FindOrCreateProfileRequest message or plain object
         * @returns {Promise<sso.FindOrCreateProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#upsertProfile}.
         * @memberof sso.ProfileV2Service
         * @typedef UpsertProfileCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.UpsertProfileResponse} [response] UpsertProfileResponse
         */

        /**
         * Calls UpsertProfile.
         * @function upsertProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IUpsertProfileRequest} request UpsertProfileRequest message or plain object
         * @param {sso.ProfileV2Service.UpsertProfileCallback} callback Node-style callback called with the error, if any, and UpsertProfileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.upsertProfile = function upsertProfile(request, callback) {
            return this.rpcCall(upsertProfile, $root.sso.UpsertProfileRequest, $root.sso.UpsertProfileResponse, request, callback);
        }, "name", { value: "UpsertProfile" });

        /**
         * Calls UpsertProfile.
         * @function upsertProfile
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IUpsertProfileRequest} request UpsertProfileRequest message or plain object
         * @returns {Promise<sso.UpsertProfileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#addContact}.
         * @memberof sso.ProfileV2Service
         * @typedef AddContactCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.AddContactResponse} [response] AddContactResponse
         */

        /**
         * Calls AddContact.
         * @function addContact
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IAddContactRequest} request AddContactRequest message or plain object
         * @param {sso.ProfileV2Service.AddContactCallback} callback Node-style callback called with the error, if any, and AddContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.addContact = function addContact(request, callback) {
            return this.rpcCall(addContact, $root.sso.AddContactRequest, $root.sso.AddContactResponse, request, callback);
        }, "name", { value: "AddContact" });

        /**
         * Calls AddContact.
         * @function addContact
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IAddContactRequest} request AddContactRequest message or plain object
         * @returns {Promise<sso.AddContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#getContact}.
         * @memberof sso.ProfileV2Service
         * @typedef GetContactCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.GetContactResponse} [response] GetContactResponse
         */

        /**
         * Calls GetContact.
         * @function getContact
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetContactRequest} request GetContactRequest message or plain object
         * @param {sso.ProfileV2Service.GetContactCallback} callback Node-style callback called with the error, if any, and GetContactResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.getContact = function getContact(request, callback) {
            return this.rpcCall(getContact, $root.sso.GetContactRequest, $root.sso.GetContactResponse, request, callback);
        }, "name", { value: "GetContact" });

        /**
         * Calls GetContact.
         * @function getContact
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetContactRequest} request GetContactRequest message or plain object
         * @returns {Promise<sso.GetContactResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#addAddress}.
         * @memberof sso.ProfileV2Service
         * @typedef AddAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.AddAddressResponse} [response] AddAddressResponse
         */

        /**
         * Calls AddAddress.
         * @function addAddress
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IAddAddressRequest} request AddAddressRequest message or plain object
         * @param {sso.ProfileV2Service.AddAddressCallback} callback Node-style callback called with the error, if any, and AddAddressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.addAddress = function addAddress(request, callback) {
            return this.rpcCall(addAddress, $root.sso.AddAddressRequest, $root.sso.AddAddressResponse, request, callback);
        }, "name", { value: "AddAddress" });

        /**
         * Calls AddAddress.
         * @function addAddress
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IAddAddressRequest} request AddAddressRequest message or plain object
         * @returns {Promise<sso.AddAddressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#getAddresses}.
         * @memberof sso.ProfileV2Service
         * @typedef GetAddressesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.GetAddressesResponse} [response] GetAddressesResponse
         */

        /**
         * Calls GetAddresses.
         * @function getAddresses
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetAddressesRequest} request GetAddressesRequest message or plain object
         * @param {sso.ProfileV2Service.GetAddressesCallback} callback Node-style callback called with the error, if any, and GetAddressesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.getAddresses = function getAddresses(request, callback) {
            return this.rpcCall(getAddresses, $root.sso.GetAddressesRequest, $root.sso.GetAddressesResponse, request, callback);
        }, "name", { value: "GetAddresses" });

        /**
         * Calls GetAddresses.
         * @function getAddresses
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetAddressesRequest} request GetAddressesRequest message or plain object
         * @returns {Promise<sso.GetAddressesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#getPassportAddresses}.
         * @memberof sso.ProfileV2Service
         * @typedef GetPassportAddressesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.GetPassportAddressesResponse} [response] GetPassportAddressesResponse
         */

        /**
         * Calls GetPassportAddresses.
         * @function getPassportAddresses
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetPassportAddressesRequest} request GetPassportAddressesRequest message or plain object
         * @param {sso.ProfileV2Service.GetPassportAddressesCallback} callback Node-style callback called with the error, if any, and GetPassportAddressesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.getPassportAddresses = function getPassportAddresses(request, callback) {
            return this.rpcCall(getPassportAddresses, $root.sso.GetPassportAddressesRequest, $root.sso.GetPassportAddressesResponse, request, callback);
        }, "name", { value: "GetPassportAddresses" });

        /**
         * Calls GetPassportAddresses.
         * @function getPassportAddresses
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetPassportAddressesRequest} request GetPassportAddressesRequest message or plain object
         * @returns {Promise<sso.GetPassportAddressesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#updateAddress}.
         * @memberof sso.ProfileV2Service
         * @typedef UpdateAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.UpdateAddressResponse} [response] UpdateAddressResponse
         */

        /**
         * Calls UpdateAddress.
         * @function updateAddress
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IUpdateAddressRequest} request UpdateAddressRequest message or plain object
         * @param {sso.ProfileV2Service.UpdateAddressCallback} callback Node-style callback called with the error, if any, and UpdateAddressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.updateAddress = function updateAddress(request, callback) {
            return this.rpcCall(updateAddress, $root.sso.UpdateAddressRequest, $root.sso.UpdateAddressResponse, request, callback);
        }, "name", { value: "UpdateAddress" });

        /**
         * Calls UpdateAddress.
         * @function updateAddress
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IUpdateAddressRequest} request UpdateAddressRequest message or plain object
         * @returns {Promise<sso.UpdateAddressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#deleteAddress}.
         * @memberof sso.ProfileV2Service
         * @typedef DeleteAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.DeleteAddressResponse} [response] DeleteAddressResponse
         */

        /**
         * Calls DeleteAddress.
         * @function deleteAddress
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IDeleteAddressRequest} request DeleteAddressRequest message or plain object
         * @param {sso.ProfileV2Service.DeleteAddressCallback} callback Node-style callback called with the error, if any, and DeleteAddressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.deleteAddress = function deleteAddress(request, callback) {
            return this.rpcCall(deleteAddress, $root.sso.DeleteAddressRequest, $root.sso.DeleteAddressResponse, request, callback);
        }, "name", { value: "DeleteAddress" });

        /**
         * Calls DeleteAddress.
         * @function deleteAddress
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IDeleteAddressRequest} request DeleteAddressRequest message or plain object
         * @returns {Promise<sso.DeleteAddressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#addPassport}.
         * @memberof sso.ProfileV2Service
         * @typedef AddPassportCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.AddPassportResponse} [response] AddPassportResponse
         */

        /**
         * Calls AddPassport.
         * @function addPassport
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IAddPassportRequest} request AddPassportRequest message or plain object
         * @param {sso.ProfileV2Service.AddPassportCallback} callback Node-style callback called with the error, if any, and AddPassportResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.addPassport = function addPassport(request, callback) {
            return this.rpcCall(addPassport, $root.sso.AddPassportRequest, $root.sso.AddPassportResponse, request, callback);
        }, "name", { value: "AddPassport" });

        /**
         * Calls AddPassport.
         * @function addPassport
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IAddPassportRequest} request AddPassportRequest message or plain object
         * @returns {Promise<sso.AddPassportResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#getPassport}.
         * @memberof sso.ProfileV2Service
         * @typedef GetPassportCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.GetPassportResponse} [response] GetPassportResponse
         */

        /**
         * Calls GetPassport.
         * @function getPassport
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetPassportRequest} request GetPassportRequest message or plain object
         * @param {sso.ProfileV2Service.GetPassportCallback} callback Node-style callback called with the error, if any, and GetPassportResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.getPassport = function getPassport(request, callback) {
            return this.rpcCall(getPassport, $root.sso.GetPassportRequest, $root.sso.GetPassportResponse, request, callback);
        }, "name", { value: "GetPassport" });

        /**
         * Calls GetPassport.
         * @function getPassport
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetPassportRequest} request GetPassportRequest message or plain object
         * @returns {Promise<sso.GetPassportResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#addPhoto}.
         * @memberof sso.ProfileV2Service
         * @typedef AddPhotoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.AddPhotoResponse} [response] AddPhotoResponse
         */

        /**
         * Calls AddPhoto.
         * @function addPhoto
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IAddPhotoRequest} request AddPhotoRequest message or plain object
         * @param {sso.ProfileV2Service.AddPhotoCallback} callback Node-style callback called with the error, if any, and AddPhotoResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.addPhoto = function addPhoto(request, callback) {
            return this.rpcCall(addPhoto, $root.sso.AddPhotoRequest, $root.sso.AddPhotoResponse, request, callback);
        }, "name", { value: "AddPhoto" });

        /**
         * Calls AddPhoto.
         * @function addPhoto
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IAddPhotoRequest} request AddPhotoRequest message or plain object
         * @returns {Promise<sso.AddPhotoResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#getPhoto}.
         * @memberof sso.ProfileV2Service
         * @typedef GetPhotoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.GetPhotoResponse} [response] GetPhotoResponse
         */

        /**
         * Calls GetPhoto.
         * @function getPhoto
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetPhotoRequest} request GetPhotoRequest message or plain object
         * @param {sso.ProfileV2Service.GetPhotoCallback} callback Node-style callback called with the error, if any, and GetPhotoResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.getPhoto = function getPhoto(request, callback) {
            return this.rpcCall(getPhoto, $root.sso.GetPhotoRequest, $root.sso.GetPhotoResponse, request, callback);
        }, "name", { value: "GetPhoto" });

        /**
         * Calls GetPhoto.
         * @function getPhoto
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IGetPhotoRequest} request GetPhotoRequest message or plain object
         * @returns {Promise<sso.GetPhotoResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#updatePhoto}.
         * @memberof sso.ProfileV2Service
         * @typedef UpdatePhotoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.UpdatePhotoResponse} [response] UpdatePhotoResponse
         */

        /**
         * Calls UpdatePhoto.
         * @function updatePhoto
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IUpdatePhotoRequest} request UpdatePhotoRequest message or plain object
         * @param {sso.ProfileV2Service.UpdatePhotoCallback} callback Node-style callback called with the error, if any, and UpdatePhotoResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.updatePhoto = function updatePhoto(request, callback) {
            return this.rpcCall(updatePhoto, $root.sso.UpdatePhotoRequest, $root.sso.UpdatePhotoResponse, request, callback);
        }, "name", { value: "UpdatePhoto" });

        /**
         * Calls UpdatePhoto.
         * @function updatePhoto
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IUpdatePhotoRequest} request UpdatePhotoRequest message or plain object
         * @returns {Promise<sso.UpdatePhotoResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link sso.ProfileV2Service#deletePhoto}.
         * @memberof sso.ProfileV2Service
         * @typedef DeletePhotoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sso.DeletePhotoResponse} [response] DeletePhotoResponse
         */

        /**
         * Calls DeletePhoto.
         * @function deletePhoto
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IDeletePhotoRequest} request DeletePhotoRequest message or plain object
         * @param {sso.ProfileV2Service.DeletePhotoCallback} callback Node-style callback called with the error, if any, and DeletePhotoResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProfileV2Service.prototype.deletePhoto = function deletePhoto(request, callback) {
            return this.rpcCall(deletePhoto, $root.sso.DeletePhotoRequest, $root.sso.DeletePhotoResponse, request, callback);
        }, "name", { value: "DeletePhoto" });

        /**
         * Calls DeletePhoto.
         * @function deletePhoto
         * @memberof sso.ProfileV2Service
         * @instance
         * @param {sso.IDeletePhotoRequest} request DeletePhotoRequest message or plain object
         * @returns {Promise<sso.DeletePhotoResponse>} Promise
         * @variation 2
         */

        return ProfileV2Service;
    })();

    sso.Contact = (function() {

        /**
         * Properties of a Contact.
         * @memberof sso
         * @interface IContact
         * @property {string|null} [value] Contact value
         * @property {sso.CONTACT_TYPE|null} [type] Contact type
         */

        /**
         * Constructs a new Contact.
         * @memberof sso
         * @classdesc Represents a Contact.
         * @implements IContact
         * @constructor
         * @param {sso.IContact=} [properties] Properties to set
         */
        function Contact(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Contact value.
         * @member {string} value
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.value = "";

        /**
         * Contact type.
         * @member {sso.CONTACT_TYPE} type
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.type = 0;

        /**
         * Encodes the specified Contact message. Does not implicitly {@link sso.Contact.verify|verify} messages.
         * @function encode
         * @memberof sso.Contact
         * @static
         * @param {sso.IContact} message Contact message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Contact.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            return writer;
        };

        /**
         * Decodes a Contact message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Contact
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Contact} Contact
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Contact.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Contact();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Contact;
    })();

    sso.Coordinates = (function() {

        /**
         * Properties of a Coordinates.
         * @memberof sso
         * @interface ICoordinates
         * @property {number|null} [lat] Coordinates lat
         * @property {number|null} [lng] Coordinates lng
         */

        /**
         * Constructs a new Coordinates.
         * @memberof sso
         * @classdesc Represents a Coordinates.
         * @implements ICoordinates
         * @constructor
         * @param {sso.ICoordinates=} [properties] Properties to set
         */
        function Coordinates(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Coordinates lat.
         * @member {number} lat
         * @memberof sso.Coordinates
         * @instance
         */
        Coordinates.prototype.lat = 0;

        /**
         * Coordinates lng.
         * @member {number} lng
         * @memberof sso.Coordinates
         * @instance
         */
        Coordinates.prototype.lng = 0;

        /**
         * Encodes the specified Coordinates message. Does not implicitly {@link sso.Coordinates.verify|verify} messages.
         * @function encode
         * @memberof sso.Coordinates
         * @static
         * @param {sso.ICoordinates} message Coordinates message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Coordinates.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.lat);
            if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.lng);
            return writer;
        };

        /**
         * Decodes a Coordinates message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Coordinates
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Coordinates} Coordinates
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Coordinates.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Coordinates();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.lat = reader.double();
                    break;
                case 2:
                    message.lng = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Coordinates;
    })();

    sso.Metro = (function() {

        /**
         * Properties of a Metro.
         * @memberof sso
         * @interface IMetro
         * @property {number|null} [distance] Metro distance
         * @property {string|null} [line] Metro line
         * @property {string|null} [name] Metro name
         */

        /**
         * Constructs a new Metro.
         * @memberof sso
         * @classdesc Represents a Metro.
         * @implements IMetro
         * @constructor
         * @param {sso.IMetro=} [properties] Properties to set
         */
        function Metro(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Metro distance.
         * @member {number} distance
         * @memberof sso.Metro
         * @instance
         */
        Metro.prototype.distance = 0;

        /**
         * Metro line.
         * @member {string} line
         * @memberof sso.Metro
         * @instance
         */
        Metro.prototype.line = "";

        /**
         * Metro name.
         * @member {string} name
         * @memberof sso.Metro
         * @instance
         */
        Metro.prototype.name = "";

        /**
         * Encodes the specified Metro message. Does not implicitly {@link sso.Metro.verify|verify} messages.
         * @function encode
         * @memberof sso.Metro
         * @static
         * @param {sso.IMetro} message Metro message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Metro.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.distance);
            if (message.line != null && Object.hasOwnProperty.call(message, "line"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.line);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a Metro message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Metro
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Metro} Metro
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Metro.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Metro();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.distance = reader.double();
                    break;
                case 2:
                    message.line = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Metro;
    })();

    sso.AddressDetail = (function() {

        /**
         * Properties of an AddressDetail.
         * @memberof sso
         * @interface IAddressDetail
         * @property {string|null} [floor] AddressDetail floor
         * @property {string|null} [comment] AddressDetail comment
         * @property {string|null} [intercom] AddressDetail intercom
         * @property {string|null} [entrance] AddressDetail entrance
         */

        /**
         * Constructs a new AddressDetail.
         * @memberof sso
         * @classdesc Represents an AddressDetail.
         * @implements IAddressDetail
         * @constructor
         * @param {sso.IAddressDetail=} [properties] Properties to set
         */
        function AddressDetail(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressDetail floor.
         * @member {string} floor
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.floor = "";

        /**
         * AddressDetail comment.
         * @member {string} comment
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.comment = "";

        /**
         * AddressDetail intercom.
         * @member {string} intercom
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.intercom = "";

        /**
         * AddressDetail entrance.
         * @member {string} entrance
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.entrance = "";

        /**
         * Encodes the specified AddressDetail message. Does not implicitly {@link sso.AddressDetail.verify|verify} messages.
         * @function encode
         * @memberof sso.AddressDetail
         * @static
         * @param {sso.IAddressDetail} message AddressDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressDetail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.floor != null && Object.hasOwnProperty.call(message, "floor"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.floor);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.comment);
            if (message.intercom != null && Object.hasOwnProperty.call(message, "intercom"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.intercom);
            if (message.entrance != null && Object.hasOwnProperty.call(message, "entrance"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.entrance);
            return writer;
        };

        /**
         * Decodes an AddressDetail message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddressDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddressDetail} AddressDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressDetail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddressDetail();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.floor = reader.string();
                    break;
                case 2:
                    message.comment = reader.string();
                    break;
                case 3:
                    message.intercom = reader.string();
                    break;
                case 4:
                    message.entrance = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddressDetail;
    })();

    sso.Address = (function() {

        /**
         * Properties of an Address.
         * @memberof sso
         * @interface IAddress
         * @property {string|null} [id] Address id
         * @property {string|null} [label] Address label
         * @property {string|null} [value] Address value
         * @property {string|null} [unrestrictedValue] Address unrestrictedValue
         * @property {string|null} [object] Address object
         * @property {sso.IAddressDetail|null} [detail] Address detail
         * @property {sso.ICoordinates|null} [coordinates] Address coordinates
         * @property {Array.<sso.IMetro>|null} [metro] Address metro
         * @property {sso.ADDRESS_TYPE|null} [type] Address type
         */

        /**
         * Constructs a new Address.
         * @memberof sso
         * @classdesc Represents an Address.
         * @implements IAddress
         * @constructor
         * @param {sso.IAddress=} [properties] Properties to set
         */
        function Address(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Address id.
         * @member {string} id
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.id = "";

        /**
         * Address label.
         * @member {string} label
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.label = "";

        /**
         * Address value.
         * @member {string} value
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.value = "";

        /**
         * Address unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.unrestrictedValue = "";

        /**
         * Address object.
         * @member {string} object
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.object = "";

        /**
         * Address detail.
         * @member {sso.IAddressDetail|null|undefined} detail
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.detail = null;

        /**
         * Address coordinates.
         * @member {sso.ICoordinates|null|undefined} coordinates
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.coordinates = null;

        /**
         * Address metro.
         * @member {Array.<sso.IMetro>} metro
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.metro = $util.emptyArray;

        /**
         * Address type.
         * @member {sso.ADDRESS_TYPE} type
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.type = 0;

        /**
         * Encodes the specified Address message. Does not implicitly {@link sso.Address.verify|verify} messages.
         * @function encode
         * @memberof sso.Address
         * @static
         * @param {sso.IAddress} message Address message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Address.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.unrestrictedValue);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.object);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                $root.sso.AddressDetail.encode(message.detail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.sso.Coordinates.encode(message.coordinates, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.sso.Metro.encode(message.metro[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.type);
            return writer;
        };

        /**
         * Decodes an Address message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Address
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Address} Address
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Address.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Address();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.unrestrictedValue = reader.string();
                    break;
                case 5:
                    message.object = reader.string();
                    break;
                case 6:
                    message.detail = $root.sso.AddressDetail.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.coordinates = $root.sso.Coordinates.decode(reader, reader.uint32());
                    break;
                case 8:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.sso.Metro.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Address;
    })();

    sso.AddressRes = (function() {

        /**
         * Properties of an AddressRes.
         * @memberof sso
         * @interface IAddressRes
         * @property {string|null} [id] AddressRes id
         * @property {string|null} [label] AddressRes label
         * @property {string|null} [value] AddressRes value
         * @property {string|null} [unrestrictedValue] AddressRes unrestrictedValue
         * @property {string|null} [object] AddressRes object
         * @property {sso.IAddressDetail|null} [detail] AddressRes detail
         * @property {sso.ICoordinates|null} [coordinates] AddressRes coordinates
         * @property {Array.<sso.IMetro>|null} [metro] AddressRes metro
         * @property {string|null} [type] AddressRes type
         */

        /**
         * Constructs a new AddressRes.
         * @memberof sso
         * @classdesc Represents an AddressRes.
         * @implements IAddressRes
         * @constructor
         * @param {sso.IAddressRes=} [properties] Properties to set
         */
        function AddressRes(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressRes id.
         * @member {string} id
         * @memberof sso.AddressRes
         * @instance
         */
        AddressRes.prototype.id = "";

        /**
         * AddressRes label.
         * @member {string} label
         * @memberof sso.AddressRes
         * @instance
         */
        AddressRes.prototype.label = "";

        /**
         * AddressRes value.
         * @member {string} value
         * @memberof sso.AddressRes
         * @instance
         */
        AddressRes.prototype.value = "";

        /**
         * AddressRes unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof sso.AddressRes
         * @instance
         */
        AddressRes.prototype.unrestrictedValue = "";

        /**
         * AddressRes object.
         * @member {string} object
         * @memberof sso.AddressRes
         * @instance
         */
        AddressRes.prototype.object = "";

        /**
         * AddressRes detail.
         * @member {sso.IAddressDetail|null|undefined} detail
         * @memberof sso.AddressRes
         * @instance
         */
        AddressRes.prototype.detail = null;

        /**
         * AddressRes coordinates.
         * @member {sso.ICoordinates|null|undefined} coordinates
         * @memberof sso.AddressRes
         * @instance
         */
        AddressRes.prototype.coordinates = null;

        /**
         * AddressRes metro.
         * @member {Array.<sso.IMetro>} metro
         * @memberof sso.AddressRes
         * @instance
         */
        AddressRes.prototype.metro = $util.emptyArray;

        /**
         * AddressRes type.
         * @member {string} type
         * @memberof sso.AddressRes
         * @instance
         */
        AddressRes.prototype.type = "";

        /**
         * Encodes the specified AddressRes message. Does not implicitly {@link sso.AddressRes.verify|verify} messages.
         * @function encode
         * @memberof sso.AddressRes
         * @static
         * @param {sso.IAddressRes} message AddressRes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressRes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.unrestrictedValue);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.object);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                $root.sso.AddressDetail.encode(message.detail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.sso.Coordinates.encode(message.coordinates, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.sso.Metro.encode(message.metro[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.type);
            return writer;
        };

        /**
         * Decodes an AddressRes message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddressRes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddressRes} AddressRes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressRes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddressRes();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.unrestrictedValue = reader.string();
                    break;
                case 5:
                    message.object = reader.string();
                    break;
                case 6:
                    message.detail = $root.sso.AddressDetail.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.coordinates = $root.sso.Coordinates.decode(reader, reader.uint32());
                    break;
                case 8:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.sso.Metro.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddressRes;
    })();

    sso.CreateProfileRequest = (function() {

        /**
         * Properties of a CreateProfileRequest.
         * @memberof sso
         * @interface ICreateProfileRequest
         * @property {string|null} [phone] CreateProfileRequest phone
         * @property {string|null} [email] CreateProfileRequest email
         * @property {string|null} [firstName] CreateProfileRequest firstName
         * @property {string|null} [lastName] CreateProfileRequest lastName
         * @property {string|null} [middleName] CreateProfileRequest middleName
         * @property {string|null} [birthday] CreateProfileRequest birthday
         * @property {sso.USER_TYPE|null} [userType] CreateProfileRequest userType
         */

        /**
         * Constructs a new CreateProfileRequest.
         * @memberof sso
         * @classdesc Represents a CreateProfileRequest.
         * @implements ICreateProfileRequest
         * @constructor
         * @param {sso.ICreateProfileRequest=} [properties] Properties to set
         */
        function CreateProfileRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateProfileRequest phone.
         * @member {string} phone
         * @memberof sso.CreateProfileRequest
         * @instance
         */
        CreateProfileRequest.prototype.phone = "";

        /**
         * CreateProfileRequest email.
         * @member {string} email
         * @memberof sso.CreateProfileRequest
         * @instance
         */
        CreateProfileRequest.prototype.email = "";

        /**
         * CreateProfileRequest firstName.
         * @member {string} firstName
         * @memberof sso.CreateProfileRequest
         * @instance
         */
        CreateProfileRequest.prototype.firstName = "";

        /**
         * CreateProfileRequest lastName.
         * @member {string} lastName
         * @memberof sso.CreateProfileRequest
         * @instance
         */
        CreateProfileRequest.prototype.lastName = "";

        /**
         * CreateProfileRequest middleName.
         * @member {string} middleName
         * @memberof sso.CreateProfileRequest
         * @instance
         */
        CreateProfileRequest.prototype.middleName = "";

        /**
         * CreateProfileRequest birthday.
         * @member {string} birthday
         * @memberof sso.CreateProfileRequest
         * @instance
         */
        CreateProfileRequest.prototype.birthday = "";

        /**
         * CreateProfileRequest userType.
         * @member {sso.USER_TYPE} userType
         * @memberof sso.CreateProfileRequest
         * @instance
         */
        CreateProfileRequest.prototype.userType = 0;

        /**
         * Encodes the specified CreateProfileRequest message. Does not implicitly {@link sso.CreateProfileRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.CreateProfileRequest
         * @static
         * @param {sso.ICreateProfileRequest} message CreateProfileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateProfileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.middleName);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.birthday);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.userType);
            return writer;
        };

        /**
         * Decodes a CreateProfileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.CreateProfileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.CreateProfileRequest} CreateProfileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateProfileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.CreateProfileRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.phone = reader.string();
                    break;
                case 2:
                    message.email = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 5:
                    message.middleName = reader.string();
                    break;
                case 6:
                    message.birthday = reader.string();
                    break;
                case 7:
                    message.userType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateProfileRequest;
    })();

    sso.CreateProfileResponse = (function() {

        /**
         * Properties of a CreateProfileResponse.
         * @memberof sso
         * @interface ICreateProfileResponse
         * @property {string|null} [uid] CreateProfileResponse uid
         */

        /**
         * Constructs a new CreateProfileResponse.
         * @memberof sso
         * @classdesc Represents a CreateProfileResponse.
         * @implements ICreateProfileResponse
         * @constructor
         * @param {sso.ICreateProfileResponse=} [properties] Properties to set
         */
        function CreateProfileResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateProfileResponse uid.
         * @member {string} uid
         * @memberof sso.CreateProfileResponse
         * @instance
         */
        CreateProfileResponse.prototype.uid = "";

        /**
         * Encodes the specified CreateProfileResponse message. Does not implicitly {@link sso.CreateProfileResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.CreateProfileResponse
         * @static
         * @param {sso.ICreateProfileResponse} message CreateProfileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateProfileResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            return writer;
        };

        /**
         * Decodes a CreateProfileResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.CreateProfileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.CreateProfileResponse} CreateProfileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateProfileResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.CreateProfileResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateProfileResponse;
    })();

    sso.GetProfileRequest = (function() {

        /**
         * Properties of a GetProfileRequest.
         * @memberof sso
         * @interface IGetProfileRequest
         * @property {string|null} [uid] GetProfileRequest uid
         * @property {sso.IContact|null} [contact] GetProfileRequest contact
         * @property {sso.USER_TYPE|null} [userType] GetProfileRequest userType
         */

        /**
         * Constructs a new GetProfileRequest.
         * @memberof sso
         * @classdesc Represents a GetProfileRequest.
         * @implements IGetProfileRequest
         * @constructor
         * @param {sso.IGetProfileRequest=} [properties] Properties to set
         */
        function GetProfileRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProfileRequest uid.
         * @member {string} uid
         * @memberof sso.GetProfileRequest
         * @instance
         */
        GetProfileRequest.prototype.uid = "";

        /**
         * GetProfileRequest contact.
         * @member {sso.IContact|null|undefined} contact
         * @memberof sso.GetProfileRequest
         * @instance
         */
        GetProfileRequest.prototype.contact = null;

        /**
         * GetProfileRequest userType.
         * @member {sso.USER_TYPE} userType
         * @memberof sso.GetProfileRequest
         * @instance
         */
        GetProfileRequest.prototype.userType = 0;

        /**
         * Encodes the specified GetProfileRequest message. Does not implicitly {@link sso.GetProfileRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.GetProfileRequest
         * @static
         * @param {sso.IGetProfileRequest} message GetProfileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProfileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            if (message.contact != null && Object.hasOwnProperty.call(message, "contact"))
                $root.sso.Contact.encode(message.contact, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.userType);
            return writer;
        };

        /**
         * Decodes a GetProfileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetProfileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetProfileRequest} GetProfileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProfileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetProfileRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                case 2:
                    message.contact = $root.sso.Contact.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.userType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetProfileRequest;
    })();

    sso.GetProfileResponse = (function() {

        /**
         * Properties of a GetProfileResponse.
         * @memberof sso
         * @interface IGetProfileResponse
         * @property {string|null} [uid] GetProfileResponse uid
         * @property {string|null} [phone] GetProfileResponse phone
         * @property {string|null} [email] GetProfileResponse email
         * @property {string|null} [firstName] GetProfileResponse firstName
         * @property {string|null} [lastName] GetProfileResponse lastName
         * @property {string|null} [middleName] GetProfileResponse middleName
         * @property {string|null} [birthday] GetProfileResponse birthday
         * @property {string|null} [userType] GetProfileResponse userType
         */

        /**
         * Constructs a new GetProfileResponse.
         * @memberof sso
         * @classdesc Represents a GetProfileResponse.
         * @implements IGetProfileResponse
         * @constructor
         * @param {sso.IGetProfileResponse=} [properties] Properties to set
         */
        function GetProfileResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProfileResponse uid.
         * @member {string} uid
         * @memberof sso.GetProfileResponse
         * @instance
         */
        GetProfileResponse.prototype.uid = "";

        /**
         * GetProfileResponse phone.
         * @member {string} phone
         * @memberof sso.GetProfileResponse
         * @instance
         */
        GetProfileResponse.prototype.phone = "";

        /**
         * GetProfileResponse email.
         * @member {string} email
         * @memberof sso.GetProfileResponse
         * @instance
         */
        GetProfileResponse.prototype.email = "";

        /**
         * GetProfileResponse firstName.
         * @member {string} firstName
         * @memberof sso.GetProfileResponse
         * @instance
         */
        GetProfileResponse.prototype.firstName = "";

        /**
         * GetProfileResponse lastName.
         * @member {string} lastName
         * @memberof sso.GetProfileResponse
         * @instance
         */
        GetProfileResponse.prototype.lastName = "";

        /**
         * GetProfileResponse middleName.
         * @member {string} middleName
         * @memberof sso.GetProfileResponse
         * @instance
         */
        GetProfileResponse.prototype.middleName = "";

        /**
         * GetProfileResponse birthday.
         * @member {string} birthday
         * @memberof sso.GetProfileResponse
         * @instance
         */
        GetProfileResponse.prototype.birthday = "";

        /**
         * GetProfileResponse userType.
         * @member {string} userType
         * @memberof sso.GetProfileResponse
         * @instance
         */
        GetProfileResponse.prototype.userType = "";

        /**
         * Encodes the specified GetProfileResponse message. Does not implicitly {@link sso.GetProfileResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.GetProfileResponse
         * @static
         * @param {sso.IGetProfileResponse} message GetProfileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProfileResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.phone);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.middleName);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.birthday);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.userType);
            return writer;
        };

        /**
         * Decodes a GetProfileResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetProfileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetProfileResponse} GetProfileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProfileResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetProfileResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                case 2:
                    message.phone = reader.string();
                    break;
                case 3:
                    message.email = reader.string();
                    break;
                case 4:
                    message.firstName = reader.string();
                    break;
                case 5:
                    message.lastName = reader.string();
                    break;
                case 6:
                    message.middleName = reader.string();
                    break;
                case 7:
                    message.birthday = reader.string();
                    break;
                case 8:
                    message.userType = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetProfileResponse;
    })();

    sso.UpdateProfileRequest = (function() {

        /**
         * Properties of an UpdateProfileRequest.
         * @memberof sso
         * @interface IUpdateProfileRequest
         * @property {string|null} [uid] UpdateProfileRequest uid
         * @property {string|null} [phone] UpdateProfileRequest phone
         * @property {string|null} [email] UpdateProfileRequest email
         * @property {string|null} [firstName] UpdateProfileRequest firstName
         * @property {string|null} [lastName] UpdateProfileRequest lastName
         * @property {string|null} [middleName] UpdateProfileRequest middleName
         * @property {string|null} [birthday] UpdateProfileRequest birthday
         */

        /**
         * Constructs a new UpdateProfileRequest.
         * @memberof sso
         * @classdesc Represents an UpdateProfileRequest.
         * @implements IUpdateProfileRequest
         * @constructor
         * @param {sso.IUpdateProfileRequest=} [properties] Properties to set
         */
        function UpdateProfileRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateProfileRequest uid.
         * @member {string} uid
         * @memberof sso.UpdateProfileRequest
         * @instance
         */
        UpdateProfileRequest.prototype.uid = "";

        /**
         * UpdateProfileRequest phone.
         * @member {string} phone
         * @memberof sso.UpdateProfileRequest
         * @instance
         */
        UpdateProfileRequest.prototype.phone = "";

        /**
         * UpdateProfileRequest email.
         * @member {string} email
         * @memberof sso.UpdateProfileRequest
         * @instance
         */
        UpdateProfileRequest.prototype.email = "";

        /**
         * UpdateProfileRequest firstName.
         * @member {string} firstName
         * @memberof sso.UpdateProfileRequest
         * @instance
         */
        UpdateProfileRequest.prototype.firstName = "";

        /**
         * UpdateProfileRequest lastName.
         * @member {string} lastName
         * @memberof sso.UpdateProfileRequest
         * @instance
         */
        UpdateProfileRequest.prototype.lastName = "";

        /**
         * UpdateProfileRequest middleName.
         * @member {string} middleName
         * @memberof sso.UpdateProfileRequest
         * @instance
         */
        UpdateProfileRequest.prototype.middleName = "";

        /**
         * UpdateProfileRequest birthday.
         * @member {string} birthday
         * @memberof sso.UpdateProfileRequest
         * @instance
         */
        UpdateProfileRequest.prototype.birthday = "";

        /**
         * Encodes the specified UpdateProfileRequest message. Does not implicitly {@link sso.UpdateProfileRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.UpdateProfileRequest
         * @static
         * @param {sso.IUpdateProfileRequest} message UpdateProfileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProfileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.phone);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.middleName);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.birthday);
            return writer;
        };

        /**
         * Decodes an UpdateProfileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UpdateProfileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UpdateProfileRequest} UpdateProfileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProfileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UpdateProfileRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                case 2:
                    message.phone = reader.string();
                    break;
                case 3:
                    message.email = reader.string();
                    break;
                case 4:
                    message.firstName = reader.string();
                    break;
                case 5:
                    message.lastName = reader.string();
                    break;
                case 6:
                    message.middleName = reader.string();
                    break;
                case 7:
                    message.birthday = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateProfileRequest;
    })();

    sso.UpdateProfileResponse = (function() {

        /**
         * Properties of an UpdateProfileResponse.
         * @memberof sso
         * @interface IUpdateProfileResponse
         */

        /**
         * Constructs a new UpdateProfileResponse.
         * @memberof sso
         * @classdesc Represents an UpdateProfileResponse.
         * @implements IUpdateProfileResponse
         * @constructor
         * @param {sso.IUpdateProfileResponse=} [properties] Properties to set
         */
        function UpdateProfileResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified UpdateProfileResponse message. Does not implicitly {@link sso.UpdateProfileResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.UpdateProfileResponse
         * @static
         * @param {sso.IUpdateProfileResponse} message UpdateProfileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateProfileResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an UpdateProfileResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UpdateProfileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UpdateProfileResponse} UpdateProfileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateProfileResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UpdateProfileResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateProfileResponse;
    })();

    sso.FindOrCreateProfileRequest = (function() {

        /**
         * Properties of a FindOrCreateProfileRequest.
         * @memberof sso
         * @interface IFindOrCreateProfileRequest
         * @property {string|null} [phone] FindOrCreateProfileRequest phone
         * @property {string|null} [email] FindOrCreateProfileRequest email
         * @property {string|null} [firstName] FindOrCreateProfileRequest firstName
         * @property {string|null} [lastName] FindOrCreateProfileRequest lastName
         * @property {string|null} [middleName] FindOrCreateProfileRequest middleName
         * @property {string|null} [birthday] FindOrCreateProfileRequest birthday
         * @property {sso.USER_TYPE|null} [userType] FindOrCreateProfileRequest userType
         */

        /**
         * Constructs a new FindOrCreateProfileRequest.
         * @memberof sso
         * @classdesc Represents a FindOrCreateProfileRequest.
         * @implements IFindOrCreateProfileRequest
         * @constructor
         * @param {sso.IFindOrCreateProfileRequest=} [properties] Properties to set
         */
        function FindOrCreateProfileRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindOrCreateProfileRequest phone.
         * @member {string} phone
         * @memberof sso.FindOrCreateProfileRequest
         * @instance
         */
        FindOrCreateProfileRequest.prototype.phone = "";

        /**
         * FindOrCreateProfileRequest email.
         * @member {string} email
         * @memberof sso.FindOrCreateProfileRequest
         * @instance
         */
        FindOrCreateProfileRequest.prototype.email = "";

        /**
         * FindOrCreateProfileRequest firstName.
         * @member {string} firstName
         * @memberof sso.FindOrCreateProfileRequest
         * @instance
         */
        FindOrCreateProfileRequest.prototype.firstName = "";

        /**
         * FindOrCreateProfileRequest lastName.
         * @member {string} lastName
         * @memberof sso.FindOrCreateProfileRequest
         * @instance
         */
        FindOrCreateProfileRequest.prototype.lastName = "";

        /**
         * FindOrCreateProfileRequest middleName.
         * @member {string} middleName
         * @memberof sso.FindOrCreateProfileRequest
         * @instance
         */
        FindOrCreateProfileRequest.prototype.middleName = "";

        /**
         * FindOrCreateProfileRequest birthday.
         * @member {string} birthday
         * @memberof sso.FindOrCreateProfileRequest
         * @instance
         */
        FindOrCreateProfileRequest.prototype.birthday = "";

        /**
         * FindOrCreateProfileRequest userType.
         * @member {sso.USER_TYPE} userType
         * @memberof sso.FindOrCreateProfileRequest
         * @instance
         */
        FindOrCreateProfileRequest.prototype.userType = 0;

        /**
         * Encodes the specified FindOrCreateProfileRequest message. Does not implicitly {@link sso.FindOrCreateProfileRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.FindOrCreateProfileRequest
         * @static
         * @param {sso.IFindOrCreateProfileRequest} message FindOrCreateProfileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindOrCreateProfileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.middleName);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.birthday);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.userType);
            return writer;
        };

        /**
         * Decodes a FindOrCreateProfileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.FindOrCreateProfileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.FindOrCreateProfileRequest} FindOrCreateProfileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindOrCreateProfileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.FindOrCreateProfileRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.phone = reader.string();
                    break;
                case 2:
                    message.email = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 5:
                    message.middleName = reader.string();
                    break;
                case 6:
                    message.birthday = reader.string();
                    break;
                case 7:
                    message.userType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindOrCreateProfileRequest;
    })();

    sso.FindOrCreateProfileResponse = (function() {

        /**
         * Properties of a FindOrCreateProfileResponse.
         * @memberof sso
         * @interface IFindOrCreateProfileResponse
         * @property {string|null} [uid] FindOrCreateProfileResponse uid
         */

        /**
         * Constructs a new FindOrCreateProfileResponse.
         * @memberof sso
         * @classdesc Represents a FindOrCreateProfileResponse.
         * @implements IFindOrCreateProfileResponse
         * @constructor
         * @param {sso.IFindOrCreateProfileResponse=} [properties] Properties to set
         */
        function FindOrCreateProfileResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindOrCreateProfileResponse uid.
         * @member {string} uid
         * @memberof sso.FindOrCreateProfileResponse
         * @instance
         */
        FindOrCreateProfileResponse.prototype.uid = "";

        /**
         * Encodes the specified FindOrCreateProfileResponse message. Does not implicitly {@link sso.FindOrCreateProfileResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.FindOrCreateProfileResponse
         * @static
         * @param {sso.IFindOrCreateProfileResponse} message FindOrCreateProfileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindOrCreateProfileResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            return writer;
        };

        /**
         * Decodes a FindOrCreateProfileResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.FindOrCreateProfileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.FindOrCreateProfileResponse} FindOrCreateProfileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindOrCreateProfileResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.FindOrCreateProfileResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindOrCreateProfileResponse;
    })();

    sso.UpsertProfileRequest = (function() {

        /**
         * Properties of an UpsertProfileRequest.
         * @memberof sso
         * @interface IUpsertProfileRequest
         * @property {string|null} [phone] UpsertProfileRequest phone
         * @property {string|null} [email] UpsertProfileRequest email
         * @property {string|null} [firstName] UpsertProfileRequest firstName
         * @property {string|null} [lastName] UpsertProfileRequest lastName
         * @property {string|null} [middleName] UpsertProfileRequest middleName
         * @property {string|null} [birthday] UpsertProfileRequest birthday
         * @property {sso.USER_TYPE|null} [userType] UpsertProfileRequest userType
         */

        /**
         * Constructs a new UpsertProfileRequest.
         * @memberof sso
         * @classdesc Represents an UpsertProfileRequest.
         * @implements IUpsertProfileRequest
         * @constructor
         * @param {sso.IUpsertProfileRequest=} [properties] Properties to set
         */
        function UpsertProfileRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProfileRequest phone.
         * @member {string} phone
         * @memberof sso.UpsertProfileRequest
         * @instance
         */
        UpsertProfileRequest.prototype.phone = "";

        /**
         * UpsertProfileRequest email.
         * @member {string} email
         * @memberof sso.UpsertProfileRequest
         * @instance
         */
        UpsertProfileRequest.prototype.email = "";

        /**
         * UpsertProfileRequest firstName.
         * @member {string} firstName
         * @memberof sso.UpsertProfileRequest
         * @instance
         */
        UpsertProfileRequest.prototype.firstName = "";

        /**
         * UpsertProfileRequest lastName.
         * @member {string} lastName
         * @memberof sso.UpsertProfileRequest
         * @instance
         */
        UpsertProfileRequest.prototype.lastName = "";

        /**
         * UpsertProfileRequest middleName.
         * @member {string} middleName
         * @memberof sso.UpsertProfileRequest
         * @instance
         */
        UpsertProfileRequest.prototype.middleName = "";

        /**
         * UpsertProfileRequest birthday.
         * @member {string} birthday
         * @memberof sso.UpsertProfileRequest
         * @instance
         */
        UpsertProfileRequest.prototype.birthday = "";

        /**
         * UpsertProfileRequest userType.
         * @member {sso.USER_TYPE} userType
         * @memberof sso.UpsertProfileRequest
         * @instance
         */
        UpsertProfileRequest.prototype.userType = 0;

        /**
         * Encodes the specified UpsertProfileRequest message. Does not implicitly {@link sso.UpsertProfileRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.UpsertProfileRequest
         * @static
         * @param {sso.IUpsertProfileRequest} message UpsertProfileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProfileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.middleName);
            if (message.birthday != null && Object.hasOwnProperty.call(message, "birthday"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.birthday);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.userType);
            return writer;
        };

        /**
         * Decodes an UpsertProfileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UpsertProfileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UpsertProfileRequest} UpsertProfileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProfileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UpsertProfileRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.phone = reader.string();
                    break;
                case 2:
                    message.email = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 5:
                    message.middleName = reader.string();
                    break;
                case 6:
                    message.birthday = reader.string();
                    break;
                case 7:
                    message.userType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpsertProfileRequest;
    })();

    sso.UpsertProfileResponse = (function() {

        /**
         * Properties of an UpsertProfileResponse.
         * @memberof sso
         * @interface IUpsertProfileResponse
         * @property {string|null} [uid] UpsertProfileResponse uid
         */

        /**
         * Constructs a new UpsertProfileResponse.
         * @memberof sso
         * @classdesc Represents an UpsertProfileResponse.
         * @implements IUpsertProfileResponse
         * @constructor
         * @param {sso.IUpsertProfileResponse=} [properties] Properties to set
         */
        function UpsertProfileResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProfileResponse uid.
         * @member {string} uid
         * @memberof sso.UpsertProfileResponse
         * @instance
         */
        UpsertProfileResponse.prototype.uid = "";

        /**
         * Encodes the specified UpsertProfileResponse message. Does not implicitly {@link sso.UpsertProfileResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.UpsertProfileResponse
         * @static
         * @param {sso.IUpsertProfileResponse} message UpsertProfileResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProfileResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            return writer;
        };

        /**
         * Decodes an UpsertProfileResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UpsertProfileResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UpsertProfileResponse} UpsertProfileResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProfileResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UpsertProfileResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpsertProfileResponse;
    })();

    sso.AddContactRequest = (function() {

        /**
         * Properties of an AddContactRequest.
         * @memberof sso
         * @interface IAddContactRequest
         * @property {string|null} [uid] AddContactRequest uid
         * @property {string|null} [value] AddContactRequest value
         * @property {sso.CONTACT_TYPE|null} [type] AddContactRequest type
         */

        /**
         * Constructs a new AddContactRequest.
         * @memberof sso
         * @classdesc Represents an AddContactRequest.
         * @implements IAddContactRequest
         * @constructor
         * @param {sso.IAddContactRequest=} [properties] Properties to set
         */
        function AddContactRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddContactRequest uid.
         * @member {string} uid
         * @memberof sso.AddContactRequest
         * @instance
         */
        AddContactRequest.prototype.uid = "";

        /**
         * AddContactRequest value.
         * @member {string} value
         * @memberof sso.AddContactRequest
         * @instance
         */
        AddContactRequest.prototype.value = "";

        /**
         * AddContactRequest type.
         * @member {sso.CONTACT_TYPE} type
         * @memberof sso.AddContactRequest
         * @instance
         */
        AddContactRequest.prototype.type = 0;

        /**
         * Encodes the specified AddContactRequest message. Does not implicitly {@link sso.AddContactRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.AddContactRequest
         * @static
         * @param {sso.IAddContactRequest} message AddContactRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddContactRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            return writer;
        };

        /**
         * Decodes an AddContactRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddContactRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddContactRequest} AddContactRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddContactRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddContactRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddContactRequest;
    })();

    sso.AddContactResponse = (function() {

        /**
         * Properties of an AddContactResponse.
         * @memberof sso
         * @interface IAddContactResponse
         */

        /**
         * Constructs a new AddContactResponse.
         * @memberof sso
         * @classdesc Represents an AddContactResponse.
         * @implements IAddContactResponse
         * @constructor
         * @param {sso.IAddContactResponse=} [properties] Properties to set
         */
        function AddContactResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified AddContactResponse message. Does not implicitly {@link sso.AddContactResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.AddContactResponse
         * @static
         * @param {sso.IAddContactResponse} message AddContactResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddContactResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an AddContactResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddContactResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddContactResponse} AddContactResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddContactResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddContactResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddContactResponse;
    })();

    sso.GetContactRequest = (function() {

        /**
         * Properties of a GetContactRequest.
         * @memberof sso
         * @interface IGetContactRequest
         * @property {string|null} [uid] GetContactRequest uid
         * @property {sso.CONTACT_TYPE|null} [type] GetContactRequest type
         */

        /**
         * Constructs a new GetContactRequest.
         * @memberof sso
         * @classdesc Represents a GetContactRequest.
         * @implements IGetContactRequest
         * @constructor
         * @param {sso.IGetContactRequest=} [properties] Properties to set
         */
        function GetContactRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetContactRequest uid.
         * @member {string} uid
         * @memberof sso.GetContactRequest
         * @instance
         */
        GetContactRequest.prototype.uid = "";

        /**
         * GetContactRequest type.
         * @member {sso.CONTACT_TYPE} type
         * @memberof sso.GetContactRequest
         * @instance
         */
        GetContactRequest.prototype.type = 0;

        /**
         * Encodes the specified GetContactRequest message. Does not implicitly {@link sso.GetContactRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.GetContactRequest
         * @static
         * @param {sso.IGetContactRequest} message GetContactRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetContactRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            return writer;
        };

        /**
         * Decodes a GetContactRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetContactRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetContactRequest} GetContactRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetContactRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetContactRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetContactRequest;
    })();

    sso.GetContactResponse = (function() {

        /**
         * Properties of a GetContactResponse.
         * @memberof sso
         * @interface IGetContactResponse
         * @property {string|null} [value] GetContactResponse value
         */

        /**
         * Constructs a new GetContactResponse.
         * @memberof sso
         * @classdesc Represents a GetContactResponse.
         * @implements IGetContactResponse
         * @constructor
         * @param {sso.IGetContactResponse=} [properties] Properties to set
         */
        function GetContactResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetContactResponse value.
         * @member {string} value
         * @memberof sso.GetContactResponse
         * @instance
         */
        GetContactResponse.prototype.value = "";

        /**
         * Encodes the specified GetContactResponse message. Does not implicitly {@link sso.GetContactResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.GetContactResponse
         * @static
         * @param {sso.IGetContactResponse} message GetContactResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetContactResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            return writer;
        };

        /**
         * Decodes a GetContactResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetContactResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetContactResponse} GetContactResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetContactResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetContactResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetContactResponse;
    })();

    sso.AddAddressRequest = (function() {

        /**
         * Properties of an AddAddressRequest.
         * @memberof sso
         * @interface IAddAddressRequest
         * @property {string|null} [uid] AddAddressRequest uid
         * @property {string|null} [label] AddAddressRequest label
         * @property {string|null} [value] AddAddressRequest value
         * @property {sso.ADDRESS_TYPE|null} [type] AddAddressRequest type
         * @property {string|null} [unrestrictedValue] AddAddressRequest unrestrictedValue
         * @property {string|null} [object] AddAddressRequest object
         * @property {sso.IAddressDetail|null} [detail] AddAddressRequest detail
         * @property {sso.ICoordinates|null} [coordinates] AddAddressRequest coordinates
         * @property {Array.<sso.IMetro>|null} [metro] AddAddressRequest metro
         * @property {string|null} [id] AddAddressRequest id
         */

        /**
         * Constructs a new AddAddressRequest.
         * @memberof sso
         * @classdesc Represents an AddAddressRequest.
         * @implements IAddAddressRequest
         * @constructor
         * @param {sso.IAddAddressRequest=} [properties] Properties to set
         */
        function AddAddressRequest(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddAddressRequest uid.
         * @member {string} uid
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.uid = "";

        /**
         * AddAddressRequest label.
         * @member {string} label
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.label = "";

        /**
         * AddAddressRequest value.
         * @member {string} value
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.value = "";

        /**
         * AddAddressRequest type.
         * @member {sso.ADDRESS_TYPE} type
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.type = 0;

        /**
         * AddAddressRequest unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.unrestrictedValue = "";

        /**
         * AddAddressRequest object.
         * @member {string} object
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.object = "";

        /**
         * AddAddressRequest detail.
         * @member {sso.IAddressDetail|null|undefined} detail
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.detail = null;

        /**
         * AddAddressRequest coordinates.
         * @member {sso.ICoordinates|null|undefined} coordinates
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.coordinates = null;

        /**
         * AddAddressRequest metro.
         * @member {Array.<sso.IMetro>} metro
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.metro = $util.emptyArray;

        /**
         * AddAddressRequest id.
         * @member {string} id
         * @memberof sso.AddAddressRequest
         * @instance
         */
        AddAddressRequest.prototype.id = "";

        /**
         * Encodes the specified AddAddressRequest message. Does not implicitly {@link sso.AddAddressRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.AddAddressRequest
         * @static
         * @param {sso.IAddAddressRequest} message AddAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddAddressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.unrestrictedValue);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.object);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                $root.sso.AddressDetail.encode(message.detail, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.sso.Coordinates.encode(message.coordinates, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.sso.Metro.encode(message.metro[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.id);
            return writer;
        };

        /**
         * Decodes an AddAddressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddAddressRequest} AddAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddAddressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddAddressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.type = reader.int32();
                    break;
                case 5:
                    message.unrestrictedValue = reader.string();
                    break;
                case 6:
                    message.object = reader.string();
                    break;
                case 7:
                    message.detail = $root.sso.AddressDetail.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.coordinates = $root.sso.Coordinates.decode(reader, reader.uint32());
                    break;
                case 9:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.sso.Metro.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddAddressRequest;
    })();

    sso.AddAddressResponse = (function() {

        /**
         * Properties of an AddAddressResponse.
         * @memberof sso
         * @interface IAddAddressResponse
         * @property {string|null} [id] AddAddressResponse id
         */

        /**
         * Constructs a new AddAddressResponse.
         * @memberof sso
         * @classdesc Represents an AddAddressResponse.
         * @implements IAddAddressResponse
         * @constructor
         * @param {sso.IAddAddressResponse=} [properties] Properties to set
         */
        function AddAddressResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddAddressResponse id.
         * @member {string} id
         * @memberof sso.AddAddressResponse
         * @instance
         */
        AddAddressResponse.prototype.id = "";

        /**
         * Encodes the specified AddAddressResponse message. Does not implicitly {@link sso.AddAddressResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.AddAddressResponse
         * @static
         * @param {sso.IAddAddressResponse} message AddAddressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddAddressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes an AddAddressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddAddressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddAddressResponse} AddAddressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddAddressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddAddressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddAddressResponse;
    })();

    sso.GetAddressesRequest = (function() {

        /**
         * Properties of a GetAddressesRequest.
         * @memberof sso
         * @interface IGetAddressesRequest
         * @property {string|null} [uid] GetAddressesRequest uid
         */

        /**
         * Constructs a new GetAddressesRequest.
         * @memberof sso
         * @classdesc Represents a GetAddressesRequest.
         * @implements IGetAddressesRequest
         * @constructor
         * @param {sso.IGetAddressesRequest=} [properties] Properties to set
         */
        function GetAddressesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAddressesRequest uid.
         * @member {string} uid
         * @memberof sso.GetAddressesRequest
         * @instance
         */
        GetAddressesRequest.prototype.uid = "";

        /**
         * Encodes the specified GetAddressesRequest message. Does not implicitly {@link sso.GetAddressesRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.GetAddressesRequest
         * @static
         * @param {sso.IGetAddressesRequest} message GetAddressesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAddressesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            return writer;
        };

        /**
         * Decodes a GetAddressesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetAddressesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetAddressesRequest} GetAddressesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAddressesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetAddressesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAddressesRequest;
    })();

    sso.GetAddressesResponse = (function() {

        /**
         * Properties of a GetAddressesResponse.
         * @memberof sso
         * @interface IGetAddressesResponse
         * @property {Array.<sso.IAddressRes>|null} [addresses] GetAddressesResponse addresses
         */

        /**
         * Constructs a new GetAddressesResponse.
         * @memberof sso
         * @classdesc Represents a GetAddressesResponse.
         * @implements IGetAddressesResponse
         * @constructor
         * @param {sso.IGetAddressesResponse=} [properties] Properties to set
         */
        function GetAddressesResponse(properties) {
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAddressesResponse addresses.
         * @member {Array.<sso.IAddressRes>} addresses
         * @memberof sso.GetAddressesResponse
         * @instance
         */
        GetAddressesResponse.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified GetAddressesResponse message. Does not implicitly {@link sso.GetAddressesResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.GetAddressesResponse
         * @static
         * @param {sso.IGetAddressesResponse} message GetAddressesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAddressesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.sso.AddressRes.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetAddressesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetAddressesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetAddressesResponse} GetAddressesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAddressesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetAddressesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.sso.AddressRes.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAddressesResponse;
    })();

    sso.GetPassportAddressesRequest = (function() {

        /**
         * Properties of a GetPassportAddressesRequest.
         * @memberof sso
         * @interface IGetPassportAddressesRequest
         * @property {string|null} [uid] GetPassportAddressesRequest uid
         */

        /**
         * Constructs a new GetPassportAddressesRequest.
         * @memberof sso
         * @classdesc Represents a GetPassportAddressesRequest.
         * @implements IGetPassportAddressesRequest
         * @constructor
         * @param {sso.IGetPassportAddressesRequest=} [properties] Properties to set
         */
        function GetPassportAddressesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPassportAddressesRequest uid.
         * @member {string} uid
         * @memberof sso.GetPassportAddressesRequest
         * @instance
         */
        GetPassportAddressesRequest.prototype.uid = "";

        /**
         * Encodes the specified GetPassportAddressesRequest message. Does not implicitly {@link sso.GetPassportAddressesRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.GetPassportAddressesRequest
         * @static
         * @param {sso.IGetPassportAddressesRequest} message GetPassportAddressesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPassportAddressesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            return writer;
        };

        /**
         * Decodes a GetPassportAddressesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetPassportAddressesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetPassportAddressesRequest} GetPassportAddressesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPassportAddressesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetPassportAddressesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPassportAddressesRequest;
    })();

    sso.GetPassportAddressesResponse = (function() {

        /**
         * Properties of a GetPassportAddressesResponse.
         * @memberof sso
         * @interface IGetPassportAddressesResponse
         * @property {Array.<sso.IAddressRes>|null} [addresses] GetPassportAddressesResponse addresses
         */

        /**
         * Constructs a new GetPassportAddressesResponse.
         * @memberof sso
         * @classdesc Represents a GetPassportAddressesResponse.
         * @implements IGetPassportAddressesResponse
         * @constructor
         * @param {sso.IGetPassportAddressesResponse=} [properties] Properties to set
         */
        function GetPassportAddressesResponse(properties) {
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPassportAddressesResponse addresses.
         * @member {Array.<sso.IAddressRes>} addresses
         * @memberof sso.GetPassportAddressesResponse
         * @instance
         */
        GetPassportAddressesResponse.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified GetPassportAddressesResponse message. Does not implicitly {@link sso.GetPassportAddressesResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.GetPassportAddressesResponse
         * @static
         * @param {sso.IGetPassportAddressesResponse} message GetPassportAddressesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPassportAddressesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.sso.AddressRes.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetPassportAddressesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetPassportAddressesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetPassportAddressesResponse} GetPassportAddressesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPassportAddressesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetPassportAddressesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.sso.AddressRes.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPassportAddressesResponse;
    })();

    sso.UpdateAddressRequest = (function() {

        /**
         * Properties of an UpdateAddressRequest.
         * @memberof sso
         * @interface IUpdateAddressRequest
         * @property {string|null} [id] UpdateAddressRequest id
         * @property {string|null} [label] UpdateAddressRequest label
         * @property {string|null} [value] UpdateAddressRequest value
         * @property {string|null} [unrestrictedValue] UpdateAddressRequest unrestrictedValue
         * @property {string|null} [object] UpdateAddressRequest object
         * @property {sso.IAddressDetail|null} [detail] UpdateAddressRequest detail
         * @property {sso.ICoordinates|null} [coordinates] UpdateAddressRequest coordinates
         * @property {Array.<sso.IMetro>|null} [metro] UpdateAddressRequest metro
         */

        /**
         * Constructs a new UpdateAddressRequest.
         * @memberof sso
         * @classdesc Represents an UpdateAddressRequest.
         * @implements IUpdateAddressRequest
         * @constructor
         * @param {sso.IUpdateAddressRequest=} [properties] Properties to set
         */
        function UpdateAddressRequest(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateAddressRequest id.
         * @member {string} id
         * @memberof sso.UpdateAddressRequest
         * @instance
         */
        UpdateAddressRequest.prototype.id = "";

        /**
         * UpdateAddressRequest label.
         * @member {string} label
         * @memberof sso.UpdateAddressRequest
         * @instance
         */
        UpdateAddressRequest.prototype.label = "";

        /**
         * UpdateAddressRequest value.
         * @member {string} value
         * @memberof sso.UpdateAddressRequest
         * @instance
         */
        UpdateAddressRequest.prototype.value = "";

        /**
         * UpdateAddressRequest unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof sso.UpdateAddressRequest
         * @instance
         */
        UpdateAddressRequest.prototype.unrestrictedValue = "";

        /**
         * UpdateAddressRequest object.
         * @member {string} object
         * @memberof sso.UpdateAddressRequest
         * @instance
         */
        UpdateAddressRequest.prototype.object = "";

        /**
         * UpdateAddressRequest detail.
         * @member {sso.IAddressDetail|null|undefined} detail
         * @memberof sso.UpdateAddressRequest
         * @instance
         */
        UpdateAddressRequest.prototype.detail = null;

        /**
         * UpdateAddressRequest coordinates.
         * @member {sso.ICoordinates|null|undefined} coordinates
         * @memberof sso.UpdateAddressRequest
         * @instance
         */
        UpdateAddressRequest.prototype.coordinates = null;

        /**
         * UpdateAddressRequest metro.
         * @member {Array.<sso.IMetro>} metro
         * @memberof sso.UpdateAddressRequest
         * @instance
         */
        UpdateAddressRequest.prototype.metro = $util.emptyArray;

        /**
         * Encodes the specified UpdateAddressRequest message. Does not implicitly {@link sso.UpdateAddressRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.UpdateAddressRequest
         * @static
         * @param {sso.IUpdateAddressRequest} message UpdateAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateAddressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.unrestrictedValue);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.object);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                $root.sso.AddressDetail.encode(message.detail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.sso.Coordinates.encode(message.coordinates, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.sso.Metro.encode(message.metro[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateAddressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UpdateAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UpdateAddressRequest} UpdateAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateAddressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UpdateAddressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.unrestrictedValue = reader.string();
                    break;
                case 5:
                    message.object = reader.string();
                    break;
                case 6:
                    message.detail = $root.sso.AddressDetail.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.coordinates = $root.sso.Coordinates.decode(reader, reader.uint32());
                    break;
                case 8:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.sso.Metro.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateAddressRequest;
    })();

    sso.UpdateAddressResponse = (function() {

        /**
         * Properties of an UpdateAddressResponse.
         * @memberof sso
         * @interface IUpdateAddressResponse
         */

        /**
         * Constructs a new UpdateAddressResponse.
         * @memberof sso
         * @classdesc Represents an UpdateAddressResponse.
         * @implements IUpdateAddressResponse
         * @constructor
         * @param {sso.IUpdateAddressResponse=} [properties] Properties to set
         */
        function UpdateAddressResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified UpdateAddressResponse message. Does not implicitly {@link sso.UpdateAddressResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.UpdateAddressResponse
         * @static
         * @param {sso.IUpdateAddressResponse} message UpdateAddressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateAddressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an UpdateAddressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UpdateAddressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UpdateAddressResponse} UpdateAddressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateAddressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UpdateAddressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateAddressResponse;
    })();

    sso.DeleteAddressRequest = (function() {

        /**
         * Properties of a DeleteAddressRequest.
         * @memberof sso
         * @interface IDeleteAddressRequest
         * @property {string|null} [id] DeleteAddressRequest id
         */

        /**
         * Constructs a new DeleteAddressRequest.
         * @memberof sso
         * @classdesc Represents a DeleteAddressRequest.
         * @implements IDeleteAddressRequest
         * @constructor
         * @param {sso.IDeleteAddressRequest=} [properties] Properties to set
         */
        function DeleteAddressRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteAddressRequest id.
         * @member {string} id
         * @memberof sso.DeleteAddressRequest
         * @instance
         */
        DeleteAddressRequest.prototype.id = "";

        /**
         * Encodes the specified DeleteAddressRequest message. Does not implicitly {@link sso.DeleteAddressRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.DeleteAddressRequest
         * @static
         * @param {sso.IDeleteAddressRequest} message DeleteAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAddressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a DeleteAddressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.DeleteAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.DeleteAddressRequest} DeleteAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAddressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.DeleteAddressRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DeleteAddressRequest;
    })();

    sso.DeleteAddressResponse = (function() {

        /**
         * Properties of a DeleteAddressResponse.
         * @memberof sso
         * @interface IDeleteAddressResponse
         */

        /**
         * Constructs a new DeleteAddressResponse.
         * @memberof sso
         * @classdesc Represents a DeleteAddressResponse.
         * @implements IDeleteAddressResponse
         * @constructor
         * @param {sso.IDeleteAddressResponse=} [properties] Properties to set
         */
        function DeleteAddressResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified DeleteAddressResponse message. Does not implicitly {@link sso.DeleteAddressResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.DeleteAddressResponse
         * @static
         * @param {sso.IDeleteAddressResponse} message DeleteAddressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAddressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a DeleteAddressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.DeleteAddressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.DeleteAddressResponse} DeleteAddressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAddressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.DeleteAddressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DeleteAddressResponse;
    })();

    sso.AddPassportRequest = (function() {

        /**
         * Properties of an AddPassportRequest.
         * @memberof sso
         * @interface IAddPassportRequest
         * @property {string|null} [uid] AddPassportRequest uid
         * @property {string|null} [firstName] AddPassportRequest firstName
         * @property {string|null} [lastName] AddPassportRequest lastName
         * @property {string|null} [middleName] AddPassportRequest middleName
         * @property {sso.GENDER_TYPE|null} [gender] AddPassportRequest gender
         * @property {string|null} [citizenship] AddPassportRequest citizenship
         * @property {string|null} [number] AddPassportRequest number
         * @property {string|null} [dateStart] AddPassportRequest dateStart
         * @property {string|null} [dateEnd] AddPassportRequest dateEnd
         * @property {string|null} [issuePlace] AddPassportRequest issuePlace
         * @property {string|null} [birthDate] AddPassportRequest birthDate
         * @property {sso.IAddress|null} [registrationAddress] AddPassportRequest registrationAddress
         * @property {sso.IAddress|null} [birthAddress] AddPassportRequest birthAddress
         */

        /**
         * Constructs a new AddPassportRequest.
         * @memberof sso
         * @classdesc Represents an AddPassportRequest.
         * @implements IAddPassportRequest
         * @constructor
         * @param {sso.IAddPassportRequest=} [properties] Properties to set
         */
        function AddPassportRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddPassportRequest uid.
         * @member {string} uid
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.uid = "";

        /**
         * AddPassportRequest firstName.
         * @member {string} firstName
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.firstName = "";

        /**
         * AddPassportRequest lastName.
         * @member {string} lastName
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.lastName = "";

        /**
         * AddPassportRequest middleName.
         * @member {string} middleName
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.middleName = "";

        /**
         * AddPassportRequest gender.
         * @member {sso.GENDER_TYPE} gender
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.gender = 0;

        /**
         * AddPassportRequest citizenship.
         * @member {string} citizenship
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.citizenship = "";

        /**
         * AddPassportRequest number.
         * @member {string} number
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.number = "";

        /**
         * AddPassportRequest dateStart.
         * @member {string} dateStart
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.dateStart = "";

        /**
         * AddPassportRequest dateEnd.
         * @member {string} dateEnd
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.dateEnd = "";

        /**
         * AddPassportRequest issuePlace.
         * @member {string} issuePlace
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.issuePlace = "";

        /**
         * AddPassportRequest birthDate.
         * @member {string} birthDate
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.birthDate = "";

        /**
         * AddPassportRequest registrationAddress.
         * @member {sso.IAddress|null|undefined} registrationAddress
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.registrationAddress = null;

        /**
         * AddPassportRequest birthAddress.
         * @member {sso.IAddress|null|undefined} birthAddress
         * @memberof sso.AddPassportRequest
         * @instance
         */
        AddPassportRequest.prototype.birthAddress = null;

        /**
         * Encodes the specified AddPassportRequest message. Does not implicitly {@link sso.AddPassportRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.AddPassportRequest
         * @static
         * @param {sso.IAddPassportRequest} message AddPassportRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPassportRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.middleName);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.gender);
            if (message.citizenship != null && Object.hasOwnProperty.call(message, "citizenship"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.citizenship);
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.number);
            if (message.dateStart != null && Object.hasOwnProperty.call(message, "dateStart"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.dateStart);
            if (message.dateEnd != null && Object.hasOwnProperty.call(message, "dateEnd"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.dateEnd);
            if (message.issuePlace != null && Object.hasOwnProperty.call(message, "issuePlace"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.issuePlace);
            if (message.birthDate != null && Object.hasOwnProperty.call(message, "birthDate"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.birthDate);
            if (message.registrationAddress != null && Object.hasOwnProperty.call(message, "registrationAddress"))
                $root.sso.Address.encode(message.registrationAddress, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.birthAddress != null && Object.hasOwnProperty.call(message, "birthAddress"))
                $root.sso.Address.encode(message.birthAddress, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AddPassportRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddPassportRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddPassportRequest} AddPassportRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPassportRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddPassportRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                case 2:
                    message.firstName = reader.string();
                    break;
                case 3:
                    message.lastName = reader.string();
                    break;
                case 4:
                    message.middleName = reader.string();
                    break;
                case 5:
                    message.gender = reader.int32();
                    break;
                case 6:
                    message.citizenship = reader.string();
                    break;
                case 7:
                    message.number = reader.string();
                    break;
                case 8:
                    message.dateStart = reader.string();
                    break;
                case 9:
                    message.dateEnd = reader.string();
                    break;
                case 10:
                    message.issuePlace = reader.string();
                    break;
                case 11:
                    message.birthDate = reader.string();
                    break;
                case 12:
                    message.registrationAddress = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.birthAddress = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddPassportRequest;
    })();

    sso.AddPassportResponse = (function() {

        /**
         * Properties of an AddPassportResponse.
         * @memberof sso
         * @interface IAddPassportResponse
         */

        /**
         * Constructs a new AddPassportResponse.
         * @memberof sso
         * @classdesc Represents an AddPassportResponse.
         * @implements IAddPassportResponse
         * @constructor
         * @param {sso.IAddPassportResponse=} [properties] Properties to set
         */
        function AddPassportResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified AddPassportResponse message. Does not implicitly {@link sso.AddPassportResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.AddPassportResponse
         * @static
         * @param {sso.IAddPassportResponse} message AddPassportResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPassportResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an AddPassportResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddPassportResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddPassportResponse} AddPassportResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPassportResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddPassportResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddPassportResponse;
    })();

    sso.GetPassportRequest = (function() {

        /**
         * Properties of a GetPassportRequest.
         * @memberof sso
         * @interface IGetPassportRequest
         * @property {string|null} [uid] GetPassportRequest uid
         */

        /**
         * Constructs a new GetPassportRequest.
         * @memberof sso
         * @classdesc Represents a GetPassportRequest.
         * @implements IGetPassportRequest
         * @constructor
         * @param {sso.IGetPassportRequest=} [properties] Properties to set
         */
        function GetPassportRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPassportRequest uid.
         * @member {string} uid
         * @memberof sso.GetPassportRequest
         * @instance
         */
        GetPassportRequest.prototype.uid = "";

        /**
         * Encodes the specified GetPassportRequest message. Does not implicitly {@link sso.GetPassportRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.GetPassportRequest
         * @static
         * @param {sso.IGetPassportRequest} message GetPassportRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPassportRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            return writer;
        };

        /**
         * Decodes a GetPassportRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetPassportRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetPassportRequest} GetPassportRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPassportRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetPassportRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPassportRequest;
    })();

    sso.GetPassportResponse = (function() {

        /**
         * Properties of a GetPassportResponse.
         * @memberof sso
         * @interface IGetPassportResponse
         * @property {string|null} [firstName] GetPassportResponse firstName
         * @property {string|null} [lastName] GetPassportResponse lastName
         * @property {string|null} [middleName] GetPassportResponse middleName
         * @property {string|null} [gender] GetPassportResponse gender
         * @property {string|null} [citizenship] GetPassportResponse citizenship
         * @property {string|null} [number] GetPassportResponse number
         * @property {string|null} [dateStart] GetPassportResponse dateStart
         * @property {string|null} [dateEnd] GetPassportResponse dateEnd
         * @property {string|null} [issuePlace] GetPassportResponse issuePlace
         * @property {string|null} [birthDate] GetPassportResponse birthDate
         * @property {sso.IAddressRes|null} [registrationAddress] GetPassportResponse registrationAddress
         * @property {sso.IAddressRes|null} [birthAddress] GetPassportResponse birthAddress
         */

        /**
         * Constructs a new GetPassportResponse.
         * @memberof sso
         * @classdesc Represents a GetPassportResponse.
         * @implements IGetPassportResponse
         * @constructor
         * @param {sso.IGetPassportResponse=} [properties] Properties to set
         */
        function GetPassportResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPassportResponse firstName.
         * @member {string} firstName
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.firstName = "";

        /**
         * GetPassportResponse lastName.
         * @member {string} lastName
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.lastName = "";

        /**
         * GetPassportResponse middleName.
         * @member {string} middleName
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.middleName = "";

        /**
         * GetPassportResponse gender.
         * @member {string} gender
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.gender = "";

        /**
         * GetPassportResponse citizenship.
         * @member {string} citizenship
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.citizenship = "";

        /**
         * GetPassportResponse number.
         * @member {string} number
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.number = "";

        /**
         * GetPassportResponse dateStart.
         * @member {string} dateStart
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.dateStart = "";

        /**
         * GetPassportResponse dateEnd.
         * @member {string} dateEnd
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.dateEnd = "";

        /**
         * GetPassportResponse issuePlace.
         * @member {string} issuePlace
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.issuePlace = "";

        /**
         * GetPassportResponse birthDate.
         * @member {string} birthDate
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.birthDate = "";

        /**
         * GetPassportResponse registrationAddress.
         * @member {sso.IAddressRes|null|undefined} registrationAddress
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.registrationAddress = null;

        /**
         * GetPassportResponse birthAddress.
         * @member {sso.IAddressRes|null|undefined} birthAddress
         * @memberof sso.GetPassportResponse
         * @instance
         */
        GetPassportResponse.prototype.birthAddress = null;

        /**
         * Encodes the specified GetPassportResponse message. Does not implicitly {@link sso.GetPassportResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.GetPassportResponse
         * @static
         * @param {sso.IGetPassportResponse} message GetPassportResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPassportResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.middleName);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.gender);
            if (message.citizenship != null && Object.hasOwnProperty.call(message, "citizenship"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.citizenship);
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.number);
            if (message.dateStart != null && Object.hasOwnProperty.call(message, "dateStart"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.dateStart);
            if (message.dateEnd != null && Object.hasOwnProperty.call(message, "dateEnd"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.dateEnd);
            if (message.issuePlace != null && Object.hasOwnProperty.call(message, "issuePlace"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.issuePlace);
            if (message.birthDate != null && Object.hasOwnProperty.call(message, "birthDate"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.birthDate);
            if (message.registrationAddress != null && Object.hasOwnProperty.call(message, "registrationAddress"))
                $root.sso.AddressRes.encode(message.registrationAddress, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.birthAddress != null && Object.hasOwnProperty.call(message, "birthAddress"))
                $root.sso.AddressRes.encode(message.birthAddress, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetPassportResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetPassportResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetPassportResponse} GetPassportResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPassportResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetPassportResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.firstName = reader.string();
                    break;
                case 2:
                    message.lastName = reader.string();
                    break;
                case 3:
                    message.middleName = reader.string();
                    break;
                case 4:
                    message.gender = reader.string();
                    break;
                case 5:
                    message.citizenship = reader.string();
                    break;
                case 6:
                    message.number = reader.string();
                    break;
                case 7:
                    message.dateStart = reader.string();
                    break;
                case 8:
                    message.dateEnd = reader.string();
                    break;
                case 9:
                    message.issuePlace = reader.string();
                    break;
                case 10:
                    message.birthDate = reader.string();
                    break;
                case 11:
                    message.registrationAddress = $root.sso.AddressRes.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.birthAddress = $root.sso.AddressRes.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPassportResponse;
    })();

    sso.Photo = (function() {

        /**
         * Properties of a Photo.
         * @memberof sso
         * @interface IPhoto
         * @property {string|null} [id] Photo id
         * @property {string|null} [tag] Photo tag
         * @property {string|null} [ownerId] Photo ownerId
         * @property {string|null} [original] Photo original
         * @property {string|null} [name] Photo name
         * @property {string|null} [url] Photo url
         * @property {string|null} [thumb] Photo thumb
         */

        /**
         * Constructs a new Photo.
         * @memberof sso
         * @classdesc Represents a Photo.
         * @implements IPhoto
         * @constructor
         * @param {sso.IPhoto=} [properties] Properties to set
         */
        function Photo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Photo id.
         * @member {string} id
         * @memberof sso.Photo
         * @instance
         */
        Photo.prototype.id = "";

        /**
         * Photo tag.
         * @member {string} tag
         * @memberof sso.Photo
         * @instance
         */
        Photo.prototype.tag = "";

        /**
         * Photo ownerId.
         * @member {string} ownerId
         * @memberof sso.Photo
         * @instance
         */
        Photo.prototype.ownerId = "";

        /**
         * Photo original.
         * @member {string} original
         * @memberof sso.Photo
         * @instance
         */
        Photo.prototype.original = "";

        /**
         * Photo name.
         * @member {string} name
         * @memberof sso.Photo
         * @instance
         */
        Photo.prototype.name = "";

        /**
         * Photo url.
         * @member {string} url
         * @memberof sso.Photo
         * @instance
         */
        Photo.prototype.url = "";

        /**
         * Photo thumb.
         * @member {string} thumb
         * @memberof sso.Photo
         * @instance
         */
        Photo.prototype.thumb = "";

        /**
         * Encodes the specified Photo message. Does not implicitly {@link sso.Photo.verify|verify} messages.
         * @function encode
         * @memberof sso.Photo
         * @static
         * @param {sso.IPhoto} message Photo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Photo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tag);
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ownerId);
            if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.original);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
            if (message.thumb != null && Object.hasOwnProperty.call(message, "thumb"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.thumb);
            return writer;
        };

        /**
         * Decodes a Photo message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Photo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Photo} Photo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Photo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Photo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.tag = reader.string();
                    break;
                case 3:
                    message.ownerId = reader.string();
                    break;
                case 4:
                    message.original = reader.string();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.url = reader.string();
                    break;
                case 7:
                    message.thumb = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Photo;
    })();

    sso.AddPhotoRequest = (function() {

        /**
         * Properties of an AddPhotoRequest.
         * @memberof sso
         * @interface IAddPhotoRequest
         * @property {string|null} [tag] AddPhotoRequest tag
         * @property {string|null} [ownerId] AddPhotoRequest ownerId
         * @property {string|null} [original] AddPhotoRequest original
         * @property {string|null} [name] AddPhotoRequest name
         * @property {string|null} [url] AddPhotoRequest url
         * @property {string|null} [thumb] AddPhotoRequest thumb
         */

        /**
         * Constructs a new AddPhotoRequest.
         * @memberof sso
         * @classdesc Represents an AddPhotoRequest.
         * @implements IAddPhotoRequest
         * @constructor
         * @param {sso.IAddPhotoRequest=} [properties] Properties to set
         */
        function AddPhotoRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddPhotoRequest tag.
         * @member {string} tag
         * @memberof sso.AddPhotoRequest
         * @instance
         */
        AddPhotoRequest.prototype.tag = "";

        /**
         * AddPhotoRequest ownerId.
         * @member {string} ownerId
         * @memberof sso.AddPhotoRequest
         * @instance
         */
        AddPhotoRequest.prototype.ownerId = "";

        /**
         * AddPhotoRequest original.
         * @member {string} original
         * @memberof sso.AddPhotoRequest
         * @instance
         */
        AddPhotoRequest.prototype.original = "";

        /**
         * AddPhotoRequest name.
         * @member {string} name
         * @memberof sso.AddPhotoRequest
         * @instance
         */
        AddPhotoRequest.prototype.name = "";

        /**
         * AddPhotoRequest url.
         * @member {string} url
         * @memberof sso.AddPhotoRequest
         * @instance
         */
        AddPhotoRequest.prototype.url = "";

        /**
         * AddPhotoRequest thumb.
         * @member {string} thumb
         * @memberof sso.AddPhotoRequest
         * @instance
         */
        AddPhotoRequest.prototype.thumb = "";

        /**
         * Encodes the specified AddPhotoRequest message. Does not implicitly {@link sso.AddPhotoRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.AddPhotoRequest
         * @static
         * @param {sso.IAddPhotoRequest} message AddPhotoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPhotoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tag);
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ownerId);
            if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.original);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.url);
            if (message.thumb != null && Object.hasOwnProperty.call(message, "thumb"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.thumb);
            return writer;
        };

        /**
         * Decodes an AddPhotoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddPhotoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddPhotoRequest} AddPhotoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPhotoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddPhotoRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tag = reader.string();
                    break;
                case 2:
                    message.ownerId = reader.string();
                    break;
                case 3:
                    message.original = reader.string();
                    break;
                case 4:
                    message.name = reader.string();
                    break;
                case 5:
                    message.url = reader.string();
                    break;
                case 6:
                    message.thumb = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddPhotoRequest;
    })();

    sso.AddPhotoResponse = (function() {

        /**
         * Properties of an AddPhotoResponse.
         * @memberof sso
         * @interface IAddPhotoResponse
         * @property {string|null} [id] AddPhotoResponse id
         */

        /**
         * Constructs a new AddPhotoResponse.
         * @memberof sso
         * @classdesc Represents an AddPhotoResponse.
         * @implements IAddPhotoResponse
         * @constructor
         * @param {sso.IAddPhotoResponse=} [properties] Properties to set
         */
        function AddPhotoResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddPhotoResponse id.
         * @member {string} id
         * @memberof sso.AddPhotoResponse
         * @instance
         */
        AddPhotoResponse.prototype.id = "";

        /**
         * Encodes the specified AddPhotoResponse message. Does not implicitly {@link sso.AddPhotoResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.AddPhotoResponse
         * @static
         * @param {sso.IAddPhotoResponse} message AddPhotoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPhotoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes an AddPhotoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddPhotoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddPhotoResponse} AddPhotoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPhotoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddPhotoResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddPhotoResponse;
    })();

    sso.GetPhotoRequest = (function() {

        /**
         * Properties of a GetPhotoRequest.
         * @memberof sso
         * @interface IGetPhotoRequest
         * @property {string|null} [ownerId] GetPhotoRequest ownerId
         * @property {string|null} [id] GetPhotoRequest id
         */

        /**
         * Constructs a new GetPhotoRequest.
         * @memberof sso
         * @classdesc Represents a GetPhotoRequest.
         * @implements IGetPhotoRequest
         * @constructor
         * @param {sso.IGetPhotoRequest=} [properties] Properties to set
         */
        function GetPhotoRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPhotoRequest ownerId.
         * @member {string} ownerId
         * @memberof sso.GetPhotoRequest
         * @instance
         */
        GetPhotoRequest.prototype.ownerId = "";

        /**
         * GetPhotoRequest id.
         * @member {string} id
         * @memberof sso.GetPhotoRequest
         * @instance
         */
        GetPhotoRequest.prototype.id = "";

        /**
         * Encodes the specified GetPhotoRequest message. Does not implicitly {@link sso.GetPhotoRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.GetPhotoRequest
         * @static
         * @param {sso.IGetPhotoRequest} message GetPhotoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPhotoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ownerId);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            return writer;
        };

        /**
         * Decodes a GetPhotoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetPhotoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetPhotoRequest} GetPhotoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPhotoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetPhotoRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ownerId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPhotoRequest;
    })();

    sso.GetPhotoResponse = (function() {

        /**
         * Properties of a GetPhotoResponse.
         * @memberof sso
         * @interface IGetPhotoResponse
         * @property {Array.<sso.IPhoto>|null} [photos] GetPhotoResponse photos
         */

        /**
         * Constructs a new GetPhotoResponse.
         * @memberof sso
         * @classdesc Represents a GetPhotoResponse.
         * @implements IGetPhotoResponse
         * @constructor
         * @param {sso.IGetPhotoResponse=} [properties] Properties to set
         */
        function GetPhotoResponse(properties) {
            this.photos = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPhotoResponse photos.
         * @member {Array.<sso.IPhoto>} photos
         * @memberof sso.GetPhotoResponse
         * @instance
         */
        GetPhotoResponse.prototype.photos = $util.emptyArray;

        /**
         * Encodes the specified GetPhotoResponse message. Does not implicitly {@link sso.GetPhotoResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.GetPhotoResponse
         * @static
         * @param {sso.IGetPhotoResponse} message GetPhotoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPhotoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.photos != null && message.photos.length)
                for (var i = 0; i < message.photos.length; ++i)
                    $root.sso.Photo.encode(message.photos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetPhotoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.GetPhotoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.GetPhotoResponse} GetPhotoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPhotoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.GetPhotoResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.photos && message.photos.length))
                        message.photos = [];
                    message.photos.push($root.sso.Photo.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPhotoResponse;
    })();

    sso.UpdatePhotoRequest = (function() {

        /**
         * Properties of an UpdatePhotoRequest.
         * @memberof sso
         * @interface IUpdatePhotoRequest
         * @property {string|null} [id] UpdatePhotoRequest id
         * @property {string|null} [tag] UpdatePhotoRequest tag
         * @property {string|null} [ownerId] UpdatePhotoRequest ownerId
         * @property {string|null} [original] UpdatePhotoRequest original
         * @property {string|null} [name] UpdatePhotoRequest name
         * @property {string|null} [url] UpdatePhotoRequest url
         * @property {string|null} [thumb] UpdatePhotoRequest thumb
         */

        /**
         * Constructs a new UpdatePhotoRequest.
         * @memberof sso
         * @classdesc Represents an UpdatePhotoRequest.
         * @implements IUpdatePhotoRequest
         * @constructor
         * @param {sso.IUpdatePhotoRequest=} [properties] Properties to set
         */
        function UpdatePhotoRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePhotoRequest id.
         * @member {string} id
         * @memberof sso.UpdatePhotoRequest
         * @instance
         */
        UpdatePhotoRequest.prototype.id = "";

        /**
         * UpdatePhotoRequest tag.
         * @member {string} tag
         * @memberof sso.UpdatePhotoRequest
         * @instance
         */
        UpdatePhotoRequest.prototype.tag = "";

        /**
         * UpdatePhotoRequest ownerId.
         * @member {string} ownerId
         * @memberof sso.UpdatePhotoRequest
         * @instance
         */
        UpdatePhotoRequest.prototype.ownerId = "";

        /**
         * UpdatePhotoRequest original.
         * @member {string} original
         * @memberof sso.UpdatePhotoRequest
         * @instance
         */
        UpdatePhotoRequest.prototype.original = "";

        /**
         * UpdatePhotoRequest name.
         * @member {string} name
         * @memberof sso.UpdatePhotoRequest
         * @instance
         */
        UpdatePhotoRequest.prototype.name = "";

        /**
         * UpdatePhotoRequest url.
         * @member {string} url
         * @memberof sso.UpdatePhotoRequest
         * @instance
         */
        UpdatePhotoRequest.prototype.url = "";

        /**
         * UpdatePhotoRequest thumb.
         * @member {string} thumb
         * @memberof sso.UpdatePhotoRequest
         * @instance
         */
        UpdatePhotoRequest.prototype.thumb = "";

        /**
         * Encodes the specified UpdatePhotoRequest message. Does not implicitly {@link sso.UpdatePhotoRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.UpdatePhotoRequest
         * @static
         * @param {sso.IUpdatePhotoRequest} message UpdatePhotoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePhotoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tag);
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ownerId);
            if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.original);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
            if (message.thumb != null && Object.hasOwnProperty.call(message, "thumb"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.thumb);
            return writer;
        };

        /**
         * Decodes an UpdatePhotoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UpdatePhotoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UpdatePhotoRequest} UpdatePhotoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePhotoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UpdatePhotoRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.tag = reader.string();
                    break;
                case 3:
                    message.ownerId = reader.string();
                    break;
                case 4:
                    message.original = reader.string();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.url = reader.string();
                    break;
                case 7:
                    message.thumb = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdatePhotoRequest;
    })();

    sso.UpdatePhotoResponse = (function() {

        /**
         * Properties of an UpdatePhotoResponse.
         * @memberof sso
         * @interface IUpdatePhotoResponse
         */

        /**
         * Constructs a new UpdatePhotoResponse.
         * @memberof sso
         * @classdesc Represents an UpdatePhotoResponse.
         * @implements IUpdatePhotoResponse
         * @constructor
         * @param {sso.IUpdatePhotoResponse=} [properties] Properties to set
         */
        function UpdatePhotoResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified UpdatePhotoResponse message. Does not implicitly {@link sso.UpdatePhotoResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.UpdatePhotoResponse
         * @static
         * @param {sso.IUpdatePhotoResponse} message UpdatePhotoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePhotoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an UpdatePhotoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UpdatePhotoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UpdatePhotoResponse} UpdatePhotoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePhotoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UpdatePhotoResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdatePhotoResponse;
    })();

    sso.DeletePhotoRequest = (function() {

        /**
         * Properties of a DeletePhotoRequest.
         * @memberof sso
         * @interface IDeletePhotoRequest
         * @property {string|null} [ownerId] DeletePhotoRequest ownerId
         * @property {string|null} [id] DeletePhotoRequest id
         */

        /**
         * Constructs a new DeletePhotoRequest.
         * @memberof sso
         * @classdesc Represents a DeletePhotoRequest.
         * @implements IDeletePhotoRequest
         * @constructor
         * @param {sso.IDeletePhotoRequest=} [properties] Properties to set
         */
        function DeletePhotoRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeletePhotoRequest ownerId.
         * @member {string} ownerId
         * @memberof sso.DeletePhotoRequest
         * @instance
         */
        DeletePhotoRequest.prototype.ownerId = "";

        /**
         * DeletePhotoRequest id.
         * @member {string} id
         * @memberof sso.DeletePhotoRequest
         * @instance
         */
        DeletePhotoRequest.prototype.id = "";

        /**
         * Encodes the specified DeletePhotoRequest message. Does not implicitly {@link sso.DeletePhotoRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.DeletePhotoRequest
         * @static
         * @param {sso.IDeletePhotoRequest} message DeletePhotoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePhotoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ownerId);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.id);
            return writer;
        };

        /**
         * Decodes a DeletePhotoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.DeletePhotoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.DeletePhotoRequest} DeletePhotoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePhotoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.DeletePhotoRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.ownerId = reader.string();
                    break;
                case 2:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DeletePhotoRequest;
    })();

    sso.DeletePhotoResponse = (function() {

        /**
         * Properties of a DeletePhotoResponse.
         * @memberof sso
         * @interface IDeletePhotoResponse
         */

        /**
         * Constructs a new DeletePhotoResponse.
         * @memberof sso
         * @classdesc Represents a DeletePhotoResponse.
         * @implements IDeletePhotoResponse
         * @constructor
         * @param {sso.IDeletePhotoResponse=} [properties] Properties to set
         */
        function DeletePhotoResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified DeletePhotoResponse message. Does not implicitly {@link sso.DeletePhotoResponse.verify|verify} messages.
         * @function encode
         * @memberof sso.DeletePhotoResponse
         * @static
         * @param {sso.IDeletePhotoResponse} message DeletePhotoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePhotoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a DeletePhotoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof sso.DeletePhotoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.DeletePhotoResponse} DeletePhotoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePhotoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.DeletePhotoResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DeletePhotoResponse;
    })();

    /**
     * APP_TYPE enum.
     * @name sso.APP_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_APP_TYPE=0 UNDEFINED_APP_TYPE value
     * @property {number} NATIVE=1 NATIVE value
     * @property {number} SPA=2 SPA value
     * @property {number} REGULAR=3 REGULAR value
     * @property {number} MACHINE=4 MACHINE value
     * @property {number} DEFAULT_APP_TYPE=5 DEFAULT_APP_TYPE value
     */
    sso.APP_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_APP_TYPE"] = 0;
        values[valuesById[1] = "NATIVE"] = 1;
        values[valuesById[2] = "SPA"] = 2;
        values[valuesById[3] = "REGULAR"] = 3;
        values[valuesById[4] = "MACHINE"] = 4;
        values[valuesById[5] = "DEFAULT_APP_TYPE"] = 5;
        return values;
    })();

    /**
     * SEND enum.
     * @name sso.SEND
     * @enum {number}
     * @property {number} UNDEFINED_SEND=0 UNDEFINED_SEND value
     * @property {number} CODE=1 CODE value
     */
    sso.SEND = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_SEND"] = 0;
        values[valuesById[1] = "CODE"] = 1;
        return values;
    })();

    /**
     * USER_TYPE enum.
     * @name sso.USER_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_USER_TYPE=0 UNDEFINED_USER_TYPE value
     * @property {number} CUSTOMER=1 CUSTOMER value
     * @property {number} CONTRACTOR=2 CONTRACTOR value
     * @property {number} EMPLOYEE=3 EMPLOYEE value
     * @property {number} PARTNER=4 PARTNER value
     */
    sso.USER_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_USER_TYPE"] = 0;
        values[valuesById[1] = "CUSTOMER"] = 1;
        values[valuesById[2] = "CONTRACTOR"] = 2;
        values[valuesById[3] = "EMPLOYEE"] = 3;
        values[valuesById[4] = "PARTNER"] = 4;
        return values;
    })();

    /**
     * CONNECTION enum.
     * @name sso.CONNECTION
     * @enum {number}
     * @property {number} UNDEFINED_CONNECTION=0 UNDEFINED_CONNECTION value
     * @property {number} CREDENTIAL=1 CREDENTIAL value
     * @property {number} M2M=2 M2M value
     * @property {number} PASSWORDLESS=3 PASSWORDLESS value
     * @property {number} YCLIENTS=4 YCLIENTS value
     * @property {number} DEVINOTELE=5 DEVINOTELE value
     */
    sso.CONNECTION = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_CONNECTION"] = 0;
        values[valuesById[1] = "CREDENTIAL"] = 1;
        values[valuesById[2] = "M2M"] = 2;
        values[valuesById[3] = "PASSWORDLESS"] = 3;
        values[valuesById[4] = "YCLIENTS"] = 4;
        values[valuesById[5] = "DEVINOTELE"] = 5;
        return values;
    })();

    /**
     * CONTACT_TYPE enum.
     * @name sso.CONTACT_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_CONTACT_TYPE=0 UNDEFINED_CONTACT_TYPE value
     * @property {number} MOBILE=1 MOBILE value
     * @property {number} EMAIL=2 EMAIL value
     */
    sso.CONTACT_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_CONTACT_TYPE"] = 0;
        values[valuesById[1] = "MOBILE"] = 1;
        values[valuesById[2] = "EMAIL"] = 2;
        return values;
    })();

    /**
     * ADDRESS_TYPE enum.
     * @name sso.ADDRESS_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_ADDRESS_TYPE=0 UNDEFINED_ADDRESS_TYPE value
     * @property {number} DEFAULT=1 DEFAULT value
     * @property {number} BIRTH_ADDRESS=2 BIRTH_ADDRESS value
     * @property {number} REG_ADDRESS=3 REG_ADDRESS value
     * @property {number} RESIDENCE_ADDRESS=4 RESIDENCE_ADDRESS value
     */
    sso.ADDRESS_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_ADDRESS_TYPE"] = 0;
        values[valuesById[1] = "DEFAULT"] = 1;
        values[valuesById[2] = "BIRTH_ADDRESS"] = 2;
        values[valuesById[3] = "REG_ADDRESS"] = 3;
        values[valuesById[4] = "RESIDENCE_ADDRESS"] = 4;
        return values;
    })();

    /**
     * GENDER_TYPE enum.
     * @name sso.GENDER_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_GENDER_TYPE=0 UNDEFINED_GENDER_TYPE value
     * @property {number} MALE=1 MALE value
     * @property {number} FEMALE=2 FEMALE value
     */
    sso.GENDER_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_GENDER_TYPE"] = 0;
        values[valuesById[1] = "MALE"] = 1;
        values[valuesById[2] = "FEMALE"] = 2;
        return values;
    })();

    sso.Empty = (function() {

        /**
         * Properties of an Empty.
         * @memberof sso
         * @interface IEmpty
         */

        /**
         * Constructs a new Empty.
         * @memberof sso
         * @classdesc Represents an Empty.
         * @implements IEmpty
         * @constructor
         * @param {sso.IEmpty=} [properties] Properties to set
         */
        function Empty(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified Empty message. Does not implicitly {@link sso.Empty.verify|verify} messages.
         * @function encode
         * @memberof sso.Empty
         * @static
         * @param {sso.IEmpty} message Empty message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Empty.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an Empty message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Empty
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Empty} Empty
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Empty.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Empty();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Empty;
    })();

    return sso;
})();

module.exports = $root;
