export const dictionary = {
  formControl: {
    id: 'ID',
    type: 'Тип',
    createdDate: 'Дата создания',
    postponedUntil: 'Дата перезвона',
    missCallsCount: 'Кол-во перезвонов',
    skill: 'Скилл',
    usp: 'Скидка',
    taskNumber: 'Номер задачи',
    createdAt: 'Дата/время создания',
    updatedAt: 'Дата последнего обновления',
    owner: 'Владелец',
    prospectNumber: 'Номер заявки',
    strategy: 'Тип',
    serviceType: 'Продукт',
    status: 'Статус',
    cancelReason: 'Причина отказа',
    orderNumber: 'Номер заказа',
    startDate: 'Дата/время',
    address: 'Адрес',
    options: 'Опции',
    totalPrice: 'Цена',
    promocode: 'Промокод',
    manager: 'Кем оформлен',
    commentForCleaner: 'Комментарий для клинера',
    commentForSupport: 'Комментарий для поддержики',
    commentFromCustomer: 'Комментарий клиента',
    review: 'Отзыв',
    score: 'Оценка',
    fio: 'ФИО',
    phone: 'Телефон',
    email: 'Эл. почта',
    totalDuration: 'Время',
    minPartPrice: 'Минимальная часть оплаты заказа',
  },
  checkControl: {
    managerState: {
      1: 'Онлайн',
      2: 'Офлайн',
    },
  },
  selectOptions: {
    serviceType: [{ id: 1, value: 'Basic' }],
    orderFinished: [
      { id: true, value: 'Выполнен' },
      { id: false, value: 'Отказ по приезде' },
    ],
    taskStatus: [
      { id: 1, value: 'Новый' },
      { id: 2, value: 'Старый' },
    ],
    orderStatus: [
      { id: 1, value: 'Новый' },
      { id: 2, value: 'В процессе' },
      { id: 3, value: 'Закончен' },
    ],
    region: [{ id: 1, value: 'Москва' }],
    regularity: [
      { id: 1, value: 'Одиночная' },
      { id: 2, value: 'Раз в месяц' },
      { id: 3, value: 'Раз в 2 недели' },
      { id: 4, value: 'Раз в неделю' },
    ],
    paymentType: [
      { id: 1, value: 'Наличными' },
      { id: 2, value: 'Банковской картой' },
    ],
    rejectionReasons: [
      { id: 1, value: 'Оформлю самостоятельно' },
      { id: 2, value: 'Не пользуюсь клинингом / убираюсь самостоятельно' },
      { id: 3, value: 'Есть свой персонал / Другой сервис' },
      { id: 4, value: 'Дорого / Маленькая скидка' },
    ],
    isDeletedOptions: [
      { value: '0', label: 'Активные' },
      { value: '1', label: 'Удалённые' },
    ],
    executorRoles: [
      { id: 0, plural: 'Клинеры - главные', singular: 'Клинер - главный' },
      { id: 3, plural: 'Клинеры - старшие', singular: 'Клинер - старший' },
      { id: 1, plural: 'Клинеры - линейные', singular: 'Клинер - линейный' },
      { id: 2, plural: 'Курьеры', singular: 'Курьер' },
    ],
  },
  groupList: {},
  isRequired: 'Обязательное поле',
}

export type TFormControl = keyof typeof dictionary['formControl']
export type TCheckControl = keyof typeof dictionary['checkControl']
export type TSelectOptions = keyof typeof dictionary['selectOptions']
export type TGroupList = keyof typeof dictionary['groupList']
