import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'
import { observer } from 'mobx-react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import OrderStore from 'src/store/OrderStore'
import OrderInfo from './OrderInfo'
import Executors from './Executors'
import loginStore from 'src/store/LoginStore'
import { ExecutorRole } from '../OrderReport/OrderReport'

interface IProps {
  setTitle: (title?: string) => void
}

interface IParams {
  orderId: string
}

const Order = observer((props: IProps) => {
  const { setTitle } = props
  const { getOrder, sendInvoice, order, executors, isLoading } = OrderStore
  const { phone } = loginStore
  const { orderId } = useParams<IParams>()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    getOrder({ orderId })
    setTitle()
  }, [])

  const ReactSwal = withReactContent(
    Swal.mixin({
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg',
        cancelButton: 'btn btn-default btn-lg',
        actions: 'text-center',
      },
    })
  )

  const onSendInvoice = () => {
    sendInvoice({ orderId }).then((res) => {
      if (res) {
        history.push(order?.id ? `/order/${order?.id}` : '/')
        ReactSwal.fire({
          title: 'Счёт успешно отправлен',
          type: 'success',
        })
      }
    })
  }

  const currentUserExecutorLink = executors.find((e) => e.phone === phone)

  const renderButtons = () => {
    if (order?.status === 'cancel') return null
    if (order?.status === 'finished')
      return (
        <>
          <Button
            variant="warning"
            className="mt-2 w-100"
            onClick={() => history.push(`${location.pathname}/report`)}
          >
            Исправить отчёт
          </Button>
          <Button disabled={isLoading} className="mt-2 w-100" onClick={onSendInvoice}>
            {isLoading ? (
              <>
                <Spinner as="span" animation="grow" role="status" aria-hidden="true" /> Загрузка...
              </>
            ) : (
              'Отправить счёт'
            )}
          </Button>
        </>
      )
    if (currentUserExecutorLink?.role === ExecutorRole.WORKER) {
      return null
    }
    return (
      <Button className="mt-2 w-100" onClick={() => history.push(`${location.pathname}/report`)}>
        Отчёт об уборке
      </Button>
    )
  }

  return order && order.id === orderId ? (
    <>
      <div className="pl-4 pr-4">
        <Row>
          <Col sm={8}>
            <OrderInfo currentExecutorLink={currentUserExecutorLink} order={order} />
          </Col>
          <Col sm={4}>
            <Executors executors={executors} />
            {renderButtons()}
          </Col>
        </Row>
      </div>
    </>
  ) : (
    <Spinner animation="grow" variant="success" />
  )
})

export default Order
