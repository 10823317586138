import { AnyObject } from 'final-form'
import { TFieldProp } from '../Fields/field.interface'
import { ValidationSchema } from 'class-validator'

export enum FIELDS {
  FIO = 'fio',
  STRING = 'string',
  BOOLEAN = 'boolean',
  PASSWORD = 'password',
  DATATIME = 'datatime',
  MASKED = 'masked',
  MOBILE_PHONE = 'mobilePhone',
  NUMBER = 'number',
  MONEY = 'money',
  // TEXT = 'text',
  HTML = 'html',
  // MARKDOWN = 'markdown',
  SELECT = 'select',
  SUGGEST = 'suggest',
  MULTI_SELECT = 'multiselect',
  MULTI_SUGGEST = 'multisuggest',
  ADDRESS = 'address',
  UPLOAD = 'upload',
  MULTI_UPLOAD = 'multiupload',
  TABLE = 'table',
  COLOR = 'color',
  TIMESLOT = 'timeslot',
  PAYMENT_TYPE = 'paymentType',
  OPTION = 'option',
}

export interface ITab {
  readonly name: string
  readonly title: string
}

export interface IFormField extends TFieldProp<any> {
  type: FIELDS
  tab?: ITab['name']
  validators?: ValidationSchema['properties']['propertyName']
  items?: AnyObject[]
  options?: AnyObject[]
  uniqueKey?: string
  children?: IFormField[]
  variant?: string
  required?: boolean
  optionLabelKey?: string
  optionValueKey?: string
}

export interface IForm {
  name: string
  rows: IFormField[][]
}

export interface ITabForm extends IForm {
  tab: {
    // type: 'horizontal' | 'vertical',
    activeKey: ITab['name']
    tabs: ITab[]
  }
}

export type TForm = IForm | ITabForm
