import { Field, useField } from 'formik'
import React, { FC, useEffect } from 'react'

export interface CheckboxesGroupProps {
  name: string
  optionLabelKey: string
  optionValueKey: string
  options: { [key: string]: unknown }[]
  label: string
  required: boolean
}

export const CheckboxesGroup: FC<CheckboxesGroupProps> = ({
  name,
  optionLabelKey,
  optionValueKey,
  label,
  options,
  required = false,
}) => {
  const [{ value = [] }, _, { setValue }] = useField<unknown[]>(name)

  useEffect(() => {
    return () => {
      setValue([])
    }
  }, [])

  return (
    <div>
      <div className="mb-2">
        <b>{label}</b>
      </div>
      <div>
        {(options || []).map((option, i) => (
          <div key={`checkbox-option-${i}`}>
            <label>
              <Field
                type="checkbox"
                name={name}
                value={option[optionValueKey]}
                checked={value.includes(String(option[optionValueKey]))}
                className="mr-2"
              />
              {option[optionLabelKey]}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}
