import { Field, useField } from 'formik'
import React, { FC, useEffect } from 'react'

export interface StringFieldProps {
  name: string
  label?: string
  variant?: 'input' | 'textarea'
  rows?: number
  disabled?: boolean
}

export const StringField: FC<StringFieldProps> = ({
  name,
  rows,
  variant = 'input',
  disabled,
  label,
}) => {
  const [_, __, { setValue }] = useField<string>(name)

  useEffect(() => {
    return () => {
      setValue('')
    }
  }, [])

  return (
    <label className="w-100">
      {label}
      <Field name={name} as={variant} rows={rows} disabled={disabled} className="form-control" />
    </label>
  )
}
