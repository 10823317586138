import React from 'react'

import LayoutMain from 'src/components/Layouts/LayoutMain'
import Demo from 'src/features/Demo'
import Orders from 'src/features/Orders'
import Order from 'src/features/Order'
import Dashboard from 'src/features/Dashboard'
import OrderReport from 'src/features/OrderReport'
import Questionnaire from './features/Questionnaire'

export interface IRoutes {
  path: string
  pureComponent?: React.ComponentType & any
  layout?: React.ComponentType & any
  exact?: boolean
  title?: string
}

export interface INavLinksSalons {
  title: string
  dependence: string
}

// ---
// Default application layout

export const DefaultLayout = LayoutMain

// ---
// Document title template

export const titleTemplate = '%s - Executors CRM'

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/'

export const routes = (permission: boolean): IRoutes[] => [
  {
    path: '/',
    pureComponent: Dashboard,
    exact: true,
    title: 'Dashboard',
  },
  {
    path: '/demo',
    pureComponent: Demo,
    exact: true,
    title: 'Демо',
  },
  {
    path: '/orders/:date',
    pureComponent: Orders,
    exact: true,
    title: 'Заказы',
  },
  {
    path: '/order/:orderId',
    pureComponent: Order,
    exact: true,
    title: 'Заказ',
  },
  {
    path: '/order/:orderId/report',
    pureComponent: OrderReport,
    exact: true,
    title: 'Отчет по заказу',
  },
  {
    path: '/order/:orderId/questionnaire',
    pureComponent: Questionnaire,
    exact: true,
    title: 'Оценка исполнителей',
  },
]
