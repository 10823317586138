import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { FormControl } from 'src/components/Formik'
import QuestionnaireStore from 'src/store/QuestionnaireStore'

export const QuestionnaireForm: FC = observer(() => {
  const store = QuestionnaireStore

  return (
    <div className="p-4">
      <Tabs defaultActiveKey={store.reviewSubjects?.[0]?.id} id="questionnaire" variant="pills">
        {store.reviewSubjects.map((subject) => (
          <Tab key={subject.id!} eventKey={subject.id} title={subject.name} className="mt-4">
            {store.formConfig[subject.id!].map((field) => (
              <div key={field.name} className="mb-4">
                <FormControl {...field} />
              </div>
            ))}
          </Tab>
        ))}
      </Tabs>
    </div>
  )
})
