import React from 'react'

import { Col, Form, Badge, Row } from 'react-bootstrap'
import Select from 'react-select'
import { Field } from 'react-final-form'
import { FieldError } from 'src/components/Fields/FieldError'

import { TFieldProp } from 'src/components/Fields/field.interface'

import { parsePrice, parseDuration } from 'src/utils/formatters'
import { FIELDS } from '../../Forms/form.interface'
import { logger } from '@qlean/front-logger'

interface AnyObject {
  [key: string]: any
}

export enum DisplayType {
  NUMBER = 'number',
  LIST = 'list',
  CHECKBOX = 'checkbox',
  READONLY = 'readonly',
}

export interface IOption {
  label: string
  value: number | string
}

export interface IDefaultValue {
  value: number | null | undefined
  isFree: boolean
}

export interface IInitialValue {
  value: IOption | number | boolean | undefined | null
  isFree: boolean
}

export interface IOptionFieldProp {
  type: FIELDS
  display: DisplayType
  options?: IOption[]
  totalDuration: number
  totalPrice: number
  defaultValue: IDefaultValue
}

export type TOptionFieldProp = IOptionFieldProp & TFieldProp<AnyObject>

export const OptionField = (props: TOptionFieldProp) => {
  const {
    placeholder = 'Выбор',
    name,
    label,
    defaultValue,
    display,
    options,
    totalDuration,
    totalPrice,
  } = props

  let initialValue: IInitialValue = defaultValue

  const renderField = (input, meta) => {
    switch (display) {
      case DisplayType.LIST:
        const value = options?.find((option) => option.value === initialValue.value)
        initialValue = {
          ...initialValue,
          value,
        }
        return (
          <Select
            {...input}
            className="w-100"
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            defaultValue={initialValue.value}
            placeholder={placeholder}
            onChange={(evt) => {
              logger.debug('CHANGE', name, evt)
              input.onChange({
                ...input.value,
                value: evt,
              })
            }}
          />
        )
      case DisplayType.NUMBER:
        return (
          <Form.Control
            id={`${name}-value`}
            value={input.value.value}
            placeholder={placeholder}
            isInvalid={FieldError.isInvalid(meta)}
            onChange={(evt) => {
              logger.debug('CHANGE', name, evt.target.value)
              input.onChange({
                ...input.value,
                value: evt.target.value,
              })
            }}
          />
        )
      case DisplayType.CHECKBOX:
        return (
          <div>
            <label className="switcher">
              <input
                type="checkbox"
                className="switcher-input"
                checked={input.value.value}
                defaultChecked={Boolean(initialValue.value)}
                onChange={(evt) => {
                  logger.debug('CHANGE', name, evt.target.checked)
                  input.onChange({
                    ...input.value,
                    value: evt.target.checked ? 1 : 0,
                  })
                }}
              />
              <span className="switcher-indicator">
                <span className="switcher-yes">
                  <span className="ion ion-md-checkmark"></span>
                </span>
                <span className="switcher-no">
                  <span className="ion ion-md-close"></span>
                </span>
              </span>
            </label>
          </div>
        )
    }
  }
  return (
    <Field
      name={name}
      initialValue={initialValue}
      render={({ input, meta }) => {
        return (
          <Form.Group className="m-0">
            <Row style={{ minHeight: '36px' }}>
              <Col className="d-flex align-items-center justify-content-between py-3" md={9}>
                <span>{label?.label}</span>
                <div className="d-flex">
                  <span className="text-light mr-2">
                    {input.value.isFree ? (
                      <del>{`+ ${parsePrice(totalPrice)}`}</del>
                    ) : (
                      `+ ${parsePrice(totalPrice)}`
                    )}
                  </span>
                  <Badge className="font-weight-normal border-bottom-0 card-header badge-outline-default">
                    {parseDuration(totalDuration) || '0 мин.'}
                  </Badge>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center border-left py-3"
                md={2}
              >
                <Form.Control
                  hidden
                  readOnly={true}
                  value={defaultValue.value ? defaultValue.value : 0}
                  // isInvalid={FieldError.isInvalid(meta)}
                />
                {renderField(input, meta)}
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center border-left py-3"
                md={1}
              >
                <div>
                  <label className="switcher">
                    <input
                      type="checkbox"
                      className="switcher-input"
                      defaultChecked={initialValue.isFree}
                      checked={input.value.isFree}
                      onChange={(evt) => {
                        logger.debug('CHANGE', name, evt.target.checked)
                        input.onChange({
                          ...input.value,
                          isFree: evt.target.checked ? 1 : 0,
                        })
                      }}
                    />
                    <span className="switcher-indicator">
                      <span className="switcher-yes">
                        <span className="ion ion-md-checkmark"></span>
                      </span>
                      <span className="switcher-no">
                        <span className="ion ion-md-close"></span>
                      </span>
                    </span>
                  </label>
                </div>
              </Col>
            </Row>
            <FieldError error={meta.error} />
          </Form.Group>
        )
      }}
    />
  )
}
