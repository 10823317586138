import { GrpcService } from './GrpcService';
import LoginStore from 'src/store/LoginStore';
import { logger } from '@qlean/front-logger';
import { toast } from 'react-toastify';

export * from './codegen/app_pb';
const grpcService = new GrpcService(process.env.REACT_APP_API);

function getUserFriendlyErrors(error: string) {

  if (error.includes('check_plan_deliveries_limit_reached')) {
    return 'Измените план заказа или слоты забора/доставки!'
  }

  if (error.includes('address_id')) {
    return 'Проверьте корректность адреса (он должен быть указан до квартиры)!'
  }

  return error;
}

const TOAST_TIMEOUT = 15000;

grpcService.interceptors.errors.push((error) => {
  const errorMessage = decodeURI(error.message || '');
  if (error.code === 419) {
    return LoginStore.refresh().catch(err => {
      logger.error('refreshError, need relogin', err);
      LoginStore.exit();
      window.location.href = '/login';
    });
  } else if (errorMessage === 'NOT_FOUND - account not found') {
    return Promise.resolve();
  } else {
    logger.error(`[GrpcService] error`, error);
    toast(getUserFriendlyErrors(errorMessage), {
      type: 'error',
      autoClose: TOAST_TIMEOUT,
      pauseOnHover: true,
    });
    return Promise.resolve();
  }
});


export default grpcService;
