import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import OrderStore from 'src/store/OrderStore'
import { Card, Spinner } from 'react-bootstrap'
import { FIELDS } from 'src/components/Forms/form.interface'
import { dictionary } from 'src/utils/dictionary'
import DynamicForm from 'src/components/Forms/DynamicForm'
import { parseMinutes } from '../../utils/formatters'

interface IParams {
  orderId: string
}

interface IProps {
  setTitle: (title?: string) => void
}

interface IOption {
  label: string
  value: string
}

export enum ExecutorRole {
  MENTOR, // Клинер - главный
  WORKER, // Клинер - линейный
  COURIER, // курьер, нужен в групповых уборках
  WORKER_SENIOR, // Клинер - старший
}

const OrderReport = observer((props: IProps) => {
  const durationOptions: IOption[] = []
  const { orderId } = useParams<IParams>()
  const [executorIdForKeysDelivery, setExecutorIdForKeysDelivery] = useState<string | undefined>(
    undefined
  )
  const [executorIdForKeysPickup, setExecutorIdForKeysPickup] = useState<string | undefined>(
    undefined
  )

  const [executorsTimes, setExecutorsTimes] = useState<{ [key: string]: number }>({})

  const [isOpenedOrder, setIsOpenedOrder] = useState(true)
  const history = useHistory()
  const location = useLocation()
  const { setTitle } = props
  const { order, executors, getOrder, sendOrderReport, isLoading } = OrderStore

  useEffect(() => {
    setTitle()
  }, [])

  useEffect(() => {
    getOrder({ orderId })
  }, [orderId])

  useEffect(() => {
    if (executors && order) {
      // число исполнителей без учета курьеров
      const executorsCount = executors.filter((executor) => executor.role !== ExecutorRole.COURIER)
        .length
      const times = {}
      const defaultDurationPerExecutor = order?.totalDuration / executorsCount / 60
      for (const executor of executors) {
        if (executor.role !== ExecutorRole.COURIER) {
          times[executor.executorId] = executor.duration ?? defaultDurationPerExecutor
        }
      }
      setExecutorsTimes(times)
    }
  }, [executors, order])

  const ReactSwal = withReactContent(
    Swal.mixin({
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg',
        cancelButton: 'btn btn-default btn-lg',
        actions: 'text-center',
      },
    })
  )

  const isKeysDeliveryInOrder = Boolean(
    order?.price?.products?.[0].options.find((option) => option.slug === 'keys_delivery')?.value
  )

  const isKeysPickupInOrder = Boolean(
    order?.price?.products?.[0].options.find((option) => option.slug === 'keys_pickup')?.value
  )

  for (let i = 0; i <= 72; i = i + 1) {
    durationOptions.push({ label: i.toString(), value: i.toString() })
  }

  let defaultDurationPerExecutor = 0

  if (order?.totalDuration) {
    // число исполнителей без учета курьеров
    const executorsCount = executors.filter((executor) => executor.role !== ExecutorRole.COURIER)
      .length

    defaultDurationPerExecutor = order?.totalDuration / executorsCount
  }

  const durations = {}

  const form = {
    name: 'order-edit',
    rows: [
      [
        {
          type: FIELDS.SELECT,
          name: 'isOpenedOrder',
          label: {
            label: 'Заказ выполнен',
          },
          placeholder: 'Заказ выполнен',
          initialValue: { id: true, value: 'Выполнен' },
          optionValueKey: 'id',
          optionLabelKey: 'value',
          options: dictionary.selectOptions.orderFinished,
          variant: 'dropdown',
          onChange: (value) => {
            setIsOpenedOrder(Boolean(value.id))
          },
        },
      ],
      [
        {
          type: FIELDS.STRING,
          name: 'comment',
          label: {
            label: 'Комментарий',
          },
          placeholder: 'Комментарий',
          variant: 'textarea',
        },
      ],
      ...executors
        .sort((a, b) => a.role - b.role)
        .map((executor) => {
          const role = dictionary.selectOptions.executorRoles.find(
            (extr) => extr.id === executor.role
          )?.singular
          if (executor.role === 2) {
            return [
              {
                type: FIELDS.STRING,
                name: `duration-${executor.executorId}`,
                label: {
                  label: role,
                },
                initialValue: executor.name,
                isReadonly: true,
              },
              {
                type: FIELDS.BOOLEAN,
                name: `keyDelivery-${executor.executorId}`,
                variant: 'switch',
                disabled: order?.serviceType === 'drycleaning_furniture',
                isReadonly:
                  (executorIdForKeysDelivery &&
                    executorIdForKeysDelivery !== executor.executorId) ||
                  !isKeysDeliveryInOrder,
                onChange: (checked) =>
                  setExecutorIdForKeysDelivery(checked ? executor.executorId : undefined),
                placeholder: 'Доставил ключи',
              },
              {
                type: FIELDS.BOOLEAN,
                name: `keyPickup-${executor.executorId}`,
                variant: 'switch',
                disabled: order?.serviceType === 'drycleaning_furniture',
                isReadonly:
                  (executorIdForKeysPickup && executorIdForKeysPickup !== executor.executorId) ||
                  !isKeysPickupInOrder,
                onChange: (checked) =>
                  setExecutorIdForKeysPickup(checked ? executor.executorId : undefined),
                placeholder: 'Забрал ключи',
              },
            ]
          }

          const currentValue = {
            label: parseMinutes(defaultDurationPerExecutor),
            value: (defaultDurationPerExecutor / 60).toFixed(2),
          }

          const options = durationOptions.map((item) => ({
            label: item.value !== '0' ? parseMinutes(+item.value * 10) : '0',
            value: item.value !== '0' ? ((+item.value * 10) / 60).toFixed(2) : '0',
          }))

          options.unshift(currentValue)

          durations[executor.executorId + ''] = currentValue.value + ''
          return [
            {
              type: FIELDS.SELECT,
              name: `duration-${executor.executorId}`,
              label: {
                label: `${role} - ${executor.name}`,
              },
              validators: [
                {
                  type: 'isNotEmpty',
                  message: 'Заполните время работы, если клинер не участвовал поставьте 0',
                },
              ],
              options,
              optionValueKey: 'value',
              optionLabelKey: 'label',
              initialValue: currentValue,
              placeholder: 'Кол-во часов',
              disabled: !isOpenedOrder,
              isReadonly: order?.serviceType === 'drycleaning_furniture',
              onChange: (event) => {
                executorsTimes[executor.executorId] = parseFloat(event.value)
                setExecutorsTimes({ ...executorsTimes })
              },
            },
            {
              type: FIELDS.BOOLEAN,
              name: `keyDelivery-${executor.executorId}`,
              variant: 'switch',
              isReadonly:
                order?.serviceType === 'drycleaning_furniture' ||
                (executorIdForKeysDelivery && executorIdForKeysDelivery !== executor.executorId) ||
                !isKeysDeliveryInOrder,
              onChange: (checked) =>
                setExecutorIdForKeysDelivery(checked ? executor.executorId : undefined),
              placeholder: 'Доставил ключи',
            },
            {
              type: FIELDS.BOOLEAN,
              name: `keyPickup-${executor.executorId}`,
              variant: 'switch',
              isReadonly:
                order?.serviceType === 'drycleaning_furniture' ||
                (executorIdForKeysPickup && executorIdForKeysPickup !== executor.executorId) ||
                !isKeysPickupInOrder,
              onChange: (checked) =>
                setExecutorIdForKeysPickup(checked ? executor.executorId : undefined),
              placeholder: 'Забрал ключи',
            },
          ]
        }),
    ],
  }

  const onSubmit = (values) => {
    const items = executors.map((executor) => {
      if (executor.role === 2) {
        return {
          executorId: executor.executorId,
          keyDelivery: Boolean(values[`keyDelivery-${executor.executorId}`]),
          keyPickup: Boolean(values[`keyPickup-${executor.executorId}`]),
        }
      }
      return {
        executorId: executor.executorId,
        duration: +values[`duration-${executor.executorId}`].value,
        keyDelivery: Boolean(values[`keyDelivery-${executor.executorId}`]),
        keyPickup: Boolean(values[`keyPickup-${executor.executorId}`]),
      }
    })

    sendOrderReport({
      orderId,
      items,
      comment: values.comment,
      isCanceledOrder: !Boolean(values.isOpenedOrder?.id),
      actualTotalDuration: +(values.actualTotalDuration || 0),
      parkingPriceCents: +(values.parkingPriceCents * 100 || 0),
      otherExpensesCents: +(values.otherExpensesCents * 100 || 0),
      skipInvoice: order?.totalPrice === 0 ? true : !Boolean(values.isOpenedOrder?.id),
    }).then((res) => {
      if (res) {
        if (values.isOpenedOrder.value === 'Выполнен' && order?.status !== 'finished') {
          history.push(`/order/${order?.id}/questionnaire`)
        }

        ReactSwal.fire({
          title: 'Отчёт успешно отправлен',
          type: 'success',
        })
      }
    })
  }

  const totalExecutorsTime = Math.round(
    Object.keys(executorsTimes)
      .map((key) => executorsTimes[key])
      .reduce((a, b) => a + b, 0) * 60
  )

  return order && order.id === orderId ? (
    <Card className="mb-4">
      <Card.Header as="h6">Отправка отчёта</Card.Header>
      <Card.Body>
        <DynamicForm
          // @ts-ignore
          form={form}
          onSubmit={onSubmit}
          submitText="Отправить"
          cancelText="Вернуться"
          onCancel={() => {
            const orderLocation = location.pathname.substr(0, location.pathname.lastIndexOf('/'))
            history.push(orderLocation)
          }}
          isLoading={isLoading}
        />
      </Card.Body>
      <Card.Footer>
        {order.totalDuration - totalExecutorsTime > 20 ? (
          <>
            Распределено <b>{parseMinutes(totalExecutorsTime)}</b>, осталось{' '}
            <b>{parseMinutes(order.totalDuration - totalExecutorsTime)}</b>
          </>
        ) : order.totalDuration - totalExecutorsTime < 0 ? (
          <>
            Превышение на{' '}
            <b style={{ color: 'red' }}>{parseMinutes(order.totalDuration - totalExecutorsTime)}</b>
          </>
        ) : (
          <>
            Суммарное время <b>{parseMinutes(totalExecutorsTime)}</b>
          </>
        )}
      </Card.Footer>
    </Card>
  ) : (
    <Spinner animation="grow" variant="success" />
  )
})

export default OrderReport
