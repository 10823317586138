import { FormikState, useField, useFormikContext } from 'formik'
import React, { PropsWithChildren, useEffect, useState, cloneElement, ReactElement } from 'react'
import * as hash from 'object-hash'

export interface DependentProps {
  name?: string
  on: IDependOn
  hiddenByDefault?: boolean
  disabledByDefault?: boolean
  needToReset?: boolean
}

interface IDependOn {
  fieldName: string
  propertyValue?: string
  toBe?: unknown
  contains?: unknown
}

const findValueRecursive = (object: any, path: string[], index = 0) => {
  if (index === path.length - 1) {
    return object[path[index]]
  }

  return findValueRecursive(object[path[index]], path, ++index)
}

const hashValue = (value: unknown) => (typeof value === 'object' ? hash(value) : hash({ value }))

const resetChildrenField = (
  children: JSX.Element | JSX.Element[],
  values: Record<string, unknown>,
  resetCb: (values: Partial<FormikState<unknown>> | undefined) => void
) => {
  if (Array.isArray(children)) {
    children.forEach((child) => {
      const { name } = child.props

      resetCb({
        values: {
          ...values,
          [name]: undefined,
        },
      })
    })
  } else if (findValueRecursive(values, children.props.name.split('.'))) {
    resetCb({
      values: {
        ...values,
        [children.props.name]: undefined,
      },
    })
  }
}

export const Dependent = ({
  on,
  hiddenByDefault = false,
  needToReset,
  disabledByDefault,
  children,
}: PropsWithChildren<DependentProps>): JSX.Element => {
  const { fieldName, propertyValue, toBe, contains } = on
  const [{ value }] = useField(fieldName)
  const [isHidden, setIsHidden] = useState(hiddenByDefault)
  const [isDisabled, setIsDisabled] = useState(disabledByDefault)
  const { values, resetForm } = useFormikContext()

  useEffect(() => {
    let checkingValue = value

    if (toBe) {
      if (propertyValue) {
        const splitted = propertyValue.split('.')
        checkingValue = findValueRecursive(value, splitted)
      }

      checkingValue = typeof toBe === 'boolean' ? !!checkingValue : checkingValue
      checkingValue = checkingValue === toBe ? checkingValue : undefined
    }

    if (contains) {
      if (!Array.isArray(value)) {
        return
      }

      checkingValue = value.includes((contains))
    }

    if (checkingValue && isHidden && hiddenByDefault) {
      setIsHidden(false)
    } else if (!checkingValue && !isHidden && hiddenByDefault) {
      setIsHidden(true)
    }

    if (checkingValue && isDisabled && disabledByDefault) {
      setIsDisabled(false)
    } else if (!checkingValue && !isDisabled && disabledByDefault) {
      setIsDisabled(true)
    }

    if (checkingValue && needToReset) {
      resetChildrenField(
        children as JSX.Element | JSX.Element[],
        values as Record<string, unknown>,
        resetForm
      )
    }
  }, [value])

  const uniqueKey = needToReset ? { key: hashValue(values) } : {}

  return (
    <div {...uniqueKey}>
      {isHidden ? null : cloneElement(children as ReactElement, { disabled: isDisabled })}
    </div>
  )
}
