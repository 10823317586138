import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Card, Col, Row } from 'react-bootstrap'

interface IProps {
  setTitle: () => void
}

const Dashboard = observer(({ setTitle }) => {
  useEffect(() => {
    setTitle()
  }, [])
  return (
    <div className="px-4 container-p-y">
      <Card className="mt-5">
        <Card.Header as="h5" className="py-4 px-5">
          Основные ссылки
        </Card.Header>
        <Row noGutters className="row-bordered">
          <Col md={12} className="p-5">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.notion.so/9b59b8b3e93946b39fa122945c09ffd7"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Заполнение отчета по заказу
            </a>
          </Col>
        </Row>
      </Card>
    </div>
  )
})

export default Dashboard
