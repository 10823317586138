import React from 'react'
import axios from 'axios'

import { Field } from 'react-final-form'
import { Form, Row, Col } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { FieldLabel } from 'src/components/Fields/FieldLabel'
import { FieldError } from 'src/components/Fields/FieldError'
import { SimpleText } from 'src/components/Fields/Text/SimpleText'

import { IAddress } from 'src/components/Fields/Address/interfaces/address.interface'
import { ISuggestion } from 'src/components/Fields/Address/interfaces/dadata.interface'
import { TFieldProp } from 'src/components/Fields/field.interface'
import { logger } from '@qlean/front-logger'

import { METRO_LINE_COLORS } from 'src/utils/metro-colors'
import { TITLES, PLACEHOLDERS } from './const'

const DADATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest'
const DADATA_TOKEN = '1a3b0db7bee65d261d6b29ac9f5907446bf788d5'

interface State {
  isLoading: boolean
  isNotSelectedAddress: boolean
  options: ISuggestion[]
  selected?: IAddress
  isShortForm?: boolean
  isValid: (address?: IAddress) => boolean
  regionId: number
}

interface IAddressProps {
  value?: IAddress
  isShortForm?: boolean
  isValid?: (address?: IAddress) => boolean
  regionId?: number
}

const LOCATIONS = {
  [1]: [{ city: 'Москва' }, { region: 'Московская' }],
  [2]: [{ city: 'Санкт-Петербург' }],
}

export class AddressField extends React.Component<TFieldProp<IAddressProps>, State> {
  constructor(props: TFieldProp<IAddressProps>) {
    super(props)
    const { initialValue } = props

    this.state = {
      isLoading: false,
      isNotSelectedAddress: false,
      options: [],
      selected: initialValue?.value,
      isShortForm: initialValue?.isShortForm,
      isValid: initialValue?.isValid || this.isDefaultValid,
      regionId: initialValue?.regionId || 1,
    }
  }

  private isDefaultValid(address?: IAddress): boolean {
    return !!address?.object?.fias_id
  }

  private instance = axios.create({
    baseURL: DADATA_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${DADATA_TOKEN}`,
    },
  })

  private search = async (data: any): Promise<ISuggestion[]> => {
    const response = await this.instance.post('/address', data)
    const { suggestions = [] } = response.data as { suggestions: ISuggestion[] }

    return suggestions
  }

  private onSearch = async (query: string) => {
    this.setState({ isLoading: true })
    const suggestions = await this.search({
      query,
      locations: LOCATIONS[this.state.regionId],
    })
    this.setState({ isLoading: false, options: suggestions })
  }

  private onFlatNumberChange(onChange: (arg?: IAddress) => void, event) {
    const { selected } = this.state
    const { value } = event.target

    if (!selected) {
      return
    }

    if (selected.object) {
      selected.object.flat = value
    }

    selected.value = selected.value.replace(/\sкв(\s[\d]+)/, 'кв ' + value)

    this.setState({ selected }, () => {
      onChange(selected)
    })
  }

  private onAddressChange = async (
    onChange: (arg: { value?: IAddress }) => void,
    [option]: ISuggestion[]
  ) => {
    let { selected } = this.state

    if (!option) {
      return
    }

    const [suggestion] = await this.search({
      query: option.unrestricted_value,
      count: 1,
    })

    const { metro, geo_lat, geo_lon, ...object } = suggestion.data

    selected = Object.assign(selected || { value: '' }, {
      value: suggestion.value,
      unrestrictedValue: suggestion.unrestricted_value,
      object,
      metro,
      coordinates: {
        lat: Number(geo_lat),
        lng: Number(geo_lon),
      },
    })

    this.setState({ selected, isNotSelectedAddress: false }, () => {
      logger.debug(`[AddressField] update address`, { selected })
      onChange({ value: selected })
    })
  }

  private onAddressDetailsChanged(
    type: string,
    onChange: (arg: { value?: IAddress }) => void,
    event
  ) {
    let { selected } = this.state

    selected = Object.assign(selected || { value: '', detail: {} }, {
      detail: {
        ...selected?.detail,
        [type]: event.target.value,
      },
    })

    this.setState({ selected }, () => {
      logger.debug(`[AddressField] update details`, { selected })
      onChange({ value: selected })
    })
  }

  private onBlur = ({ target }, value: IAddress = { value: '' }) => {
    this.setState({ isNotSelectedAddress: target.value !== '' && target.value !== value.value })
  }

  private renderPoint = () => {
    const { selected } = this.state

    if (!selected?.coordinates) {
      return null
    }

    const { lat, lng } = selected?.coordinates

    return (
      <>
        <span className="align-items-center text-body">
          <i className="ion ion-ios-pin" />
          &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            data-ref="geo-link"
            title="Открыть на Yandex.Картах"
            href={`//maps.yandex.ru/?text=${lat},${lng}`}
          >
            {lat.toFixed(2)}, {lng.toFixed(2)}
          </a>
        </span>
        &nbsp;&nbsp;&nbsp;
      </>
    )
  }

  private renderMetro = () => {
    const { selected } = this.state

    if (!selected?.metro) {
      return null
    }

    const renderOne = (metro, idx, list) => (
      <span key={`${metro.line}_${metro.name}`} className="align-items-center text-body">
        <i className="ion ion-ios-square" style={{ color: METRO_LINE_COLORS[metro.line] }} />
        <span>
          &nbsp;{metro.name}&nbsp;({metro.distance.toFixed(1)} км)
          {list.length - 1 !== idx ? ',' : ''}&nbsp;
        </span>
      </span>
    )

    return (selected?.metro || []).map(renderOne)
  }

  render() {
    const { isRequired, isReadonly, name, label, placeholder, initialValue } = this.props
    const { isLoading, options, isNotSelectedAddress, isShortForm, selected, isValid } = this.state

    return (
      <Field
        name={name}
        initialValue={initialValue}
        render={({ input: { onChange, ...inputProps }, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <Form.Control
              readOnly={true}
              hidden={!isReadonly}
              value={(initialValue || {}).value?.value}
              isInvalid={FieldError.isInvalid(meta) || isNotSelectedAddress}
            />
            {!isReadonly && (
              <AsyncTypeahead
                {...inputProps}
                id={`${name}-value`}
                options={options}
                hidden={true}
                placeholder={placeholder}
                align="justify"
                minLength={3}
                delay={300}
                useCache={false}
                isLoading={isLoading}
                isInvalid={FieldError.isInvalid(meta) || isNotSelectedAddress}
                defaultInputValue={(initialValue || {}).value?.value}
                labelKey={(option) => option.value}
                filterBy={(option) => option}
                onSearch={this.onSearch}
                onChange={(query) => this.onAddressChange(onChange, query)}
                onBlur={(e) => this.onBlur(e, selected)}
                className="mb-1"
              />
            )}
            <div className="mb-1">
              {this.renderPoint()}
              {this.renderMetro()}
            </div>
            <FieldError error={isNotSelectedAddress ? 'Адрес не выбран из списка' : meta.error} />
            {!isShortForm && isValid(selected) && (
              <div>
                <Row>
                  <Col>
                    <FieldLabel label={TITLES.flat} isRequired={false} />
                    <Form.Control
                      id={`${name}-object-flat`}
                      value={selected?.object?.flat}
                      readOnly={isReadonly}
                      placeholder={PLACEHOLDERS.flat}
                      onChange={(evt) => this.onFlatNumberChange(onChange, evt)}
                      isInvalid={FieldError.isInvalid(meta)}
                    />
                  </Col>
                  <Col>
                    <FieldLabel label={TITLES.intercom} isRequired={false} />
                    <Form.Control
                      id={`${name}-detail-intercom`}
                      value={selected?.detail?.intercom}
                      readOnly={isReadonly}
                      placeholder={PLACEHOLDERS.intercom}
                      onChange={(evt) => this.onAddressDetailsChanged('intercom', onChange, evt)}
                      isInvalid={FieldError.isInvalid(meta)}
                    />
                  </Col>
                  <Col>
                    <FieldLabel label={TITLES.floor} isRequired={false} />
                    <Form.Control
                      id={`${name}-detail-floor`}
                      value={selected?.detail?.floor}
                      readOnly={isReadonly}
                      placeholder={PLACEHOLDERS.floor}
                      onChange={(evt) => this.onAddressDetailsChanged('floor', onChange, evt)}
                      isInvalid={FieldError.isInvalid(meta)}
                    />
                  </Col>
                  <Col>
                    <FieldLabel label={TITLES.entrance} isRequired={false} />
                    <Form.Control
                      id={`${name}-detail-entrance`}
                      value={selected?.detail?.entrance}
                      readOnly={isReadonly}
                      placeholder={PLACEHOLDERS.entrance}
                      onChange={(evt) => this.onAddressDetailsChanged('entrance', onChange, evt)}
                      isInvalid={FieldError.isInvalid(meta)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FieldLabel label={TITLES.comment} isRequired={false} />
                    <SimpleText
                      {...inputProps}
                      placeholder={PLACEHOLDERS.comment}
                      name={`${name}-comment`}
                      value={selected?.detail?.comment || ''}
                      readOnly={isReadonly}
                      onChange={(evt) => this.onAddressDetailsChanged('comment', onChange, evt)}
                      isInvalid={FieldError.isInvalid(meta)}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Form.Group>
        )}
      />
    )
  }
}
