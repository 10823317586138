import { Field, useField } from 'formik'
import React, { FC, useEffect } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export interface RadioGroupProps {
  name: string
  optionLabelKey: string
  optionValueKey: string
  options: { [key: string]: unknown }[]
  label: string
  required: boolean
}

export const RadioGroup: FC<RadioGroupProps> = ({
  name,
  optionLabelKey,
  optionValueKey,
  options,
  label,
  required = false,
}) => {
  const [{ value }] = useField(name)

  const tooltip = (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`${label}_required`}>Необходимо выбрать один из вариантов</Tooltip>}
    >
      <i className="ion ion-ios-alert ml-1" />
    </OverlayTrigger>
  )

  return (
    <div>
      <div className="mb-2">
        <b>{label}</b> {required && tooltip}
      </div>
      <div>
        {options.map((option, i) => (
          <div key={`radio-option-${i}`}>
            <label>
              <Field
                type="radio"
                name={name}
                value={option[optionValueKey]}
                checked={String(value) === String(option[optionValueKey])}
                className="mr-2"
              />
              {option[optionLabelKey]}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}
