import React, { FC } from 'react'
import { CheckboxesGroup } from './CheckboxesGroup/CheckboxesGroup'
import { Dependent } from './Dependent/Dependent'
import { RadioGroup } from './RadioGroup/RadioGroup'
import { StringField } from './StringField/StringField'

enum FormControlTypes {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  DEPENDENT = 'dependent',
  INPUT = 'input',
}

export interface ControlProps {
  name: string
  control: 'checkbox' | 'radio' | 'string'
  optionLabelKey: string
  optionValueKey: string
  label: string
  required: boolean
  options: { [key: string]: unknown }[]
  on: {
    fieldName: string
    toBe: unknown
    contains: string
  }
  variant: 'input' | 'textarea'
  rows: number
  hiddenByDefault: boolean
}

export const FormControl: FC<ControlProps> = (props: ControlProps) => {
  const controlsMap = {
    [FormControlTypes.CHECKBOX]: <CheckboxesGroup {...props} />,
    [FormControlTypes.RADIO]: <RadioGroup {...props} />,
    [FormControlTypes.INPUT]: <StringField {...props} />,
  }

  if (props.on) {
    return (
      <Dependent hiddenByDefault={props.hiddenByDefault} on={{ ...props.on }}>
        {controlsMap[props.control]}
      </Dependent>
    )
  }

  return controlsMap[props.control]
}
