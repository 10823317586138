import { Type } from 'class-transformer'
import { PlatformCRMWeb, sso } from 'src/services/GrpcService'

export class GetOrderRequestDto implements PlatformCRMWeb.GetOrderRequest {
  orderId!: string
}

export class GetOrdersByExecutorRequestDto implements PlatformCRMWeb.IGetOrdersRequest {
  date?: string
}

export class ExecutorLinkDto implements PlatformCRMWeb.IExecutorLink {
  executorId!: string
  duration!: number
  keyDelivery!: boolean
  keyPickup!: boolean
  bonus!: number
  penalty!: number
  rate!: number
  name!: string
  phone!: string
  qleanId!: number
  role!: number
  ssoId!: string
}

export class SaveOrderReportDto implements PlatformCRMWeb.ISaveOrderReportRequest {
  orderId!: string
  @Type(() => OrderReportItemDto)
  items!: OrderReportItemDto[]
  skipInvoice?: boolean
  actualTotalDuration!: number
  comment?: string
  parkingPriceCents!: number
  otherExpensesCents!: number
  isCanceledOrder!: boolean
}

export class SendInvoiceRequestDto implements PlatformCRMWeb.ISendInvoiceRequest {
  orderId!: string
}

class OrderReportItemDto implements PlatformCRMWeb.IOrderReportItem {
  duration?: number
  bonus?: number
  penalty?: number
  keyDelivery!: boolean
  keyPickup!: boolean
}

export class FioDto implements PlatformCRMWeb.IFIO {
  fullName?: string // Полное, правильно отформатированное ФИО возвращаемый из Dadata
  lastName?: string // Фамилия
  middleName?: string // Отчество
  firstName?: string //  Имя
  isValid?: boolean // Признка того, что ФИО разобрано или через DATA или в ручную менджером
  gender?: number
}

export class PersonDto implements PlatformCRMWeb.IPerson {
  id!: string
  email!: string
  phone!: string
  status!: string
  ssoId!: string
  commentForCleaner!: string
  commentForSupport!: string
  @Type(() => FioDto)
  fio!: FioDto
  createdAt!: string
  updatedAt!: string
}

export class CalculateOptionDto implements PlatformCRMWeb.ICalculateOption {
  slug!: string
  value!: number
  totalPrice!: number
  totalDuration!: number
  id!: string
  createdAt!: string
  updatedAt!: string
  isFree!: boolean
  factor?: number
}

export class CalculateProductDto implements PlatformCRMWeb.ICalculateProduct {
  productSlug!: string
  @Type(() => CalculateOptionDto)
  options!: CalculateOptionDto[]
  totalPrice!: number
  totalDuration!: number
  subTotalPrice!: number
  baseCost!: number
  baseDuration!: number
  id!: string
  createdAt!: string
  updatedAt!: string
}

export class CalculatePriceDto implements PlatformCRMWeb.ICalculatePrice {
  @Type(() => CalculateProductDto)
  products!: CalculateProductDto[]
  totalPrice!: number
  totalDuration!: number
  subTotalPrice!: number
  relevantUntil!: string
  priceListTitle!: string
  priceListId!: string
  createdAt!: string
  updatedAt!: string
}

export class AddressDto implements sso.IAddress {
  value!: string
  unrestrictedValue!: string
  object!: string
  @Type(() => AddressDetailDto)
  detail!: AddressDetailDto
  @Type(() => AddressPointDto)
  coordinates!: AddressPointDto
  @Type(() => AddressMetroDto)
  metro!: AddressMetroDto[]
  id?: string
}

export class AddressDetailDto {
  intercom!: string
  floor!: string
  comment!: string
  entrance!: string
}

export class AddressPointDto implements sso.ICoordinates {
  lat!: number
  lng!: number
}

export class AddressMetroDto implements sso.IMetro {
  name!: string
  distance!: number
  line!: string
}

export class ShortOrderDto implements PlatformCRMWeb.IShortOrder {
  id!: string
  startDate!: string
  @Type(() => AddressDto)
  address!: sso.Address
  orderNumber!: number
}

export class OrderDto implements PlatformCRMWeb.IOrder {
  id!: string
  orderNumber!: number
  status!: string
  @Type(() => PersonDto)
  person!: PersonDto
  prospectUID!: string
  executorId!: number
  startDate!: string
  @Type(() => AddressDto)
  address!: sso.Address
  regionId!: number
  @Type(() => CalculatePriceDto)
  price!: CalculatePriceDto
  promocode!: string
  serviceType!: string
  paymentType!: string
  cardNumber!: string
  qleanOrderId!: string
  commentForExecutor!: string
  commentForSupport!: string
  score!: number
  cleanerId!: string
  cancelReason!: string
  totalPrice!: number
  totalDuration!: number
  isOvertime!: boolean
  commentFromCustomer!: string
  @Type(() => AddressDto)
  keysDelivery!: sso.Address
  @Type(() => AddressDto)
  keysPickup!: sso.Address
  discount?: number
}
