import React from 'react'

import { Form, Table, Button } from 'react-bootstrap'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { Field } from 'react-final-form'
import { FieldLabel } from 'src/components/Fields/FieldLabel'

import { TFieldProp } from 'src/components/Fields/field.interface'
import { IFormField } from 'src/components/Forms/form.interface'

import { FIELD_COMPONENTS } from 'src/components/Forms/fields'

interface AnyObject {
  [key: string]: any
}

interface IColumnProp {
  value: string
  label: string
  field: IFormField
}

export interface ITableFieldProp {
  // массив объектов задающих колонки для отображения
  columns?: IColumnProp[]
}

export type TTableFieldProp = ITableFieldProp & TFieldProp<AnyObject[]>

export class TableField extends React.Component<TTableFieldProp> {
  static defaultProps: Partial<TTableFieldProp> = {
    isReadonly: false,
    isRequired: false,
  }

  private renderRow = (column: IColumnProp, nameTable: string, initialValue: AnyObject) => {
    const { isReadonly } = this.props
    const { value, field } = column
    const { type, name, validators, ...fieldProps } = field

    const FieldCell: React.ElementType = FIELD_COMPONENTS[type]

    return (
      <td key={`${nameTable}.${value}`} className="py-0 card-table">
        <FieldCell
          name={`${nameTable}.${value}`}
          {...fieldProps}
          initialValue={initialValue[value]}
          isReadonly={isReadonly}
        />
      </td>
    )
  }

  private renderTable = ({ fields }: FieldArrayRenderProps<TTableFieldProp, any>) => {
    const { columns, isReadonly, placeholder } = this.props

    if (!columns?.length) {
      return <div className="text-center text-muted p-1">Не задано колонок для отображения</div>
    }

    return (
      <>
        <Table className="table-fixed">
          <thead>
            <tr>
              {columns.map(({ label }, ith) => (
                <th key={ith}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fields.length ? (
              fields.map((name, index) => (
                <tr key={name}>
                  {columns.map((col) => this.renderRow(col, name, fields.value[index]))}
                  <td hidden={isReadonly} style={{ verticalAlign: 'middle', borderTop: 0 }}>
                    <Button
                      onClick={() => fields.remove(index)}
                      variant="outline-primary"
                      size="sm"
                    >
                      <i className="ion ion-md-close"></i>
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <div className="text-center text-muted p-1">{placeholder}</div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Button
          variant="outline-primary"
          onClick={() =>
            fields.push(
              columns.reduce((prev, cur) => ({ ...prev, [cur.value]: '' }), {} as TTableFieldProp)
            )
          }
          hidden={isReadonly}
        >
          <i className="ion ion-md-add"></i>&nbsp;{'Добавить строку'}
        </Button>
      </>
    )
  }

  render() {
    const { name, initialValue = [], isRequired, label } = this.props

    return (
      <Field
        name={name}
        initialValue={initialValue}
        render={() => (
          <Form.Group>
            <FieldLabel {...label} isRequired={isRequired} />
            <FieldArray name={name} render={this.renderTable} />
          </Form.Group>
        )}
      />
    )
  }
}
