import React from 'react'
import _ from 'lodash'
import { registerSchema } from 'class-validator'
import { Form, Button, Col, Spinner } from 'react-bootstrap'
import { Form as FinalForm } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { IForm, IFormField, TForm } from './form.interface'

import arrayMutators from 'final-form-arrays'
import { getValidatorSchema, validate } from './validate.utils'
import { FIELD_COMPONENTS } from './fields'

import 'src/components/Forms/style.scss'
import { logger } from '@qlean/front-logger'

interface AnyObject {
  [key: string]: any
}

export interface TDynamicForm {
  readonly form: TForm
  readonly onSubmit?: (values) => void
  readonly onCancel?: () => void
  readonly isAutoSubmit?: boolean
  readonly submitText?: string
  readonly isLoading?: boolean
  readonly cancelText?: string
  readonly isBordered?: boolean
}

interface State {
  isVisibleLog: boolean
}

export default class DynamicForm<T extends TDynamicForm> extends React.Component<T> {
  state: State = { isVisibleLog: false }

  constructor(props) {
    super(props)
    registerSchema(getValidatorSchema(props.form))
  }

  onSubmit = _.debounce((values: object) => {
    const { onSubmit } = this.props

    if (onSubmit) {
      onSubmit(values)
    }
  }, 600)

  onCancel() {
    const { onCancel } = this.props

    if (onCancel) {
      onCancel()
    }
  }

  async onValidate(form: IForm, values) {
    return validate(form, values)
  }

  renderRow(fields: IFormField[], index) {
    // @ts-ignore
    return (
      <Form.Row
        className={
          this.props.isBordered
            ? `list-group-item pl-3 pt-0 pr-2 pb-0 mx-0 border-left-0 border-right-0 ${
                index === 0 ? 'border-top-0' : ''
              }`
            : ''
        }
        key={`row-${index}`}
      >
        {fields.map((props) => {
          const { type, name, validators, ...field } = props
          let Field = FIELD_COMPONENTS[type]

          return (
            <Col key={name} md={12 / fields.length}>
              <Field name={name} {...field} />
            </Col>
          )
        })}
      </Form.Row>
    ) //
  }

  /**
   * метод нужен для разработки
   */
  renderLog = (values) => {
    const { isVisibleLog } = this.state

    return (
      <>
        <p
          style={{ textAlign: 'center', cursor: 'pointer' }}
          onClick={() => this.setState({ isVisibleLog: !isVisibleLog })}
        >
          ...
        </p>
        <pre hidden={!isVisibleLog}>{JSON.stringify(values, null, 2)}</pre>
      </>
    )
  }

  render() {
    return (
      <FinalForm
        validateOnBlur={true}
        validate={this.onValidate.bind(this, this.props.form)}
        onSubmit={this.onSubmit.bind(this)}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, values }) => {
          return (
            <form className={this.props.isBordered ? 'list-group' : ''} onSubmit={handleSubmit}>
              {this.renderBody()}
              {this.props.isAutoSubmit
                ? this.renderOnChange(values)
                : this.renderControl(handleSubmit)}
            </form>
          )
        }}
      />
    )
  }

  renderControl(
    handleSubmit: (
      event?:
        | Partial<Pick<React.SyntheticEvent<Element, Event>, 'preventDefault' | 'stopPropagation'>>
        | undefined
    ) => Promise<AnyObject | undefined> | undefined
  ) {
    const { isLoading, submitText, cancelText } = this.props
    return (
      <div className="d-flex justify-content-end">
        <Button disabled={isLoading} onClick={handleSubmit} variant="success">
          {isLoading ? (
            <>
              <Spinner as="span" animation="grow" role="status" aria-hidden="true" /> Загрузка...
            </>
          ) : submitText ? (
            submitText
          ) : (
            'Сохранить'
          )}
        </Button>
        &nbsp;
        <Button disabled={isLoading} onClick={this.onCancel.bind(this)} variant="danger">
          {cancelText ? cancelText : 'Отменить'}
        </Button>
      </div>
    )
  }

  renderBody() {
    return <>{this.props.form.rows.map(this.renderRow.bind(this))}</>
  }

  renderOnChange(values) {
    return (
      <>
        {Array.prototype.concat.apply([], this.props.form.rows).map((field) => (
          <OnChange key={field.name} name={field.name}>
            {(value, previous) => {
              logger.debug('[DynamicForm] change', values)
              this.onSubmit(values)
            }}
          </OnChange>
        ))}
      </>
    )
  }
}
