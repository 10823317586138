import React from 'react'

import { Col, Form, Button } from 'react-bootstrap'
import Select from 'react-select'
import { Field } from 'react-final-form'
import { FieldLabel } from 'src/components/Fields/FieldLabel'
import { FieldError } from 'src/components/Fields/FieldError'

import { TFieldProp } from 'src/components/Fields/field.interface'
import { dictionary } from 'src/utils/dictionary'

interface AnyObject {
  [key: string]: any
}

export interface ISelectFieldProp {
  saveCardTitle: string
  onSaveCardClick: () => void
}

export type TSelectFieldProp = ISelectFieldProp & TFieldProp<AnyObject>

export const PaymentTypeField = (props: TSelectFieldProp) => {
  const {
    isReadonly,
    placeholder = 'Выбор',
    name,
    isRequired,
    label,
    initialValue,
    saveCardTitle,
    onSaveCardClick,
  } = props

  return (
    <Field
      name={name}
      initialValue={initialValue}
      render={({ input, meta }) => {
        const disabledSaveCardButton = input.value !== dictionary.selectOptions.paymentType[1]

        return (
          <Form.Row>
            <Form.Group controlId={name} as={Col} md={9}>
              <FieldLabel {...label} isRequired={isRequired} />
              <Form.Control
                hidden
                readOnly={true}
                value={initialValue ? initialValue.id : ''}
                isInvalid={FieldError.isInvalid(meta)}
              />
              <Select
                {...input}
                options={dictionary.selectOptions.paymentType}
                getOptionLabel={(option) => option.value}
                getOptionValue={(option) => option.id}
                defaultValue={initialValue}
                placeholder={placeholder}
                isSearchable={true}
                isDisabled={isReadonly}
              />
              <FieldError error={meta.error} />
            </Form.Group>
            <Form.Group className="pt-4" controlId={name} as={Col} md={3}>
              <Button disabled={disabledSaveCardButton} className="w-100" onClick={onSaveCardClick}>
                {saveCardTitle || 'Привязать карту'}
              </Button>
            </Form.Group>
          </Form.Row>
        )
      }}
    />
  )
}
