import React from 'react'
import { Form } from 'react-bootstrap'
import { Field } from 'react-final-form'
import { FieldLabel } from '../FieldLabel'
import { TFieldProp } from '../field.interface'
import { FieldError } from '../FieldError'

export class PasswordField extends React.Component<TFieldProp<string>> {
  static defaultProps: Partial<TFieldProp<string>> = {
    isReadonly: false,
    isRequired: false,
  }

  render() {
    const { name, isRequired, isReadonly, initialValue, placeholder, label } = this.props

    return (
      <Field
        name={name}
        initialValue={initialValue}
        render={({ input, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <Form.Control
              {...input}
              readOnly={isReadonly}
              type="password"
              placeholder={placeholder}
              isInvalid={FieldError.isInvalid(meta)}
            />
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    )
  }
}
