import React from 'react';

import { Card, Spinner } from 'react-bootstrap';

interface Props {
  isLoading: boolean;
}

export class SpinnerWrapper extends React.Component<Props> {
  render() {
    const { isLoading, children } = this.props;

    if (isLoading) {
      return (
        <Card className={'align-items-center py-3 mb-3 media'}>
          <Spinner animation={'grow'} variant={'secondary'} />
        </Card>
      );
    }

    return children;
  }
}
