import React from 'react'

import { Form } from 'react-bootstrap'
import { Field } from 'react-final-form'
import AsyncSuggest from 'react-select/async'
import { FieldLabel } from 'src/components/Fields/FieldLabel'
import { FieldError } from 'src/components/Fields/FieldError'

import { TFieldProp } from 'src/components/Fields/field.interface'
import { ValueType } from 'react-select'

interface AnyObject {
  [key: string]: any
}

export interface ISuggestFieldProp {
  optionValueKey: Partial<keyof AnyObject>
  optionLabelKey: Partial<keyof AnyObject>
  search: (searchValue: string) => Promise<AnyObject[]>
  delay?: number
}

export type TSuggestFieldProp = ISuggestFieldProp & TFieldProp<AnyObject[]>

// необходим для решения ошибки типизации компонента
// при работе в режиме isMulti
// https://github.com/JedWatson/react-select/issues/3518
type TSelectValue = ValueType<AnyObject | AnyObject[]>

interface IState {
  options: AnyObject[]
}

export class MultiSuggestField extends React.Component<TSuggestFieldProp, IState> {
  constructor(props: TSuggestFieldProp) {
    super(props)
    const { initialValue } = props

    this.state = { options: initialValue || [] }
  }

  static defaultProps: Partial<TSuggestFieldProp> = {
    isReadonly: false,
    isRequired: false,
  }

  private onChange = (onChange: (arg?: TSelectValue) => void, selectedOptions: TSelectValue) => {
    onChange(selectedOptions && selectedOptions.length ? selectedOptions : undefined)
  }

  private loadOptions = (value: string): Promise<AnyObject[]> => {
    const { search } = this.props
    /**
     * TODO: добавить обработку `delay` из-вне
     */
    return search(value)
  }

  render() {
    const {
      name,
      isRequired,
      isReadonly,
      label,
      placeholder = 'Выбор',
      optionValueKey,
      optionLabelKey,
    } = this.props
    const { options } = this.state

    return (
      <Field
        name={name}
        type="checkbox"
        initialValue={options.length ? options : undefined}
        render={({ input: { onChange, ...inputRender }, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <AsyncSuggest
              {...inputRender}
              isMulti
              defaultOptions
              getOptionLabel={(option) => option[optionLabelKey]}
              getOptionValue={(option) => option[optionValueKey]}
              defaultValue={options}
              isDisabled={isReadonly}
              placeholder={placeholder}
              loadOptions={this.loadOptions}
              loadingMessage={() => 'Загрузка...'}
              noOptionsMessage={() => 'Список пуст'}
              onChange={(selectedOptions) => this.onChange(onChange, selectedOptions)}
            />
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    )
  }
}
