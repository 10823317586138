import React, { useEffect, useMemo, useState } from 'react'
import { Card, Badge } from 'react-bootstrap'
import { sso } from 'src/services/GrpcService'

import InfoPanel, { IInfoPanelRow } from 'src/components/InfoPanel'
import { ExecutorLinkDto, OrderDto } from 'src/store/OrderStore'
import {
  parseDate,
  formatPhone,
  parseChosedOptions,
  ITargetOption,
  getFullName,
} from 'src/utils/formatters'
import { observer } from 'mobx-react'
import MetaStore from 'src/store/MetaStore'
import { logger } from '@qlean/front-logger'
import { parseMinutes } from '../../utils/formatters'
import { ExecutorRole } from '../OrderReport/OrderReport'

interface IProps {
  order: OrderDto
  currentExecutorLink?: ExecutorLinkDto
}

const ServiceTypeText = {
  cleaning_flat_deep: 'Генеральная уборка квартиры',
  cleaning_air_conditioner: 'Чистка кондиционера',
  cleaning_flat_renovation: 'Уборка квартиры после ремонта',
  cleaning_house_deep: 'Уборка загородного дома',
  hourly_deep_cleaning: 'Почасовая генеральная уборка',
  sell_goods: 'Продажа инвентаря клиенту',
}

const STATUS_NAME = {
  assigned: 'Назначен',
  started: 'Начат',
  finished: 'Закончен',
  paid: 'Оплачен',
  confirmed: 'Подтвержден',
}

const OrderInfo = observer((props: IProps) => {
  const { order, currentExecutorLink } = props
  logger.debug('[component orderInfo] order: ', order)
  const { findPriceElement, priceElement } = MetaStore
  const [chosedOptions, setChosedOptions] = useState<ITargetOption[]>([])
  const orderAddressObject = JSON.parse(order?.address?.object || '{}')

  useEffect(() => {
    // Исправить [0]
    findPriceElement({ productSlug: order.price.products[0].productSlug, regionId: order.regionId })
  }, [])

  useEffect(() => {
    if (priceElement) {
      setChosedOptions(parseChosedOptions(priceElement?.options, order.price.products[0].options))
    }
  }, [priceElement])

  const getOptions = () => {
    if (chosedOptions.length > 0) {
      const badges = chosedOptions
        .filter((chosedOption) => chosedOption.value)
        .map((chosedOption) => (
          <Badge
            key={chosedOption.id}
            className="mt-1 mr-1"
            variant={chosedOption.type === 1 ? 'secondary' : 'success'}
          >
            {chosedOption.title} {chosedOption.value} ({chosedOption.slug})
          </Badge>
        ))
      return badges
    }
    return undefined
  }

  const rowsMain = () => {
    const data: (IInfoPanelRow & { exceptRolesToShow?: ExecutorRole[] })[] = [
      {
        key: 'ФИО клиента',
        value: getFullName(
          order?.person?.fio?.firstName,
          order?.person?.fio?.lastName,
          order?.person?.fio?.middleName
        ),
      },
      { key: 'Статус заказа', value: `${STATUS_NAME[order.status] || order.status}` },
      {
        key: 'Стоимость',
        value: `${(order.totalPrice / 100).toFixed(2)} руб.`,
        exceptRolesToShow: [ExecutorRole.WORKER],
      },
      { key: 'Услуга', value: ServiceTypeText[order.serviceType] ?? order.serviceType },
      { key: 'Продолжительность', value: `${parseMinutes(order.totalDuration)}` },
      { key: 'Адрес', value: order.address?.value },
      { key: 'Квартира', value: orderAddressObject?.flat },
      { key: 'Домофон', value: order.address?.detail?.intercom || '' },
      { key: 'Подъезд', value: order.address?.detail?.entrance || '' },
      { key: 'Этаж', value: order.address?.detail?.floor || '' },
      {
        keyType: 'small',
        key: 'Комментарий к адресу',
        value: order.address?.detail?.comment || '',
      },
      {
        key: 'Телефон',
        value: formatPhone(order?.person?.phone),
        exceptRolesToShow: [ExecutorRole.WORKER],
      },
      { key: 'Дата и время', value: parseDate(order?.startDate) },
    ]
    return data.filter((d) => 
      currentExecutorLink
        ? !d.exceptRolesToShow || !d.exceptRolesToShow.includes(currentExecutorLink.role)
        : true
    )
  }

  const renderKeysAddress = (value: string, object: any, detail: sso.IAddressDetail | null) => (
    <>
      {value && (
        <Badge className="mt-1 mr-1" variant="secondary">
          {value}
        </Badge>
      )}
      {object?.flat && (
        <Badge className="mt-1 mr-1" variant="secondary">
          Квартира: {object?.flat}
        </Badge>
      )}
      {detail?.intercom && (
        <Badge className="mt-1 mr-1" variant="secondary">
          Домофон: {detail?.intercom}
        </Badge>
      )}
      {detail?.entrance && (
        <Badge className="mt-1 mr-1" variant="secondary">
          Подъезд: {detail?.entrance}
        </Badge>
      )}
      {detail?.floor && (
        <Badge className="mt-1 mr-1" variant="secondary">
          Этаж: {detail?.floor}
        </Badge>
      )}
    </>
  )

  const rowsAdditional: IInfoPanelRow[] = [
    {
      keyType: 'small',
      key: 'Забор ключей',
      value: order?.keysPickup
        ? renderKeysAddress(
            order.keysPickup.value,
            JSON.parse(order.keysPickup.object || '{}'),
            order?.keysPickup?.detail || null
          )
        : undefined,
    },
    {
      keyType: 'small',
      key: 'Комментарий к адресу забора ключей',
      value: order?.keysPickup?.detail?.comment || '',
    },
    {
      keyType: 'small',
      key: 'Доставка ключей',
      value: order.keysDelivery
        ? renderKeysAddress(
            order.keysDelivery.value,
            JSON.parse(order.keysDelivery.object || '{}'),
            order?.keysDelivery?.detail || null
          )
        : undefined,
    },
    {
      keyType: 'small',
      key: 'Комментарий к адресу доставки ключей',
      value: order?.keysDelivery?.detail?.comment || '',
    },
    { keyType: 'small', key: 'Опции', value: getOptions() },
    { keyType: 'small', key: 'Комментарий', value: order?.commentForExecutor },
  ]

  return (
    <Card>
      <InfoPanel title={`Заказ ${order?.orderNumber}`} rows={rowsMain()} />
      <InfoPanel title="Детали" rows={rowsAdditional} />
    </Card>
  )
})

export default OrderInfo
