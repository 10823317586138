import React from 'react'

import DatePicker from 'react-datepicker'
import { Form } from 'react-bootstrap'
import { Field } from 'react-final-form'
import { FieldLabel } from 'src/components/Fields/FieldLabel'
import { FieldError } from 'src/components/Fields/FieldError'

import { TFieldProp } from 'src/components/Fields/field.interface'

import ru from 'date-fns/locale/ru'

import 'src/vendor/libs/react-datepicker/react-datepicker.scss'

interface State {
  initialValue?: Date
}

export class DataTimeField extends React.Component<TFieldProp<string>, State> {
  constructor(props: TFieldProp<string>) {
    super(props)
    const { initialValue } = props

    this.state = { initialValue: initialValue ? new Date(initialValue) : undefined }
  }

  static defaultProps: Partial<TFieldProp<string>> = {
    isReadonly: false,
    isRequired: false,
  }

  render() {
    const { name, isRequired, label } = this.props
    const { initialValue } = this.state

    return (
      <Field
        name={name}
        initialValue={initialValue}
        render={({ input, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <DatePicker
              className="form-control"
              calendarClassName="react-datepicker--with-time"
              selected={input.value}
              onChange={input.onChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              dateFormat="dd MMMM yyyy HH:mm"
              timeCaption="Время"
              showDisabledMonthNavigation
              locale={ru}
            />

            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    )
  }
}
