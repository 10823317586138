import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import QuestionnaireStore from 'src/store/QuestionnaireStore'
import Spinner from 'react-bootstrap/Spinner'
import { Form, Formik } from 'formik'
import { QuestionnaireForm } from './QuestionsForm'

const Questionnaire = observer(() => {
  const store = QuestionnaireStore
  const { orderId, ...rest } = useParams<{ orderId: string }>()
  const history = useHistory()

  useEffect(() => {
    return () => {
      store.reviewSubjects = []
      store.reviewer = undefined
    }
  }, [])

  useEffect(() => {
    if (orderId) store.getQuestionnaire(orderId)
  }, [orderId])

  const onSubmitForm = (values) => {
    store.submitQuestionnaire(values).then((response) => {
      if (response) {
        history.push(orderId ? `/order/${orderId}` : '/')
      }
    })
  }

  const onCancel = () => {
    history.push(orderId ? `/order/${orderId}` : '/')
  }

  const QuestionnaireComp = observer(
    (): JSX.Element => {
      return (
        <Card className="p-3">
          <div className="mb-3">
            <h4 className="d-flex font-italic text-light">
              <div className="mr-3">
                <b>Исполнитель:</b>
              </div>
              <div>
                {store.reviewer?.role} специалист {store.reviewer?.name}
              </div>
            </h4>
          </div>
          <div>
            <Formik initialValues={{}} onSubmit={onSubmitForm}>
              <Form>
                <QuestionnaireForm />
                <div className="mt-5 pl-4">
                  <Button disabled={store.isLoading} type="submit" variant="success">
                    {store.isLoading ? (
                      <>
                        <Spinner as="span" animation="grow" role="status" aria-hidden="true" />
                        Загрузка...
                      </>
                    ) : (
                      'Оценить'
                    )}
                  </Button>
                  &nbsp;
                  <Button disabled={store.isLoading} onClick={onCancel} variant="danger">
                    Вернуться к заказу
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </Card>
      )
    }
  )

  return store.hasAnswers ? (
    <div>
      <h2 className="mb-4">Вы уже оценили данного сотрудника</h2>
      <div>
        <Button onClick={onCancel}>Вернуться к заказу</Button>
      </div>
    </div>
  ) : store.isLoadingQuestionnaire ? (
    <Spinner animation="border" role="status" />
  ) : (
    <QuestionnaireComp />
  )
})

export default Questionnaire
